export const tabs = [
    {
        title: 'Person’s Details',
        text: 'Name, Categories, Types, Groups',
        value: 'setup',
    },
    {
        title: 'Tracker Assignment',
        text: 'Node Contact Name, Cell, Email',
        value: 'sensorAssignment',
    },
];
