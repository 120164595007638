import { Typography, IconButton, Stack, Box } from '@mui/material';
import { Close, Edit } from '@mui/icons-material';
import plusIcon from '../../../assets/images/addIcon.svg';
import closeIcon from '../../../assets/images/closeIcon.svg';
import editIcon from '../../../assets/images/editIcon.svg';
import moment from 'moment';

export const geoFenceColumns = (serialNumber, onDeleteClick, onEditClick, setAddGeoFence, formik) => [
    {
        field: 'name', headerName: 'Zone Name', width: 90,
        renderCell: ({ value }) => (
            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                {value ? value : "-"}
            </Typography>
        ),
    },
    {
        field: 'notification',
        headerName: 'Status',
        width: 70,
        renderCell: ({ value }) => (
            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                {value == "1" ? "Active" : "In Active"}
            </Typography>
        ),
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                {value}
            </Typography>
        ),
    },
    {
        field: 'delete',
        headerName: '',
        width: 78,
        sortable: false,
        disableColumnMenu: true,
        renderHeader: (params) => (
            <Stack alignItems="flex-end" width="100%" onClick={(e) => {
                setAddGeoFence(true)
                formik.resetForm()
            }}>
                <Box component={'img'} src={plusIcon} sx={{ width: "25px", height: "25px" }} />
            </Stack>
        ),
        renderCell: (params) => (
            <Stack alignItems="flex-end" width="100%"  >
                <Stack direction={'row'} gap={'8px'} >
                    <Stack
                        onClick={(e) => {
                            e.stopPropagation()
                            onEditClick(params.row)
                        }}
                        sx={{
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '25px',
                            height: '25px',
                            backgroundColor: '#262B40',
                        }}>
                        <Box component={'img'} src={editIcon} />
                    </Stack>
                    <Stack
                        onClick={(e) => {
                            e.stopPropagation()
                            onDeleteClick(params.row)
                        }}
                        sx={{
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '25px',
                            height: '25px',
                            backgroundColor: '#262B40',
                        }}>
                        <Box component={'img'} src={closeIcon} />
                    </Stack>
                </Stack>
            </Stack>
        ),
    },
];

export const sharingColumns = (onDeleteClick, onEditClick, setAddSharing) => [

    {
        field: 'email', headerName: 'Email', flex: 1, renderCell: ({ value }) => (
            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                {value ? value : "-"}
            </Typography>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: ({ value }) => (
            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                {value == "1" ? "Active" : "In Active"}
            </Typography>
        ),
    },
    {
        field: 'delete',
        headerName: '',
        width: 78,
        sortable: false,
        disableColumnMenu: true,
        renderHeader: (params) => (
            <Stack alignItems="flex-end" width="100%" onClick={(e) => { setAddSharing(true) }}>
                <Box component={'img'} src={plusIcon} sx={{ width: "25px", height: "25px" }} />
            </Stack>
        ),
        renderCell: (params) => (
            <Stack alignItems="flex-end" width="100%">

                <Stack direction={'row'} gap={'8px'}>
                    <Stack
                        onClick={(e) => {
                            e.stopPropagation()
                            onEditClick(params.row)
                        }}
                        sx={{
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '25px',
                            height: '25px',
                            backgroundColor: '#262B40',
                        }}>
                        <Box component={'img'} src={editIcon} />
                    </Stack>
                    <Stack
                        onClick={(e) => {
                            e.stopPropagation()
                            onDeleteClick(params.row)
                        }}
                        sx={{
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '25px',
                            height: '25px',
                            backgroundColor: '#262B40',
                        }}>
                        <Box component={'img'} src={closeIcon} />
                    </Stack>
                </Stack>
            </Stack>
        ),
    },
];

export const pictureColumns = (onDeleteClick, fileRef, uploadFiles) => [

    {
        field: 'name', headerName: 'File Name', width: 230,
        renderCell: ({ value }) => (
            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                {value ? value : "-"}
            </Typography>
        )
    },
    {
        field: 'updatedAt',
        headerName: 'Date',
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                {value ? moment(value).format('MM/DD/YY') : "-"}
            </Typography>
        ),
    },
    {
        field: 'delete',
        headerName: '',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderHeader: (params) => (
            <Stack alignItems="flex-end" width="100%" onClick={(e) => {
                // e.preventDefault()
                if (fileRef.current) {
                    fileRef.current.value = null;
                    fileRef.current.click()
                }
            }}>
                <input
                    type="file"
                    name="files"
                    hidden
                    ref={fileRef}
                    accept="image/*"
                    onChange={(e) => {
                        const selectedFiles = e.target.files;
                        console.log("selectedFiles", selectedFiles)
                        if (selectedFiles.length > 0) {
                            uploadFiles(selectedFiles[0]);
                        }

                    }}

                />
                <Box component={'img'} src={plusIcon} sx={{ width: "25px", height: "25px" }} />
            </Stack>
        ),
        renderCell: (params) => (
            <Stack alignItems="flex-end" width="100%" >
                <Stack
                    onClick={(e) => {
                        e.stopPropagation()
                        onDeleteClick(params.row)
                    }}
                    sx={{
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '25px',
                        height: '25px',
                        backgroundColor: '#262B40',
                    }}>
                    <Box component={'img'} src={closeIcon} />
                </Stack>
            </Stack>
        ),
    },
];