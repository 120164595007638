import { Stack, Typography, Box } from "@mui/material"

export const columns = [
    {
        field: 'logMessage',
        headerName: 'Log Message',
        flex: 1,
        renderCell: ({ value }) => (
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} mr={5}>
                <Typography fontSize={12}>{value ? value : "-"}</Typography>
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: "green.main",
                    }}
                />
            </Stack>
        ),
    },
    {
        field: 'timestamp',
        headerName: 'Timestamp',
        width: 200,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{value ? value : "-"}</Typography>
        ),
    },
]

export const row = [
    {
        id: 1,
        logMessage: 'Not Enough Sufficient Data with Selected Date Range. Expand Data Range.',
        timestamp: 'August 18, 2024 1:23 AM',
    },
    {
        id: 2,
        logMessage: 'Completed Successfully',
        timestamp: 'August 18, 2024 1:23 AM',
    },
    {
        id: 3,
        logMessage: 'Not Enough Sufficient Data with Selected Date Range. Expand Data Range.',
        timestamp: 'August 18, 2024 1:23 AM',
    },
    {
        id: 4,
        logMessage: 'Completed Successfully',
        timestamp: 'August 18, 2024 1:23 AM',
    },
    {
        id: 5,
        logMessage: 'Not Enough Sufficient Data with Selected Date Range. Expand Data Range.',
        timestamp: 'August 18, 2024 1:23 AM',
    },
]