import { Box, Stack, Typography, Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Map } from './Map.jsx';
import ArrowUpIcon from '../../../assets/icons/ArrowUpIcon.svg';
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon.svg';
import SettingsIcon from '../../../assets/icons/settings.svg';
import CloseIcon from '../../../assets/icons/CloseIcon.svg';
import { SelectGraphAlert } from './SelectGraphAlert.jsx';
import { SelectChartAlert } from './SelectChartAlert.jsx';
import { GraphBox } from './GraphBox.jsx';
import { Details } from './Details.jsx';
import { ChartBox } from './ChartBox.jsx';
import { toolsTabs } from '../../../modules/Asset/data/toolsTabs.jsx';
import api from '../../../lib/axios.js';
import { MachineTimelineChart } from './MachineTimelineChart.jsx';
import { SelectTimelineAlert } from './SelectTimelineAlert.jsx';

export const LocationsMap = ({ nodeData, sensorData, selectedDataId, getData, showMap, onMapClose, setShowMap }) => {
    const [activeTab, setActiveTab] = useState(toolsTabs[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [temperature, setTemperature] = useState("");
    const [showGraphAlert, setShowGraphAlert] = useState(false);
    const [showChartAlert, setShowChartAlert] = useState(false);
    const [showTimelineAlert, setShowTimelineAlert] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [addGeoFence, setAddGeoFence] = useState(false);
    const [drawData, setDrawData] = useState(null);
    const [selectedPoligon, setSelectedPoligon] = useState(null);

    const [componentOrder, setComponentOrder] = useState(() => {
        const savedOrder = (sensorData && sensorData.product == "4") ? localStorage.getItem('componentVibrationOrder') : localStorage.getItem('componentOrder');
        return savedOrder ? JSON.parse(savedOrder) : (sensorData && sensorData.product == "4") ? ["reading", "graph", "chart", "timeline"] : ["reading", "graph", "chart"]
    });

    useEffect(() => {
        if (sensorData && sensorData.product == "4") {
            localStorage.setItem('componentVibrationOrder', JSON.stringify(componentOrder));
        } else {
            localStorage.setItem('componentOrder', JSON.stringify(componentOrder));
        }
    }, [componentOrder]);

    useEffect(() => {
        if (nodeData && nodeData._id != null) {
            getPoligon()
        }
    }, [nodeData]);

    useEffect(() => {
        if (sensorData && sensorData.temperature !== undefined) {
            const value = parseFloat(sensorData.temperature);
            setTemperature(`${value.toFixed(1)} °F`);
        } else {
            setTemperature("-");
        }
    }, [nodeData, sensorData]);

    const moveComponentUp = (index) => {
        if (index === 0) return;
        const newOrder = [...componentOrder];
        [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];
        setComponentOrder(newOrder);
    };

    const moveComponentDown = (index) => {
        if (index === componentOrder.length - 1) return;
        const newOrder = [...componentOrder];
        [newOrder[index], newOrder[index + 1]] = [newOrder[index + 1], newOrder[index]];
        setComponentOrder(newOrder);
    };

    const renderComponent = (component, index) => {
        switch (component) {
            case "reading":
                return (
                    <Box
                        key="reading"
                        sx={{ width: "100%", backgroundColor: "#F6F8FA", border: '1px solid #E6E7E8', borderRadius: '5px', marginBottom: "10px" }}
                        px={'15px'}
                    >
                        <Stack direction={'row'} gap={5} alignItems={'center'} pb="10px">
                            <Stack width={'50%'}>
                                <Stack direction="row" justifyContent={'space-between'} sx={{ display: "flex", height: "40px", alignItems: 'center' }} width={'100%'}>
                                    <Stack width={'80px'} >
                                        <LinearProgress
                                            sx={{
                                                width: '100%',
                                                height: "6px",
                                                flexShrink: 0,
                                                borderRadius: '2px',
                                                bgcolor: '#EFEFEF',

                                                '.MuiLinearProgress-bar': {
                                                    borderRadius: '3px',
                                                    bgcolor: "#27B270",
                                                },
                                            }}
                                            variant={'determinate'}
                                            value={nodeData?.health || 0}
                                        />
                                    </Stack>
                                    <Stack width={'80px'}>
                                        <LinearProgress
                                            sx={{
                                                width: '100%',
                                                height: "6px",
                                                flexShrink: 0,
                                                borderRadius: '2px',
                                                bgcolor: '#EFEFEF',
                                                '.MuiLinearProgress-bar': {
                                                    borderRadius: '3px',
                                                    bgcolor: "#1E75FF",
                                                },
                                            }}
                                            variant={'determinate'}
                                            value={nodeData?.totalEvent ? (nodeData?.totalEvent >= 100 ? 100 : nodeData?.totalEvent) : 0}
                                        />

                                    </Stack>
                                    <Stack width={'80px'} >
                                        <LinearProgress
                                            sx={{
                                                width: '100%',
                                                height: "6px",
                                                flexShrink: 0,
                                                borderRadius: '2px',
                                                bgcolor: '#EFEFEF',

                                                '.MuiLinearProgress-bar': {
                                                    borderRadius: '3px',
                                                    bgcolor: "#003B6D",
                                                },
                                            }}
                                            variant={'determinate'}
                                            value={10}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction="row" justifyContent={'space-between'} >
                                    <Stack width={'80px'} gap={0.6}>

                                        <Typography fontSize={20} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} >
                                            {`${nodeData?.health || 0}%`}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} >
                                            Performance
                                        </Typography>
                                    </Stack>
                                    <Stack width={'80px'} gap={0.6} >

                                        <Typography fontSize={20} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'}>
                                            {nodeData?.totalEvent || "0"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'}>
                                            Events
                                        </Typography>
                                    </Stack>
                                    <Stack width={'80px'} gap={0.6} >
                                        <Typography fontSize={20} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'}>
                                            {10}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'}>
                                            Anomalies
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Box height={60} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                            <Stack width={'50%'}>
                                <Stack direction="row" sx={{ display: "flex", height: "40px", alignItems: 'center' }} width={'100%'}>
                                    <Typography fontWeight={500} fontSize={15}>Data</Typography>
                                    <Box flexGrow={1}></Box>
                                    <Stack direction={'row'} alignItems={'center'} gap="18px">
                                        {/* <Box
                                            sx={{
                                                height: '18px',
                                                width: '18px',
                                                cursor: 'pointer',
                                            }}
                                            component={'img'}
                                            src={SettingsIcon}
                                        /> */}
                                        <Box
                                            sx={{
                                                height: '13px',
                                                width: '10px',
                                                opacity: index === 0 ? 0.5 : 1,
                                                cursor: index === 0 ? 'not-allowed' : 'pointer',
                                            }}
                                            component={'img'}
                                            src={ArrowUpIcon}
                                            onClick={index > 0 ? () => moveComponentUp(index) : null}
                                        />
                                        <Box
                                            sx={{
                                                height: '13px',
                                                width: '10px',
                                                opacity: (index === 2) ? 0.5 : 1,
                                                cursor: (index === 2) ? 'not-allowed' : 'pointer',
                                            }}
                                            component={'img'}
                                            src={ArrowDownIcon}
                                            onClick={index != 3 ? () => moveComponentDown(index) : null}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction="row" sx={{ display: "flex", alignItems: 'center' }} justifyContent={'space-between'}>
                                    <Stack gap={0.6}>
                                        <Typography height="26px" alignContent={'center'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#151D26'}>{temperature}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Temp</Typography>
                                    </Stack>
                                    <Stack gap={0.6}>
                                        <Typography height="26px" alignContent={'center'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#151D26'}>{sensorData != null && sensorData.battery ?(sensorData.product != null && sensorData.product === "4" ? `${sensorData.battery}%` : `${sensorData.battery}v`) : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Battery</Typography>
                                    </Stack>
                                    <Stack gap={0.6}>
                                        <Typography height="26px" alignContent={'center'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#151D26'}>{sensorData?.speed || "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Speed(mph)</Typography>
                                    </Stack>
                                    <Stack gap={0.6}>
                                        <Typography height="26px" alignContent={'center'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#151D26'}>{sensorData?.altitude || "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Altitude(m)</Typography>
                                    </Stack>
                                    <Stack gap={0.6}>
                                        <Typography height="26px" alignContent={'center'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#151D26'}>{sensorData != null && sensorData.externalPower ? `${sensorData.externalPower}v` : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>External Power</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                );
            case "graph":
                return (
                    <GraphBox
                        key="graph"
                        nodeData={nodeData}
                        sensorData={sensorData}
                        componentOrder={componentOrder}
                        index={index}
                        onUp={() => moveComponentUp(index)}
                        onDown={() => moveComponentDown(index)}
                        onGraphSettingClick={() => {
                            setShowChartAlert(false);
                            setShowGraphAlert(true);
                            setShowTimelineAlert(false);
                        }}
                    />
                );
            case "chart":
                return (
                    <ChartBox
                        key="chart"
                        index={index}
                        nodeData={nodeData}
                        sensorData={sensorData}
                        componentOrder={componentOrder}
                        onUp={() => moveComponentUp(index)}
                        onDown={() => moveComponentDown(index)}
                        onChartSettingClick={() => {
                            setShowGraphAlert(false);
                            setShowChartAlert(true);
                            setShowTimelineAlert(false);
                        }}
                    />
                );
            case "timeline":
                return (<MachineTimelineChart
                    key="timeline"
                    timelineOrder={componentOrder}
                    nodeData={nodeData}
                    sensorData={sensorData}
                    index={index}
                    onUp={() => moveComponentUp(index)}
                    onDown={() => moveComponentDown(index)}
                    title={nodeData?.objMachineRuntime?.groupName || 'Machine Runtimes'}
                    onSettingClick={() => {
                        setShowTimelineAlert(true);
                        setShowGraphAlert(false);
                        setShowChartAlert(false);
                    }}
                />)
            default:
                return null;
        }
    };

    const getPoligon = () => {
        setIsLoading(true)
        api.get('/apis/NodeGeoFencings/byNode/' + nodeData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onDrawGeofence = (data) => {
        setDrawData(data)
    };

    return (
        <>
            <Stack
                flex={1}
                direction={'row'}
                width={'calc(100% + 24px)'}
                overflow={'hidden'}
                sx={{
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '4px',
                        backgroundColor: '#bdbdbd',
                    },
                }}
            >
                <Stack flex={1}>
                    {!showMap && componentOrder.map((component, index) => renderComponent(component, index))}
                    {showMap && (
                        <Box sx={{ flex: 1, width: "100%", border: '1px solid #E2E2EA', borderRadius: '3.5px', marginBottom: "10px", overflow: 'hidden' }}>
                            <Stack direction="row" sx={{ display: "flex", height: "40px", borderBottom: '1px solid #E2E2EA', alignItems: 'center' }} px={'15px'}>
                                <Typography fontWeight={500} fontSize={12}>Map View</Typography>
                                <Box flexGrow={1}></Box>
                                <Stack direction={'row'} alignItems={'center'} gap={5}>
                                    <Typography fontSize={12}>{`Latitude: ${sensorData?.latitude || "-"}`}</Typography>
                                    <Typography fontSize={12}>{`Longitude: ${sensorData?.longitude || "-"}`}</Typography>
                                    <Box
                                        sx={{
                                            height: '24px',
                                            width: '24px',
                                            cursor: 'pointer',
                                        }}
                                        component={'img'}
                                        onClick={() => {
                                            setAddGeoFence(false)
                                            onMapClose();
                                        }}
                                        src={CloseIcon}
                                    />
                                </Stack>
                            </Stack>
                            <Map sensorData={sensorData} arrayData={arrayData} isDrawGeoFence={addGeoFence} onDrawGeofence={onDrawGeofence} mode={activeTab} selectedPoligon={selectedPoligon} />
                        </Box>
                    )}
                </Stack>
                {/* Alerts and Details Components */}
                {!showChartAlert && !showGraphAlert && !showTimelineAlert &&
                    <Details
                        nodeData={nodeData}
                        arrayData={arrayData}
                        sensorData={sensorData}
                        setShowMap={setShowMap}
                        showMap={showMap}
                        setAddGeoFence={setAddGeoFence}
                        addGeoFence={addGeoFence}
                        setDrawData={setDrawData}
                        drawData={drawData}
                        setSelectedPoligon={setSelectedPoligon}
                        selectedPoligon={selectedPoligon}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        getPoligon={getPoligon}
                    />}
                {showGraphAlert && (
                    <SelectGraphAlert
                        selectedDataId={selectedDataId}
                        nodeData={nodeData}
                        sensorData={sensorData}
                        onClose={(e) => {
                            if (e) {
                                getData();
                            }
                            setShowGraphAlert(false);
                        }}
                    />
                )}
                {showChartAlert && (
                    <SelectChartAlert
                        selectedDataId={selectedDataId}
                        nodeData={nodeData}
                        sensorData={sensorData}
                        onClose={(e) => {
                            if (e) {
                                getData();
                            }
                            setShowChartAlert(false);
                        }}
                    />
                )}

                {showTimelineAlert && (
                    <SelectTimelineAlert
                        selectedDataId={selectedDataId}
                        nodeData={nodeData}
                        sensorData={sensorData}
                        onClose={(e) => {
                            if (e) {
                                getData();
                            }
                            setShowTimelineAlert(false);
                        }}
                    />
                )}
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
