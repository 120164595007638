import { Backdrop, Box, CircularProgress, Stack, Typography, LinearProgress, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios.js';
import moment from 'moment';
import TimelineTimePanel from '../NodeDetail/Timeline/TimelineTimePanel.jsx';
import { TimeLineGraph } from '../../components/Timeline/TimeLineGraph.jsx';
import settingSliderIcon from '../../assets/images/settingSliderIcon.svg';
import { Dashboard } from './Dashboard.jsx';

export const PersonnelDashboard = () => {
    const navigate = useNavigate();
    const [arrayData, setArrayData] = useState([]);
    const [arrayTimeline, setArrayTimeline] = useState([]);
    const [eventPercentage, setEventPercentage] = useState(-1)
    const [isLoading, setIsLoading] = useState(false);
    const [isTimeline, setIsTimline] = useState(localStorage.getItem("nodeDahboardTimeLineSelected") == "1");
    const [isStats, setIsStats] = useState(localStorage.getItem("nodeDahboardStateSelected") == "1");
    const [isChecked, setIsChecked] = useState(true);
    const [arrayTopStatusData, setArrayTopStatusData] = useState([
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Active", totals: 0, color: "#36B368" },
        { id: "2", name: "Events", totals: 0, color: "#1E75FF" },
        { id: "3", name: "Anomalies", totals: 0, color: "#FFAD2C" },
        { id: "4", name: "Low Battery", totals: 0, color: "#FF3941" },
        { id: "5", name: "Offline", totals: 0, color: "#979797" },
    ]);

    useEffect(() => {
        // getTimelineData()
        getData()
    }, []);
    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Personnels/dashboard/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                setArrayData(resData)
                // var array = [
                //     { id: "0", name: "Total", totals: resData.length, color: "#ffffff" },
                //     { id: "1", name: "Active", totals: resData.filter((data) => data.state == "1").length, color: "#36B368" },
                //     { id: "2", name: "Events", totals: resData.filter((data) => data.isEvent == "1").length, color: "#1E75FF" },
                //     { id: "3", name: "Anomalies", totals: resData.filter((data) => data.isAnomalies == "1").length, color: "#FFAD2C" },
                //     { id: "4", name: "Low Battery", totals: resData.filter((data) => data.isLowBattery == "1").length, color: "#FF3941" },
                //     { id: "5", name: "Offline", totals: resData.filter((data) => data.state == "0").length, color: "#979797" },
                // ]
                // setArrayTopStatusData(array)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getTimelineData = () => {
        // setIsLoading(true)
        api.post('/apis/RuleEvents/getNodeEventTimelineData/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayTimeline(resData.arrayData)

                const now = moment();
                const hours = now.hours();
                const minutes = now.minutes();
                const seconds = now.seconds();

                // Convert hours, minutes, and seconds into total seconds
                const currentSecond = (hours * 3600) + (minutes * 60) + seconds;
                const totalSecondsInDay = 24 * 60 * 60;
                const percentageWidth = (currentSecond / totalSecondsInDay) * 100;
                setEventPercentage(percentageWidth)

            }
            //setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function onCheck(nodeData, isFavourite) {
        setIsLoading(true)
        var data = {
            sensorNumberID: nodeData.sensorNumberID,
            sensorNumber: nodeData.sensorNumber,
            isFavourite: isFavourite
        }
        api.put('/apis/Nodes/' + nodeData._id, data).then((res) => {
            getData()
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <Stack width={'100%'} mb={1}>
            <Stack p={3}>
                <Stack direction={'row'} gap={5} alignItems={'center'} mt={-2}>
                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                        Personnel Dashboard
                    </Typography>
                    {/* <Stack direction={'row'} alignItems={'center'}>
                        <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} color={'#615D5D'}> Timeline</Typography>

                        <Checkbox
                            checked={isTimeline}
                            sx={{ width: '16px', height: '16px', ml: "10px" }}
                            onChange={(e) => {
                                setIsTimline(e.target.checked)
                                localStorage.setItem("nodeDahboardTimeLineSelected", e.target.checked ? "1" : "0")
                            }}
                        />

                        <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} ml={'20px'} color={'#615D5D'}> Stats</Typography>

                        <Checkbox
                            checked={isStats}
                            sx={{ width: '16px', height: '16px', ml: "10px" }}
                            onChange={(e) => {
                                setIsStats(e.target.checked)
                                localStorage.setItem("nodeDahboardStateSelected", e.target.checked ? "1" : "0")
                            }}
                        />
                        <Box
                            sx={{
                                height: '16px',
                                width: '20px',
                                ml: '23px'
                            }}
                        >
                            <Box component={'img'} src={settingSliderIcon} />
                        </Box>
                    </Stack> */}
                </Stack>
                {/* {isTimeline && <Stack mt={'21px'} >
                    <Box
                        key="timePannel"
                        sx={{
                            bgcolor: '#FFFFFF',
                            borderRadius: '3.5px',
                            border: '1px solid #E6E7E8',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {arrayTimeline.length > 0 && <TimeLineGraph arrayData={arrayTimeline} totalPercentage={eventPercentage} sx={{ height: '32px', marginBottom: '8px' }} />}
                        <Box width={'100%'} height={'2px'} bgcolor={"#E6E7E8"} />
                        <TimelineTimePanel />
                    </Box>

                </Stack>} */}
                <Stack mt={'16px'} overflow={'hidden'} >
                    {/* {isStats && <Box
                        sx={{
                            border: '2px solid #E6E7E8',
                            bgcolor: '#FFFFFF',
                            borderRadius: '5px',
                            mb: '15px'
                        }}
                    >
                        <Stack direction={'row'} alignItems="center" sx={{ height: '90px' }}>
                            {arrayTopStatusData.map((status, index) => (
                                <React.Fragment key={status.id}>
                                    <Stack flex={1} alignItems={"center"} justifyContent="center" height={100} gap={1}  >
                                        <Stack direction={'row'} alignItems={'center'} gap={1.5} >
                                            <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"}>
                                                {status.name}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    backgroundColor: status.color,
                                                }}
                                            />
                                        </Stack>
                                        <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} textAlign="center" sx={{ marginRight: '20px' }}>
                                            {status.totals}
                                        </Typography>
                                    </Stack>

                                    {index < arrayTopStatusData.length - 1 && (
                                        <Box height={60} width={'1px'} bgcolor={'#E1E3E8'} />
                                    )}
                                </React.Fragment>
                            ))}
                        </Stack>

                    </Box>} */}
                    <Stack direction={'row'} gap={'17px'} flexWrap={'wrap'} >
                        {arrayData.map((data, index) => {
                            return (
                                <Stack key={data._id}>
                                    <Dashboard data={data} index={index}/>
                                </Stack>
                            )
                        })}
                    </Stack>
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
};
