import { Box, Stack, Typography, Backdrop, CircularProgress, ButtonBase, Grid, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { recipientsColoumn } from '../data/recipients';
import { DefaultDataGrid } from './DefaultDataGrid';
import { DefaultInput } from '../../../ui/DefaultInput/index.js';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import api from '../../../lib/axios.js';

export const ButtonAlert = ({ personnelData, getData }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [arrayRecipients, setArrayRecipients] = useState(personnelData?.arraySoSRecepient || []);
    const [arrayRecipients2, setArrayRecipients2] = useState(personnelData?.arrayButtonPressedRecepient || []);
    const arrayAlertType = [
        { id: '0', value: 'SOS' },
        { id: '1', value: 'Single Press' },
    ]

    const formik = useFormik({
        initialValues: {
            selectedType: { id: '0', value: 'SOS' },
            recipient: '',
        },
        validationSchema: Yup.object().shape({
            selectedType: Yup.object().required('Type is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {

                var dataValues = {
                    selectedType: values.selectedType,
                    recipient: values.recipient,
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apicalling();
             
                if (data !== undefined && data.data.success) {
                    getData()
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('Data updated', {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                } else {
                    setStatus({ success: false });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setIsLoading(false)
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {

    }, []);

    async function apicalling() {
        var data = {
            arraySoSRecepient: arrayRecipients,
            arrayButtonPressedRecepient: arrayRecipients2,
        }

        return await api.put('/apis/Personnels/updateRecepient/' + personnelData._id, data)

    }

    const addRecipients = (value) => {
        if (formik.values.selectedType != null) {
            if (formik.values.selectedType.id == "0") {
                const recipientExists = arrayRecipients.some((recipient) => recipient === value);

                if (!recipientExists) {
                    setArrayRecipients((prevArrayRecipient) => [...prevArrayRecipient, value]);
                    formik.setFieldValue('recipient', ''); // Reset the input field
                } else {
                    enqueueSnackbar('Recipient already exists', {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
            } else {
                const recipientExists = arrayRecipients2.some((recipient) => recipient === value);

                if (!recipientExists) {
                    setArrayRecipients2((prevArrayRecipient) => [...prevArrayRecipient, value]);
                    formik.setFieldValue('recipient', ''); // Reset the input field
                } else {
                    enqueueSnackbar('Recipient already exists', {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
            }
        }

    }

    const removeRecipients = (itemToRemove) => {
        if (formik.values.selectedType != null) {
            if (formik.values.selectedType.id == "0") {
                setArrayRecipients(arrayRecipients.filter(item => item !== itemToRemove));
            } else {
                setArrayRecipients2(arrayRecipients2.filter(item => item !== itemToRemove));
            }
        }
    };


    return (
        <FormikProvider value={formik}>
            <Stack
                px={2}
                sx={{
                    overflowY: 'auto',
                    flex: 1,
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '4px',
                        backgroundColor: '#bdbdbd',
                    },
                }}
                maxWidth={'100%'}
                component={'form'}
                onSubmit={formik.handleSubmit}
            >
                <Grid spacing={3} container>
                <Grid xs={9} item>
                        <DefaultInput
                            sx={{
                                '& .MuiInputBase-input': {
                                    pr: 2,
                                },
                            }}
                            label={'Recipients'}
                            value={formik.values.recipient}
                            placeholder={'example@domain.com or 000-000-0000'}
                            name={'recipient'}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.recipient && formik.errors.recipient)}
                            helperText={formik.touched.recipient && formik.errors.recipient}
                        ></DefaultInput>
                    </Grid>
                    <Grid xs={3} item mt={2.3} ml={-1.5}>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                bgcolor: '#FFFFFF',
                            }}
                            onClick={() => { addRecipients(formik.values.recipient) }}
                        >
                            <Typography fontSize={13} color={'#1F243B'} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                Add
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid xs={9} item>
                        <DefaultSelect
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: 40,
                                },
                            }}
                            label={'Alert Type'}
                            value={formik.values.selectedType}
                            onItemSelected={(e) => {
                                formik.setFieldValue('selectedType', e.target.value)
                            }}
                            helperText={formik.touched.selectedType && formik.errors.selectedType}
                            error={Boolean(formik.touched.selectedType && formik.errors.selectedType)}
                            placeholder={'Select Alert Type'}
                        >
                            {arrayAlertType.map((item, index) => (
                                <MenuItem key={index} value={item}> {item.value}</MenuItem>
                            ))}

                        </DefaultSelect>
                    </Grid>
                    <Grid xs={3} item mt={2.3} ml={-1.5}>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                bgcolor: '#FFFFFF',
                            }}
                            type='submit'
                            disabled={formik.isSubmitting}
                        >
                            <Typography fontSize={13} color={'#1F243B'} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                Save
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid xs={12} item>
                        <DefaultDataGrid
                            columns={recipientsColoumn(removeRecipients)}
                            rows={formik.values.selectedType == null ? [] : formik.values.selectedType.id == "0" ? arrayRecipients.map((recipient, index) => ({ id: index + 1, recipient })) : arrayRecipients2.map((recipient, index) => ({ id: index + 1, recipient }))} />
                    </Grid>
                </Grid>

            </Stack >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider>
    );

};
