export const arrayBillingCycle = [
    { id: "1", value: "Monthly" },
    { id: "2", value: "Yearly" },
]

export const arrayStatus = [
    { id: "2", value: "Paid", color: "#36B368" },
    { id: "1", value: "Past Due", color: "#FF3941" },
]

export const arrayYear = [
    { id: "2023", value: "2023" },
    { id: "2024", value: "2024" },
]