import React, { useState } from 'react';
import { Box } from "@mui/material"

export const TimeLineGraph = ({ arrayData, arrayData2, totalPercentage, sx, }) => {
    const totalSecondsInDay = 24 * 60 * 60;
    let total = 0
    let seconds = 0


    return (
        <Box
            height={arrayData.length > 0 && arrayData2.length > 0 ? '160px' : '96px'}
            width={'100%'}
            direction={'column'}
            sx={{
                direction: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `linear-gradient(to right, #E6E7E8 1px, transparent 1px, transparent 100%)`,//`linear-gradient(to right, transparent 95%, #E6E7E8 5%)`, // Vertical lines background
                backgroundSize: '12.48% 100%', // Adjust spacing between lines
                position: 'relative',
                ...sx
            }}
        >
            {arrayData.length > 0 && <Box
                sx={{
                    top: '30px',
                    position: 'absolute',
                    width: '100%',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',

                }}
            >
                {arrayData.map((item, index) => {
                    const percentageWidth = (item.seconds / totalSecondsInDay) * 100;
                    total += percentageWidth
                    seconds += item.seconds
                    return (<Box
                        key={index}
                        sx={{
                            width: `${percentageWidth}%`, // Progress percentage
                            height: '100%',
                            backgroundColor: item.backgroundColor, // Progress color
                            position: 'relative',
                        }}
                    ></Box>)
                }
                )}

            </Box>}

            {arrayData2.length > 0 && <Box
                sx={{
                    bottom: '32px',
                    position: 'absolute',
                    width: '100%',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',

                }}
            >
                {arrayData2.map((item, index) => {
                    const percentageWidth = (item.seconds / totalSecondsInDay) * 100;
                    total += percentageWidth
                    seconds += item.seconds
                    return (<Box
                        key={index}
                        sx={{
                            width: `${percentageWidth}%`, // Progress percentage
                            height: '100%',
                            backgroundColor: item.backgroundColor, // Progress color
                            position: 'relative',
                        }}
                    ></Box>)
                }
                )}

            </Box>}
            <Box
                sx={{
                    position: 'absolute',
                    left: `${totalPercentage}%`, // Position the line according to the progress
                    bottom: 0,
                    width: '2px',
                    height: '100%',
                    backgroundColor: '#000',
                    // Make sure the vertical line is in front of progress bars
                }}
            />
        </Box>

    )
}