import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { ArrowForwardIos } from '@mui/icons-material';
import { arrayMeasurment } from '../../../../modules/RuleNew/data/arrayData';

export const movementColumn = (selectedDataId) => [
    // {
    //     field: 'selectedBox',
    //     headerName: '',
    //     width: 20,
    //     renderCell: (params) => selectedDataId == params.row._id ? (
    //         <div style={{ position: 'relative', height: '100%', width: '100%' }}>
    //             <Box position={'absolute'} left={-25} top={0} bottom={0} bgcolor={'secondary.main'} width={6} />
    //         </div>
    //     ) : null,
    //     sortable: false,
    //     disableColumnMenu: true,
    // },
    {
        field: 'locations',
        headerName: 'Location (Area)',
        width: 300,
        // flex: 1,
        renderCell: (params) => (
            <Typography fontSize={12} fontWeight={400} fontFamily={'Roboto'}>
                {params.row.latitude + " , " + params.row.longitude}
            </Typography>
        ),
    },
    {
        field: 'light',
        headerName: 'Light',
        width: 150,
        renderCell: (params) => {
            const light = params.row.light;
            const arrayEvents = params.row.events
            return (
                light ? <Stack direction={"row"} gap={2}>
                    <Box
                        sx={{
                            // width: "100px",
                            px: 2,
                            border: '1px solid',
                            borderRadius: '20px',
                            bgcolor: "#F7F7F7",
                            height: '30px',
                            borderColor: arrayEvents.find((events) => events.eventType == "3") ? "#FF3941" : "#E2E2EA"
                        }}
                    >
                        <Stack
                            alignItems={'center'}
                            justifyContent={'center'}
                            height="100%"
                        >
                            <Typography
                                color={'#44444F'}
                                fontFamily={'Roboto'}
                                fontWeight={400}
                                fontSize={12}
                            >
                                {light ? `${parseInt(light)}%` : "-"}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack> : <Typography fontSize={12}>-</Typography>
            );
        },
    },
    {
        field: 'temperature',
        headerName: 'Temperature',
        width: 150,
        renderCell: (params) => {
            const temperature = params.row.temperature;
            const speed = params.row.positionSpeed;
            const arrayEvents = params.row.events
            return (
                temperature ? <Stack direction={"row"} gap={2}>
                    <Box
                        sx={{
                            // width: "100px",
                            px: 2,
                            border: '1px solid',
                            borderRadius: '20px',
                            bgcolor: "#F7F7F7",
                            height: '30px',
                            borderColor: arrayEvents.find((events) => events.eventType == "1") ? "#FF3941" : "#E2E2EA"
                        }}
                    >
                        <Stack
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={2}
                            height="100%"
                        >
                            <Typography
                                color={'#44444F'}
                                fontFamily={'Roboto'}
                                fontWeight={400}
                                fontSize={12}
                            >
                                {temperature ? `${parseFloat(temperature).toFixed(2)} °F` : "-"}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack> : <Typography fontSize={12}>-</Typography>
            );
        },
    },
    {
        field: 'event',
        headerName: 'Events',
        flex: 1,
        renderCell: (params) => {
            var arrayEvents = params.row.events
            return (arrayEvents != null && arrayEvents.length > 0 ?
                <Stack direction={'row'} gap={1}>
                    {arrayEvents.slice(0, 3).map((event, index) => (
                        <Box
                            key={event._id}
                            sx={{
                                p: "2px",
                                borderRadius: '20px',
                                bgcolor: "#3333CC4F",
                                height: '30px',
                            }}
                        >
                            <Typography
                                height="100%"
                                width="100%"
                                minWidth='120px'
                                pl="16px"
                                borderRadius='20px'
                                backgroundColor="#3333CC"
                                color={'#FFFFFF'}
                                fontFamily={'Roboto'}
                                fontWeight={400}
                                fontSize={12}
                                alignContent="center"
                                textAlign={'start'}
                            >
                                {/* {event.name} */}
                                {console.log("eventType", event.eventType)}
                                {arrayMeasurment.find((measurment) => measurment.id == event.eventType)?.value}
                            </Typography>
                        </Box>
                    ))}
                    {arrayEvents.length > 3 &&
                        <Box sx={{
                            px: 2,
                            border: '1px solid #E2E2EA',
                            borderRadius: '20px',
                            height: '30px',
                            alignContent: 'center',
                            width: '54px'
                        }}

                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                                <Typography
                                    color={'#707070'}
                                    fontFamily={'Roboto'}
                                    fontWeight={'Regular'}
                                    fontSize={12}
                                >
                                    {`+${arrayEvents.length - 3}`}
                                </Typography>
                                <ArrowForwardIos
                                    sx={{
                                        color: '#151D26',
                                        width: 13,
                                        height: 13,
                                    }}
                                />
                            </Stack>

                        </Box>
                    }
                </Stack> : <Typography fontSize={12}> None </Typography>)
        },
    },
    {
        field: 'battery',
        headerName: 'Battery',
        width: 120,
        renderCell: (params) => {
            const battery = params.row.battery ? parseFloat(params.row.battery) : 0
            var formatedBattery = battery ? `${battery}%` : "-"
            return (
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'55px'}>
                    <Typography fontSize={12}>{formatedBattery}</Typography>
                    {params.row.battery && battery < 3.5 && <Box
                        sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            backgroundColor: "#FF3941",
                            ml: '10px',
                        }}
                    />}
                </Stack>
            )
        },
    },
    {
        field: 'createdAt',
        headerName: 'Timestamp',
        width: 200,
        renderCell: ({ value }) => (
            <Typography fontSize={12} fontWeight={400} fontFamily={'Roboto'}>
                {value ? moment(value).format("MMMM DD, YYYY hh:mm A") : '-'}
            </Typography>
        ),
    },

];

export const rows = [
    {
        id: 1,
        asset: 'TR-500-14A',
        eventId: '1002',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 2,
        asset: 'TR-500-14A',
        eventId: '1003',
        sensor: 'GPS Device',
        type: 'Speed',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 3,
        asset: 'TR-500-14A',
        eventId: '1004',
        sensor: 'GPS Device',
        type: 'Altitude',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 1,
        },
    },
    {
        id: 4,
        asset: 'TR-500-14A',
        eventId: '1005',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 5,
        asset: 'TR-500-14A',
        eventId: '1006',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 6,
        asset: 'TR-500-14A',
        eventId: '1007',
        sensor: 'GPS Device',
        type: 'Speed',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 1,
        },
    },
];

export const movementMapColumn = (selectedDataId) => [
    {
        field: 'selectedBox',
        headerName: '',
        width: 20,
        renderCell: (params) => selectedDataId == params.row._id ? (
            <div style={{ position: 'relative', height: '100%', width: '20px', }}>
                <Box position={'absolute'} right={17} top={0} bottom={0} bgcolor={'secondary.main'} width={3} />
            </div>
        ) : null,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: 'createdAt',
        headerName: 'Timestamp',
        width: 155,
        renderCell: ({ value }) => (
            <Typography fontSize={12} fontWeight={400} fontFamily={'Roboto'}>
                {value ? moment(value).format("MM/DD/YYYY hh:mm A") : '-'}
            </Typography>
        ),
    },
    {
        field: 'light',
        headerName: 'Light',
        width: 80,
        renderCell: (params) => {
            const light = params.row.light;
            return (
                light ?
                    <Typography
                        color={'#44444F'}
                        fontFamily={'Roboto'}
                        fontWeight={400}
                        fontSize={12}
                    >
                        {light ? `${parseInt(light)}%` : "-"}
                    </Typography>
                    :
                    <Typography fontSize={12}>-</Typography>
            );
        },
    },
    {
        field: 'temperature',
        headerName: 'Temp',
        width: 80,
        renderCell: (params) => {
            const temperature = params.row.temperature;
            return (
                temperature ?
                    <Typography
                        color={'#44444F'}
                        fontFamily={'Roboto'}
                        fontWeight={400}
                        fontSize={12}
                    >
                        {temperature ? `${temperature} °F` : "-"}
                    </Typography>
                    :
                    <Typography fontSize={12}>-</Typography>
            );
        },
    },
    {
        field: 'event',
        headerName: 'Events',
        flex: 1,
        renderCell: (params) => {
            var arrayEvents = params.row.events
            return (arrayEvents != null && arrayEvents.length > 0 ?
                <Stack direction={'row'} gap={1}>
                    {arrayEvents.slice(0, 3).map((event, index) => (
                        <Box key={event._id}>
                            <Typography
                                height="100%"
                                width="100%"
                                minWidth='120px'
                                color={'#262B40'}
                                fontFamily={'Roboto'}
                                fontWeight={400}
                                fontSize={12}
                            >
                                {arrayMeasurment.find((measurment) => measurment.id == event.eventType)?.value}
                                {/* High Temp. */}
                            </Typography>
                        </Box>
                    ))}
                    {arrayEvents.length > 3 &&
                        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                            <Typography
                                color={'#707070'}
                                fontFamily={'Roboto'}
                                fontWeight={'Regular'}
                                fontSize={12}
                            >
                                {`+${arrayEvents.length - 3}`}
                            </Typography>
                            <ArrowForwardIos
                                sx={{
                                    color: '#151D26',
                                    width: 13,
                                    height: 13,
                                }}
                            />
                        </Stack>
                    }
                </Stack>
                :
                <Typography fontSize={12}> None </Typography>
            )
        },
    },


];