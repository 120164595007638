import markerIcon from '../../../assets/images/mapsIcon.svg';
import layersIcon from '../../../assets/images/assetIcon.svg'
import nodeIcon from '../../../assets/images/nodeIcon.svg'
import dashboardIcon from '../../../assets/images/dashboardIcon.svg';
import eventsIcon from '../../../assets/images/eventsIcon.svg';
import rulesEngineIcon from '../../../assets/images/rulesEngineIcon.svg';
import trackerIcon from '../../../assets/images/trackerIcon.svg';
import personnelIcon from '../../../assets/images/personnelIcon.svg';
import billingReportIcon from '../../../assets/images/billingReportIcon.svg';
import configurationIcon from '../../../assets/images/configurationIcon.svg';

export const mainMenu = [
    {
        title: 'Dashboard',
        link: '/node-dashboard',
        isFont: true,
        icon: dashboardIcon,
        selectedIcon: 'fi fi-br-pulse',
    },
    {
        title: 'Nodes',
        link: '/node-listing',
        isFont: true,
        icon: nodeIcon,
        selectedIcon: 'fi fi-br-pulse',
    },

    {
        title: 'Events',
        link: '/events',
        isFont: true,
        icon: eventsIcon,
        selectedIcon: 'fi fi-br-exclamation',
        statusCode: 3,
        notificationsCount: 0,
    },
    {
        title: 'Rules Engine',
        link: '/rule-listing',
        isFont: true,
        icon: rulesEngineIcon,
        selectedIcon: 'fi fi-br-calendar-clock',
    },

];

export const systemMenu = [
    // {
    //     title: 'Messages',
    //     link: '/messages',
    //     icon: <MessagesIcon />,
    //     notificationsCount: 200,
    // },
    {
        title: 'Billing Reports',
        link: '/billing-reports-listing',
        isFont: true,
        icon: billingReportIcon,
        selectedIcon: 'fi fi-bs-clipboard-list-check',
    },

    {
        title: 'Configuration',
        link: '/settings',
        isFont: true,
        icon: configurationIcon,
        selectedIcon: 'fi fi-br-settings-sliders',
    },
    // {
    //     title: 'Support',
    //     link: '/support',
    //     icon: <SupportIcon />,
    // },
];

export const trackingMenu = [
    {
        title: 'Maps',
        link: '/asset/asset-mapview',
        isFont: true,
        icon: markerIcon,
        selectedIcon: 'fi fi-bs-marker',
    },
    {
        title: 'Assets',
        link: '/asset-listing',
        isFont: true,
        icon: layersIcon,
        selectedIcon: 'fi fi-br-layers',
    },
    {
        title: 'Personnel',
        link: '/personnel-listing',
        isFont: true,
        icon: personnelIcon,
        selectedIcon: 'fi fi-br-location-alt',
    },
    {
        title: 'Personnel Dashboard',
        link: '/personnel-dashboard',
        isFont: true,
        icon: dashboardIcon,
        selectedIcon: 'fi fi-br-location-alt',
    },
    {
        title: 'Trackers',
        link: '/devices',
        isFont: true,
        icon: trackerIcon,
        selectedIcon: 'fi fi-br-location-alt',
    },
];
