export const getStatusColor = (code) => {
    switch (code) {
        case 1:
            return 'green.main';
        case 2:
            return 'orange.main';
        case 3:
            return 'red.main';
        default:
            return 'red.main';
    }
};
export const getAssetStatusColor = (code) => {
    switch (code) {
        case 'Ready':
            return 'blue.main';
        case 'In Service':
            return 'green.main';
        case 'In Maintenance':
            return 'red.main';
        case 'Not In Service':
            return 'black';
        case 'In Repair':
            return 'orange.main';
        default:
            return 'green.main';
    }
};
export const getPriorityColor = (code) => {
    switch (code) {
        case '1':
            return 'red.main';
        case '2':
            return 'orange.main';
        case '3':
            return 'black';
        case '4':
            return 'blue.main';
        default:
            return 'green.main';
    }
};
export const getStatusText = (code) => {
    switch (code) {
        case 1:
            return 'Online';
        case 2:
            return 'Preparing';
        case 3:
            return 'Offline';
        default:
            return 'Online';
    }
};
export const getEventType = (code) => {
    switch (code) {
        case 1:
            return 'Temperature';
        case 2:
            return 'Altitude';
        case 3:
            return 'Speed';
        case 4:
            return 'Battery';
        case 5:
            return 'CellSignal';
        default:
            return 'Sensor Value';
    }
};