import React, { useState, useEffect } from 'react';
import { Box, ButtonBase, Stack, Typography, Backdrop, CircularProgress, Checkbox, FormControlLabel, Breadcrumbs, Grid } from '@mui/material';
import { SearchInput } from '../../components/SearchInput/index.js';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Tag } from '../../ui/Tag/index.js';
import { tabs } from './data/tabs.js';
import { Tabs } from '../../ui/Tabs/index.js';
import { FormikProvider, useFormik } from 'formik';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { trackersTabs } from '../../components/Map/data/tabs.js';
import { Details } from './Details/Details.jsx';
import { Interval } from './Interval/interval.jsx';
import { ConfirmAlert } from '../../customControls/confirmAlert.js';
import { enqueueSnackbar } from 'notistack';
import api from '../../lib/axios';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

export const TrackerDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const assetName = location.state != null && location.state.assetName != null ? location.state.assetName : "-"
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [oemServer, setOemServer] = useState(null);
    const [trackerData, setTrackerData] = useState({});
    const [mapTrackerData, setMapTrackerData] = useState({});
    const [secondaryTrackerData, setSecondaryTrackerData] = useState({});
    const [activeTrackerTab, setActiveTrackerTab] = useState(trackersTabs[0].value);
    const [activeTab, setActiveTab] = useState(location.state != null && location.state.activeTab != null ? location.state.activeTab : tabs[0].value);
    const formik = useFormik({
        initialValues: {
            fenceName: '',
            alerts: true,
            sendTo: '',
            device: '',
            minTemperature: '',
            maxTemperature: '',
            minSpeed: '',
            maxSpeed: '',
        },
        onSubmit: (values) => {
            console.log(values);
        },
        validateOnChange: false,
    });

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        getData()
    }, []);
    function deleteTracker() {
        setIsLoading(true)
        api.delete('/apis/Trackers/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Tracker deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-1)
            } else {
                setIsLoading(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteTracker()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    const getData = () => {
        setIsLoading(true);
        api.get('/apis/Trackers/byIdWithOem/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                // var dataValues = {
                //     serialNumber: resData.deviceSerialNumber,
                // }
                // setTrackerType(resData)
                // setTrackersData(resData)
                console.log("ResData", resData);
                setTrackerData(resData)
                setOemServer(resData.objOemServer)
                // setMinutes(parseInt(resData.interval, 10));
                // formik.setValues(dataValues)
            }
            setIsLoading(false);
            // getCredentialData(resData.deviceSerialNumber)
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <>
            <FormikProvider value={formik}>
                <Stack width={'100%'}>
                    <Stack p={3} gap={2}>
                        <Stack direction={'row'} gap={4} justifyContent={'space-between'} alignItems={'center'}>
                            <Box
                            // onClick={() => navigate(-1)}
                            >
                                <Stack
                                    display={'inline-flex'}
                                    direction={'row'}
                                    alignItems={'center'}
                                    sx={{
                                        svg: {
                                            fill: '#A0A3A6',
                                        },
                                    }}
                                    gap={0.5}
                                >
                                    <ArrowBackIos
                                        sx={{
                                            fontSize: "small",
                                        }}
                                    />
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        spacing={3}
                                    >
                                        <Grid item>
                                            <Breadcrumbs
                                                aria-label="breadcrumb"
                                                separator={<Typography fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6">/</Typography>}
                                            >
                                                <Link
                                                    component={RouterLink}
                                                    to="/devices"
                                                >
                                                    <Typography
                                                        fontSize={15}
                                                        fontWeight={400}
                                                        fontFamily={'Roboto'}
                                                        color="#A0A3A6"
                                                    >
                                                        Trackers
                                                    </Typography>
                                                </Link>

                                                <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    Tracker Details
                                                </Typography>
                                                <Typography fontSize={15} fontWeight={500} color={'#151D26'}>
                                                    {trackerData.deviceSerialNumber || "-"}
                                                </Typography>
                                            </Breadcrumbs>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Box>
                            {/* <SearchInput /> */}
                        </Stack>

                        <Stack direction={'row'} justifyContent={'space-between'} gap={4} alignItems={'center'}>
                            <Stack direction={'row'} gap={6} alignItems={'center'}>
                                <Typography fontSize={20} fontWeight={500}>
                                    {trackerData.deviceSerialNumber || "-"}
                                </Typography>
                            </Stack>

                        </Stack>
                        <Box>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} />
                                <Stack direction={'row'} gap={1}>
                                    <ButtonBase
                                        sx={{
                                            pt: 1,
                                            pb: 1,
                                            px: 5,
                                            border: '1px solid #E2E2EA',
                                            bgcolor: '#F6F8FA',
                                            borderRadius: '5px',
                                            height: '30px'
                                        }}
                                        onClick={() => {
                                            getData()
                                        }}
                                    >
                                        <Typography fontSize={14}>Refresh</Typography>
                                    </ButtonBase>
                                    <ButtonBase
                                        sx={{
                                            pt: 1,
                                            pb: 1,
                                            px: 5,
                                            border: '1px solid #E2E2EA',
                                            bgcolor: '#F6F8FA',
                                            borderRadius: '5px',
                                            height: '30px'
                                        }}
                                        onClick={(e) => {
                                            setDeleteConfirmationShouldOpen(true)
                                        }}
                                    >
                                        <Typography fontSize={14}>Delete</Typography>
                                    </ButtonBase>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                    <Stack px={3} pb={3} pt={1} flex={1}>
                        {activeTab === 'details' && <Details assetName={assetName} oemServer={oemServer} trackerData={trackerData} />}
                        {activeTab === 'interval' && <Interval trackerID={selectedDataId} activeTab={activeTab} assetName={assetName} oemServer={oemServer} trackerData={trackerData} />}
                    </Stack>
                    {
                        deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                            updateConfirmAlert={(e, value) => {
                                setDeleteConfirmationShouldOpen(false)
                                updateConfirmAlert(value)
                            }} />)
                    }
                    <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Stack>
            </FormikProvider>
        </>
    )
}