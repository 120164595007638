import { Box, ButtonBase, Checkbox, FormControlLabel, Grid, Stack, Typography, Backdrop, CircularProgress, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { tabs } from './data/tabs.js';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import { CheckCircle } from '@mui/icons-material';
import { DefaultToggle } from '../../ui/DefaultToggle/DefaultToggle.jsx';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios.js';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { enqueueSnackbar } from 'notistack';
import { arrayMeasurment, arrayCondition, arrayThreshold, arrayDeviceProduct, arrayDeviceType, arrayRuleType, arrayAssetDeviceProduct, arrayPersonnelDeviceProduct, arrayPersonnelDeviceType } from './data/arrayData.jsx';
import { ConfirmAlert } from '../../customControls/confirmAlert.js';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/DefaultDataGrid.jsx';
import { recipientsColoumn } from './data/recipients.jsx';
import { columns } from './data/data.jsx';
import { SearchInput } from './data/SearchInput.jsx';

export const RuleNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arrayNode, setArrayNode] = useState([]);
    const [arrayPersonnel, setArrayPersonnel] = useState([]);
    const [arrayAsset, setArrayAsset] = useState([]);
    const [arrayTrackerType, setArrayTrackerType] = useState([]);
    const [arraySelected, setArraySelected] = useState([]);
    const [arrayRecipients, setArrayRecipients] = useState([]);
    const [isSensorMeasurment, setIsSensorMeasurment] = useState(false);
    const [condition, setCondition] = useState(null);
    const [isOfflineDuration, setIsOfflineDuration] = useState(false);
    const [isOnlineDuration, setIsOnlineDuration] = useState(false);
    const [arrayMeasurementData, setArrayMeasurementData] = useState(arrayMeasurment);
    const [selectedRuleType, setSelectedRuleType] = useState(null);
    const formik = useFormik({
        initialValues: {
            ruleName: '',
            selectedRuleType: null,
            selectedProduct: null,
            selectedType: null,
            arrayAsset: [],
            arraySelected: [],
            isSensorMeasurment: false,
            selectedSensorMeasurement: null,
            selectedCondition: null,
            conditionValue1: '',
            conditionValue2: '',
            isOffline: false,
            selectedOfflineDuration: null,
            offlineValue: '',
            isOnline: false,
            selectedOnlineDuration: null,
            onlineValue: '',
            isEmail: false,
            isSms: false,
            subject: '',
            message: '',
            recipient: '',
            arrayRecipients: [],
            isEnable: false,
        },
        validationSchema: Yup.object().shape({
            ruleName: Yup.string().max(255).required('Rule name is required'),
            selectedRuleType: Yup.object().required('Rule type is required'),
            selectedProduct: Yup.object().required('Product is required'),
            selectedType: Yup.object().required('Type is required'),
            selectedSensorMeasurement: isSensorMeasurment ? Yup.object().required('Sensor measurment is required') : '',
            selectedCondition: isSensorMeasurment ? Yup.object().required('Condition is required') : '',
            conditionValue1: isSensorMeasurment ? Yup.string().max(255).required('Value is required')
                .test(
                    'is-not-negative',
                    'Value must be greater than 0',
                    value => !/^-/.test(value)
                ) : "",
            conditionValue2: isSensorMeasurment && condition != null && (condition.id == "6" || condition.id == "7") ? Yup.string()
                .max(255)
                .required('Value 2 is required')
                .test(
                    'is-not-negative',
                    'Value must be greater than 0',
                    value => !/^-/.test(value)
                ) : "",
            selectedOfflineDuration: isOfflineDuration ? Yup.object().required('Offline duration is required') : "",
            offlineValue: isOfflineDuration ? Yup.string()
                .max(255)
                .required('Offline value is required')
                .test(
                    'is-not-negative',
                    'Value must be greater than 0',
                    value => !/^-/.test(value)
                )
                .test(
                    'min-value-check',
                    'Value must be greater than or equals to 15',
                    function (value) {
                        const { selectedOfflineDuration } = this.parent; // Access the other field
                        console.log("selectedOfflineDuration", selectedOfflineDuration);
                        console.log("value", value);
                        if (selectedOfflineDuration != null && selectedOfflineDuration.value === "Minutes") {
                            return value > 14; // Return true if value is greater than 50
                        }
                        return true; // If not "min", no validation error
                    }
                ) : "",
            // selectedOnlineDuration: isOnlineDuration  ? Yup.object().required('Online duration is required') : "",
            // onlineValue: isOnlineDuration ? Yup.string().max(255)
            //     .required('Online value is required')
            //     .test(
            //         'is-not-negative',
            //         'Value must be greater than 0',
            //         value => !/^-/.test(value)  // Custom test to ensure no negative sign
            //     ) : "",
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {

                var dataValues = {
                    ruleName: values.ruleName,
                    selectedRuleType: values.selectedRuleType,
                    selectedProduct: values.selectedProduct,
                    selectedType: values.selectedType,
                    arraySelected: values.arraySelected,
                    isSensorMeasurment: values.isSensorMeasurment,
                    selectedSensorMeasurement: values.selectedSensorMeasurement,
                    selectedCondition: values.selectedCondition,
                    conditionValue1: values.conditionValue1,
                    conditionValue2: values.conditionValue2,
                    isOffline: values.isOffline,
                    selectedOfflineDuration: values.selectedOfflineDuration,
                    offlineValue: values.offlineValue,
                    isOnline: values.isOnline,
                    selectedOnlineDuration: values.selectedOnlineDuration,
                    onlineValue: values.onlineValue,
                    isEmail: values.isEmail,
                    isSms: values.isSms,
                    subject: values.subject,
                    message: values.message,
                    arrayRecipients: values.arrayRecipients,
                    isEnable: values.isEnable,
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apicalling(values);
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('Rule ' + (selectedDataId == "" ? "added" : "updated"), {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    navigate(-1)
                } else {
                    setStatus({ success: false });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        if (selectedDataId != "") {
            getData()
        } else {
            setIsLoading(true)
            getDevideDetail()
        }
    }, [selectedDataId]);

    useEffect(() => {
        if (formik.values.selectedRuleType != null && formik.values.selectedType != null) {
            var ruleId = formik.values.selectedRuleType.id
            var trackerTypeId = formik.values.selectedType.trackerTypeId
            if (ruleId == "1" && (trackerTypeId == "92" || trackerTypeId == "85")) {
                const updatedMeasurements = arrayMeasurment.filter(item => item.value !== "Speed");
                setArrayMeasurementData(updatedMeasurements);
            } else {
                setArrayMeasurementData(arrayMeasurment);

            }
        }
    }, [activeTab]);

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Rules/getById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var selectedProduct = resData != null && resData.product != null && resData.ruleType == "1" ? arrayAssetDeviceProduct.find((product) => product.id == resData.product) : resData.ruleType == "2" ? arrayDeviceProduct.find((product) => product.id == resData.product) : arrayPersonnelDeviceProduct.find((product) => product.id == resData.product)
                var selectedType = resData != null && resData.product != null && resData.ruleType == "1" ? arrayDeviceType.find((type) => type.id == resData.type) : resData.ruleType == "2" ? arrayDeviceType.find((product) => product.id == resData.product) : arrayPersonnelDeviceType.find((product) => product.id == resData.product)
                var selectedRuleType = arrayRuleType.find((type) => type.id == resData.ruleType)
                var selectedMeasurment = arrayMeasurment.find((measurment) => measurment.id == resData.sensorMesurement)
                var selectedCondition = arrayCondition.find((condition) => condition.id == resData.condition)
                var selectedOfflinDuration = arrayThreshold.find((threshold) => threshold.id == resData.offlineDuration)
                var selectedOnlineDuration = arrayThreshold.find((threshold) => threshold.id == resData.onlineDuration)
                setSelectedRuleType(selectedRuleType)
                var values = {
                    ruleName: resData.ruleName,
                    selectedRuleType: selectedRuleType,
                    selectedProduct: selectedProduct,
                    selectedType: selectedType,
                    arraySelected: resData.arrayDevice,
                    isSensorMeasurment: resData.isSensorMesurement == "1" ? true : false,
                    selectedSensorMeasurement: selectedMeasurment,
                    selectedCondition: selectedCondition,
                    conditionValue1: resData.value1,
                    conditionValue2: resData.value2,
                    isOffline: resData.isOffline == "1" ? true : false,
                    selectedOfflineDuration: selectedOfflinDuration,
                    offlineValue: resData.offlineValue,
                    isOnline: resData.isOnline == "1" ? true : false,
                    selectedOnlineDuration: selectedOnlineDuration,
                    onlineValue: resData.onlineValue,
                    isEmail: resData.isEmail == "1" ? true : false,
                    isSms: resData.isSms == "1" ? true : false,
                    subject: resData.subject,
                    message: resData.message,
                    arrayRecipients: resData.arrayRecepient,
                    isEnable: resData.status == "1" ? true : false,
                }
                formik.setValues(values);
                setIsSensorMeasurment(resData.isSensorMesurement == "1" ? true : false)
                setIsOfflineDuration(resData.isOffline == "1" ? true : false)
                setArrayRecipients(resData.arrayRecepient)
                setArraySelected(resData.arrayDevice)
            }
            getDevideDetail(selectedProduct, selectedType, selectedRuleType.id)
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const goToNextTab = () => {
        const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);
        formik.validateForm().then((errors) => {
            formik.setErrors(errors);
            formik.setTouched({
                ruleName: true,
                selectedRuleType: true,
                selectedProduct: true,
                selectedType: true,
                selectedSensorMeasurement: true,
                selectedCondition: true,
                conditionValue1: true,
                conditionValue2: true,
                selectedOfflineDuration: true,
                offlineValue: true,
                selectedOnlineDuration: true,
                onlineValue: true,
            });
            const isAnyCheckboxSelected = formik.values.isSensorMeasurment || formik.values.isOffline || formik.values.isOnline;

            if (activeTab === tabs[0].value) {
                if (errors.ruleName || errors.selectedRuleType || errors.selectedProduct || errors.selectedType) {
                    console.log("err")
                } else {
                    if (activeTabIndex < tabs.length - 1) {
                        setActiveTab(tabs[activeTabIndex + 1].value);
                    }
                }
            } else if (activeTab === tabs[1].value) {
                if (!isAnyCheckboxSelected) {
                    enqueueSnackbar("Please select at least one trigger", {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                } else if (errors.selectedSensorMeasurement || errors.selectedCondition || errors.conditionValue1 || errors.conditionValue2 || errors.selectedOfflineDuration || errors.offlineValue) {
                    console.log("err")
                } else {
                    if (activeTabIndex < tabs.length - 1) {
                        setActiveTab(tabs[activeTabIndex + 1].value);
                    }
                }
            } else {
                if (activeTabIndex < tabs.length - 1) {
                    setActiveTab(tabs[activeTabIndex + 1].value);
                }
            }
        });
    };

    const goToPrevTab = () => {
        const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);

        if (activeTabIndex > 0) {
            setActiveTab(tabs[activeTabIndex - 1].value);
        }
    };

    async function apicalling(values) {
        var data = {
            ruleName: values.ruleName,
            ruleType: values.selectedRuleType.id,
            product: values.selectedProduct.id,
            type: values.selectedRuleType.id == "1" ? values.selectedType._id : values.selectedRuleType.id == "2" ? values.selectedType.id : values.selectedType.id,
            arrayDevice: values.arraySelected,
            isSensorMesurement: values.isSensorMeasurment ? "1" : "0",
            sensorMesurement: values.selectedSensorMeasurement ? values.selectedSensorMeasurement.id : null,
            condition: values.selectedCondition ? values.selectedCondition.id : null,
            value1: values.conditionValue1,
            value2: values.conditionValue2,
            isOffline: values.isOffline ? "1" : "0",
            offlineDuration: values.selectedOfflineDuration ? values.selectedOfflineDuration.id : null,
            offlineValue: values.offlineValue,
            isOnline: values.isOnline ? "1" : "0",
            onlineDuration: values.selectedOnlineDuration ? values.selectedOnlineDuration.id : null,
            onlineValue: values.onlineValue,
            isEmail: values.isEmail ? "1" : "0",
            isSms: values.isSms ? "1" : "0",
            subject: values.subject,
            message: values.message,
            status: values.isEnable ? "1" : "0",
            arrayRecepient: arrayRecipients,
        }
        console.log("dataValues", data);
        if (selectedDataId != "") {
            console.log("IF");
            return await api.put('/apis/rules/updateById/' + selectedDataId, data)
        } else {
            console.log("ELSE");
            return await api.post('/apis/rules/add', data)
        }
    }

    function deleteRule() {
        setIsLoading(true)
        api.delete('/apis/Rules/deleteById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Rule deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-1)
            } else {
                setIsLoading(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteRule()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var ruleType = formik.values.selectedRuleType != null ? formik.values.selectedRuleType.id : ""
        var filterArray = applyFilter(ruleType == "1" ? arrayAsset : ruleType == "2" ? arrayNode : arrayPersonnel, event.target.value, ruleType, formik.values.selectedProduct, formik.values.selectedType)
        setFilteredArrayData(filterArray)
    };

    const getDevideDetail = (selectedProduct, selectedDevice, ruleType, deviceType) => {
        //setIsLoading(true)
        api.get('/apis/Rules/getDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayNode(resData.arrayNode)
                setArrayAsset(resData.arrayAsset)
                setArrayPersonnel(resData.arrayPersonnel)
                setArrayTrackerType(resData.arrayTrackerType)

                // var ruleType = formik.values.selectedRuleType != null ? formik.values.selectedRuleType.id : ""
                var filterArray = applyFilter(ruleType == "1" ? resData.arrayAsset : ruleType == "2" ? resData.arrayNode : resData.arrayPersonnel, query, ruleType, selectedProduct, selectedDevice)
                console.log("filterArray", filterArray)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const applyFilter = (arrayDataAll, query, ruleType, selectedProduct, selectedDevice) => {
        console.log("arrayDataAll", arrayDataAll)
        console.log("query", query)
        console.log("ruleType", ruleType)
        console.log("selectedProduct", selectedProduct)
        console.log("selectedDevice", selectedDevice)

        if (selectedProduct == null) {
            return []
        }
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['nodeName', 'sensorNumber', 'assetName', 'serialNumber', 'firstName', 'lastName'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }

                    if (ruleType == 3) {
                        if (`${dataObj.firstName.trim()} ${dataObj.lastName.trim()}`.toLowerCase().includes(query.toLowerCase())) {
                            containsQuery = true;
                        }
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []
        // if (selectedProduct !== null) {
        //     var filteredArrayGroup = finalArray
        //         .filter((dataObj) => {
        //             let matches = true;
        //             let containsQuery = false;
        //             if (ruleType == "1") {
        //                 containsQuery = true;
        //             } else {
        //                 if (dataObj.manufacturer == selectedProduct.id) {
        //                     containsQuery = true;
        //                 }
        //             }
        //             if (!containsQuery) {
        //                 matches = false;
        //             }
        //             return matches;
        //         });
        //     finalArray = [...filteredArrayGroup]
        // }

        if (selectedDevice !== null) {
            var filteredArrayTracker = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (ruleType == "1") {
                        if (dataObj.product == selectedDevice.trackerTypeId) {
                            containsQuery = true;
                        }
                    } if (ruleType == "2") {
                        console.log("IF RuleType 2 Then Product :-", dataObj.product);
                        console.log("IF RuleType 2 Then Device :-", selectedDevice.id);
                        if (dataObj.product == selectedDevice.id) {
                            containsQuery = true;
                        }
                    } else {
                        if (dataObj.product == selectedDevice.id) {
                            containsQuery = true;
                        }
                    }
                    // console.log("containsQuery", containsQuery);


                    if (!containsQuery) {
                        matches = false;
                    }

                    return matches;
                });
            finalArray = [...filteredArrayTracker]
        }

        if (query === "" && selectedDevice == null) {
            return arrayDataAll
        }
        return finalArray
    }

    function onCheck(data) {

        if (arraySelected.some(obj => obj._id == data._id)) {
            setArraySelected(arraySelected.filter(obj => obj._id != data._id))
        } else {
            setArraySelected((prevState) => {
                const newArr = [...prevState];
                newArr.push(data);
                return newArr;
            });
        }

        var nodes = formik.values.arraySelected ? formik.values.arraySelected : []
        if (nodes.includes(data._id)) {
            nodes = nodes.filter(obj => obj != data._id)
        } else {
            nodes.push(data._id)
        }
        handleSetArrayNodes(nodes)

    }

    const handleHeaderClick = () => {
        var arrayData = filteredArrayData
        var nodes = formik.values.arraySelected ? formik.values.arraySelected : []
        if (formik.values.arraySelected.length === arrayData.length) {
            nodes = []
            setArraySelected([]);
        } else {
            nodes = arrayData.map(data => data._id)
            setArraySelected(arrayData);
        }
        handleSetArrayNodes(nodes)
    }

    const handleSetArrayNodes = (nodes) => {
        formik.setFieldValue('arraySelected', nodes);
    }

    const addRecipients = (value) => {
        var data = {
            recipient: value
        }
        const recipientExists = arrayRecipients.some((recipient) => recipient === value);

        if (!recipientExists) {
            setArrayRecipients((prevArrayRecipient) => [...prevArrayRecipient, value]);
            formik.setFieldValue('recipient', ''); // Reset the input field
        } else {
            enqueueSnackbar('Recipient already exists', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }

    const removeRecipients = (itemToRemove) => {
        setArrayRecipients(arrayRecipients.filter(item => item !== itemToRemove));
    };

    return (
        <FormikProvider value={formik}>
            <Stack
                flex={1}
                p={3}
                gap={3}
                overflow={'hidden'}
                component={'form'}
                onSubmit={formik.handleSubmit}
            >
                <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography textTransform={'uppercase'}>RULES ENGINE</Typography>
                </Stack>
                <Typography>Follow these quick steps to setup a custom rule for your nodes and assets.</Typography>
                <Stack direction={'row'} overflow={'hidden'} width={'100%'}>
                    {tabs.map((tab, idx) => (
                        <Stack
                            direction={'row'}
                            gap={1}
                            key={tab.value}
                            px={2}
                            py={1}
                            border={'1px solid #E2E2EA'}
                            borderRight={idx === tabs.length - 1 ? '1px solid #E2E2EA' : 'none'}
                            flex={1}
                            position={'relative'}
                            sx={{
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '0',
                                    left: 0,
                                    width: '100%',
                                    height: 3,
                                    bgcolor: 'secondary.main',
                                    display: activeTab === tab.value ? 'block' : 'none',
                                },
                            }}
                        >
                            <Typography>{idx + 1}.</Typography>
                            <Stack gap={0.5} overflow={'hidden'} flex={1}>
                                <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography>{tab.title}</Typography>
                                    {tabs.findIndex((tab) => tab.value === activeTab) !== idx &&
                                        tabs.findIndex((tab) => tab.value === activeTab) > idx && (
                                            <CheckCircle
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                    color: 'green.main',
                                                }}
                                            />
                                        )}
                                </Stack>
                                <Typography
                                    fontSize={12}
                                    color={'#AAB0BC'}
                                    overflow={'hidden'}
                                    whiteSpace={'nowrap'}
                                    textOverflow={'ellipsis'}
                                >
                                    {tab.text}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}

                    <Stack
                        direction={'row'}
                        gap={1}
                        py={1}
                        px={5}
                        alignItems={'center'}
                        justifyContent={'center'}
                        border={'1px solid #E2E2EA'}
                        flexShrink={0}
                    >

                        {selectedDataId != "" &&
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '5px',
                                    bgcolor: '#E2E2EA',
                                    height: '30px'
                                }}
                                onClick={(e) => {
                                    setDeleteConfirmationShouldOpen(true)
                                }}
                            >
                                <Typography fontSize={14}>Remove</Typography>
                            </ButtonBase>
                        }
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#E2E2EA',
                                borderRadius: '5px',
                                height: '30px'
                            }}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <Typography fontSize={14}>Cancel</Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                bgcolor: tabs.findIndex((tab) => tab.value === activeTab) === 0 && '#f0f0f3',
                                height: '30px'
                            }}
                            disabled={tabs.findIndex((tab) => tab.value === activeTab) === 0}
                            onClick={goToPrevTab}
                        >
                            <Typography fontSize={14} color={tabs.findIndex((tab) => tab.value === activeTab) === 0 && '#9a9aa4'}>
                                Back
                            </Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                bgcolor: tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1 && '#f0f0f3',
                                height: '30px'
                            }}
                            onClick={goToNextTab}
                            disabled={tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1}
                        >
                            <Typography
                                fontSize={14}
                                color={tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1 && '#9a9aa4'}
                            >
                                Next
                            </Typography>
                        </ButtonBase>

                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#FCFCFE',
                                borderRadius: '5px',
                                height: '30px'
                            }}
                            type='submit'
                            disabled={formik.isSubmitting}
                            onClick={(e) => { console.log("error", formik.errors); }}
                        >
                            <Typography color={'secondary.main'} fontSize={14} fontWeight={700} whiteSpace={'nowrap'}>
                                Save & Close
                            </Typography>
                        </ButtonBase>
                    </Stack>
                </Stack>

                {tabs[0].value === activeTab && (
                    <Box bgcolor={'#FAFBFD'} px={3} py={2} flex={1}>
                        <Stack direction={'row'}>
                            <Stack gap={3} maxWidth={650}>
                                <Box>
                                    <Grid spacing={3} container>
                                        <Grid xs={12} item>
                                            <Stack direction={'row'}  >
                                                <DefaultInput
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            pr: 6,
                                                        },
                                                    }}
                                                    label={'Rule Name'}
                                                    placeholder={'Enter a rule name….'}
                                                    value={formik.values.ruleName}
                                                    name={'ruleName'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.ruleName && formik.errors.ruleName)}
                                                    helperText={formik.touched.ruleName && formik.errors.ruleName}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                }}
                                                label={'Choose Rule Type'}
                                                value={formik.values.selectedRuleType}
                                                onItemSelected={(e) => {
                                                    formik.setFieldValue('selectedProduct', null)
                                                    formik.setFieldValue('selectedType', null)
                                                    formik.setFieldValue('arraySelected', []);
                                                    formik.setFieldValue('selectedRuleType', e.target.value)

                                                    setSelectedRuleType(e.target.value)
                                                    // var filterArray = applyFilter(e.target.value == "1" ? arrayAsset : e.target.value == "2" ? arrayNode : arrayPersonnel, query, e.target.value, formik.values.selectedProduct, formik.values.selectedType)
                                                    setFilteredArrayData([])
                                                }}
                                                helperText={formik.touched.selectedRuleType && formik.errors.selectedRuleType}
                                                error={Boolean(formik.touched.selectedRuleType && formik.errors.selectedRuleType)}
                                                // placeholder="Select category"
                                                displayKey="value"
                                            >
                                                {arrayRuleType.map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                ))}
                                            </DefaultSelect>
                                        </Grid>
                                        <Grid xs={8} item>
                                            <Box
                                                position={'relative'}
                                                height={'100%'}
                                                display={'flex'}
                                                alignItems={'flex-end'}
                                                pb={0.5}
                                            >
                                                <Stack>
                                                    <Typography color={'#262B40'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                                        Select Devices
                                                    </Typography>
                                                    <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} mt={1}>
                                                        Select the device products that will be included in this rule.
                                                    </Typography>
                                                </Stack>

                                            </Box>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Box position={'relative'}>
                                                <DefaultSelect
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: 40,
                                                        },
                                                    }}
                                                    label={'Choose Device Product'}
                                                    value={formik.values.selectedProduct}
                                                    onItemSelected={(e) => {
                                                        formik.setFieldValue('selectedProduct', e.target.value)
                                                        formik.setFieldValue('selectedSensorMeasurement', null)
                                                        formik.setFieldValue('selectedType', null)
                                                        var ruleType = formik.values.selectedRuleType != null ? formik.values.selectedRuleType.id : ""
                                                        // var filterArray = applyFilter(ruleType == "1" ? arrayAsset : ruleType == "2" ? arrayNode : arrayPersonnel, query, ruleType, e.target.value, formik.values.selectedType)
                                                        // setFilteredArrayData(filterArray)
                                                    }}
                                                    helperText={formik.touched.selectedProduct && formik.errors.selectedProduct}
                                                    error={Boolean(formik.touched.selectedProduct && formik.errors.selectedProduct)}
                                                    // placeholder="Select category"
                                                    displayKey="value"
                                                >
                                                    {formik.values.selectedRuleType == null ? [] : formik.values.selectedRuleType.id == "1" ?
                                                        arrayAssetDeviceProduct.map((item, index) => (
                                                            <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                        ))
                                                        :
                                                        formik.values.selectedRuleType.id == "2" ?
                                                            arrayDeviceProduct.map((item, index) => (
                                                                <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                            ))
                                                            :
                                                            arrayPersonnelDeviceProduct.map((item, index) => (
                                                                <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                            ))}
                                                </DefaultSelect>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Box position={'relative'}>
                                                <DefaultSelect
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: 40,
                                                        },
                                                    }}
                                                    label={'Choose Device Type'}
                                                    value={formik.values.selectedType}
                                                    onItemSelected={(e) => {
                                                        formik.setFieldValue('selectedType', e.target.value)
                                                        formik.setFieldValue('arraySelected', [])
                                                        var ruleType = formik.values.selectedRuleType != null ? formik.values.selectedRuleType.id : ""
                                                        var filterArray = applyFilter(ruleType == "1" ? arrayAsset : ruleType == "2" ? arrayNode : arrayPersonnel, query, ruleType, formik.values.selectedProduct, e.target.value)
                                                        setFilteredArrayData(filterArray)

                                                    }}
                                                    helperText={formik.touched.selectedType && formik.errors.selectedType}
                                                    error={Boolean(formik.touched.selectedType && formik.errors.selectedType)}
                                                    // placeholder="Select type"
                                                    displayKey={formik.values.selectedRuleType == null ? "" : formik.values.selectedRuleType.id == "1" ? "name" : "value"}
                                                >
                                                    {formik.values.selectedRuleType == null ? [] : formik.values.selectedRuleType.id == "1" ?
                                                        formik.values.selectedProduct != null && arrayTrackerType.map((item, index) => (
                                                            <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                                        ))
                                                        : formik.values.selectedRuleType.id == "2" ?
                                                            formik.values.selectedProduct != null && arrayDeviceType.filter(obj => obj.id == formik.values.selectedProduct.id).map((item, index) => (
                                                                <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                            )) :
                                                            formik.values.selectedProduct != null && arrayPersonnelDeviceType.filter(obj => obj.id == formik.values.selectedProduct.id).map((item, index) => (
                                                                <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                            ))}
                                                </DefaultSelect>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Box position={'relative'}>
                                                <SearchInput name={'search'} handleQueryChange={handleQueryChange} placeholder={'Enter device ID (Serial) or Name'} sx={{ minWidth: '200px', bgcolor: '#FFFFFF' }} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box height={'1px'} bgcolor={'#E2E2EA'} />
                                <Stack flex={1} border={'1px solid #E1E3E8'} borderBottom={'none'} borderRadius={''} direction={'row'}>
                                    <DefaultDataGrid rows={filteredArrayData} columns={columns(filteredArrayData, onCheck, handleHeaderClick, formik, selectedRuleType)} onRowClick={({ id }) => {
                                        console.log("id", id);
                                    }} />
                                </Stack>
                            </Stack>
                            <Stack
                                direction={'row'}
                                gap={1}
                                alignItems={'flex-start'}
                                justifyContent={'space-between'}
                                maxWidth={650}
                                width={'100%'}
                                ml={5}
                                mt={-1}
                            >
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <DefaultToggle name={'isEnable'} />
                                    <Typography fontSize={14}>ACTIVE RULE</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                )}
                {tabs[1].value === activeTab && (
                    <Box bgcolor={'#FFFFFF'} px={3} py={2} flex={1}>
                        <Stack gap={3} maxWidth={650}>
                            <Box>
                                <Grid spacing={3} container>
                                    <Grid xs={11} item>
                                        <Box
                                            position={'relative'}
                                            height={'100%'}
                                            display={'flex'}
                                            alignItems={'flex-end'}
                                            pb={0.5}
                                        >
                                            <Stack>
                                                <Typography color={'#1F243B'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                                    Select Trigger
                                                </Typography>
                                                <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} mt={1}>
                                                    Select a trigger to set when the rule is fired
                                                </Typography>
                                                <Stack>
                                                    <FormControlLabel
                                                        name={'isSensorMeasurment'}
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.isSensorMeasurment}
                                                                onChange={(e) => {
                                                                    if (formik.values.isSensorMeasurment) {
                                                                        formik.setFieldValue("isSensorMeasurment", false)
                                                                        formik.setFieldValue('selectedSensorMeasurement', "")
                                                                        formik.setFieldValue('selectedCondition', "")
                                                                        formik.setFieldValue('conditionValue1', "")
                                                                        formik.setFieldValue('conditionValue2', "")
                                                                        setIsSensorMeasurment(false)
                                                                    } else {
                                                                        formik.setFieldValue("isSensorMeasurment", true)
                                                                        setIsSensorMeasurment(true)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label='Sensor Measurement'
                                                    />
                                                    <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} ml={3.8}>
                                                        Execute rule for a device when it receives a new measurement that is referenced in a condition.
                                                    </Typography>

                                                    <FormControlLabel
                                                        name={'isOffline'}
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.isOffline}
                                                                onChange={(e) => {
                                                                    if (formik.values.isOffline) {
                                                                        formik.setFieldValue("isOffline", false)
                                                                        formik.setFieldValue('selectedOfflineDuration', "")
                                                                        formik.setFieldValue('offlineValue', "")
                                                                        setIsOfflineDuration(false)
                                                                    } else {
                                                                        formik.setFieldValue("isOffline", true)
                                                                        setIsOfflineDuration(true)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label='Device is Offline'
                                                    />
                                                    <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} ml={3.8}>
                                                        Execute rule for a device when it goes offline by exceeding the time value.
                                                    </Typography>

                                                    <FormControlLabel
                                                        name={'isOnline'}
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.isOnline}
                                                                onChange={(e) => {
                                                                    if (formik.values.isOnline) {
                                                                        formik.setFieldValue("isOnline", false)
                                                                        formik.setFieldValue('selectedOnlineDuration', "")
                                                                        formik.setFieldValue('onlineValue', "")
                                                                        setIsOnlineDuration(false)
                                                                    } else {
                                                                        formik.setFieldValue("isOnline", true)
                                                                        setIsOnlineDuration(true)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label='Device is Online'
                                                    />
                                                    <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} ml={3.8}>
                                                        Execute this rule for a device when it comes back online after being offline
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Grid>

                                    {/* IS SENSOR MEASURMENT */}

                                    {formik.values.isSensorMeasurment && <Grid xs={6} item>
                                        <DefaultSelect
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    height: 40,
                                                },
                                            }}
                                            label={'Sensor Measurement'}
                                            value={formik.values.selectedSensorMeasurement}
                                            onItemSelected={(e) => {
                                                formik.setFieldValue('selectedSensorMeasurement', e.target.value)
                                            }}
                                            helperText={formik.touched.selectedSensorMeasurement && formik.errors.selectedSensorMeasurement}
                                            error={Boolean(formik.touched.selectedSensorMeasurement && formik.errors.selectedSensorMeasurement)}
                                            placeholder={"Selected Sensor Measurement"}
                                        // disabled={!formik.values.isEnable}
                                        >
                                            {arrayMeasurment.filter(item => item.array.includes(formik.values.selectedProduct.id)).map((item, index) => (
                                                <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                            ))}

                                        </DefaultSelect>
                                    </Grid>}
                                    {formik.values.isSensorMeasurment && <Grid xs={6} item>

                                        <DefaultSelect
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    height: 40,
                                                },
                                            }}
                                            label={'Condition'}
                                            value={formik.values.selectedCondition}
                                            onItemSelected={(e) => {
                                                formik.setFieldValue('selectedCondition', e.target.value)
                                                setCondition(e.target.value)
                                            }}
                                            helperText={formik.touched.selectedCondition && formik.errors.selectedCondition}
                                            error={Boolean(formik.touched.selectedCondition && formik.errors.selectedCondition)}
                                            placeholder={"Select Sensor Condition"}
                                        // disabled={!formik.values.isEnable}

                                        >
                                            {arrayCondition.map((item, index) => (
                                                <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                            ))}

                                        </DefaultSelect>

                                    </Grid>}
                                    {formik.values.isSensorMeasurment && <Grid xs={6} item>
                                        <DefaultInput
                                            label={'Value'}
                                            value={formik.values.conditionValue1}
                                            name={'conditionValue1'}
                                            type={'number'}
                                            placeholder={'Enter a value'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.conditionValue1 && formik.errors.conditionValue1)}
                                            helperText={formik.touched.conditionValue1 && formik.errors.conditionValue1}
                                        // disabled={!formik.values.isEnable}
                                        />
                                    </Grid>}
                                    {formik.values.isSensorMeasurment && formik.values.selectedCondition != null && (formik.values.selectedCondition.id == "6" || formik.values.selectedCondition.id == "7") && <Grid xs={6} item>
                                        <DefaultInput
                                            label={'Value 2'}
                                            value={formik.values.conditionValue2}
                                            name={'conditionValue2'}
                                            type={'number'}
                                            placeholder={'Enter a value 2'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.conditionValue2 && formik.errors.conditionValue2)}
                                            helperText={formik.touched.conditionValue2 && formik.errors.conditionValue2}
                                        // disabled={!formik.values.isEnable}
                                        />
                                    </Grid>}

                                    {/* IS OFFLINE */}

                                    {formik.values.isOffline && <Grid xs={11} item>
                                        <Box
                                            position={'relative'}
                                            height={'100%'}
                                            display={'flex'}
                                            alignItems={'flex-end'}
                                        >
                                            <Stack>
                                                <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'}>
                                                    <Typography color={'#262B40'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                                        Offline Threshold
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'red.main',
                                                        }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Grid>}
                                    {formik.values.isOffline && <Grid xs={18} item>
                                        <Stack direction={'row'} gap={2} mt={-3}>
                                            <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} width={750}>
                                                Execute trigger when no data is received from device within the following time parameters (Hours) or (Minutes)
                                            </Typography>
                                            <Stack width={800}>
                                                <DefaultSelect
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: 40,
                                                        },
                                                    }}
                                                    value={formik.values.selectedOfflineDuration}
                                                    onItemSelected={(e) => {
                                                        formik.setFieldValue('selectedOfflineDuration', e.target.value)
                                                    }}
                                                    helperText={formik.touched.selectedOfflineDuration && formik.errors.selectedOfflineDuration}
                                                    error={Boolean(formik.touched.selectedOfflineDuration && formik.errors.selectedOfflineDuration)}
                                                    placeholder={'Select duration'}

                                                // disabled={!formik.values.isEnable}
                                                >
                                                    {arrayThreshold.map((item, index) => (
                                                        <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                    ))}

                                                </DefaultSelect>
                                            </Stack>
                                            <Stack width={'40%'}>
                                                <DefaultInput
                                                    value={formik.values.offlineValue}
                                                    name={'offlineValue'}
                                                    type={'number'}
                                                    placeholder={'Enter a value'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.offlineValue && formik.errors.offlineValue)}
                                                    helperText={formik.touched.offlineValue && formik.errors.offlineValue}
                                                // disabled={!formik.values.isEnable}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>}

                                    {/* IS ONLINE */}

                                    {/* {formik.values.isOnline && <Grid xs={11} item>
                                        <Box
                                            position={'relative'}
                                            height={'100%'}
                                            display={'flex'}
                                            alignItems={'flex-end'}
                                            pb={0.5}
                                        >
                                            <Stack>
                                                <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'}>
                                                    <Typography color={'#262B40'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                                        Online Threshold
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'green.main',
                                                        }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Grid>} */}
                                    {/* {formik.values.isOnline && <Grid xs={18} item>
                                        <Stack direction={'row'} gap={2} mt={-3}>
                                            <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} width={750}>
                                                Execute trigger when data is received from device within the following time parameters (Hours) or (Minutes) after being offline.
                                            </Typography>
                                            <Stack width={800}  >
                                                <DefaultSelect
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: 40,
                                                        },
                                                    }}
                                                    value={formik.values.selectedOnlineDuration}
                                                    onItemSelected={(e) => {
                                                        formik.setFieldValue('selectedOnlineDuration', e.target.value)
                                                    }}
                                                    helperText={formik.touched.selectedOnlineDuration && formik.errors.selectedOnlineDuration}
                                                    error={Boolean(formik.touched.selectedOnlineDuration && formik.errors.selectedOnlineDuration)}
                                                    placeholder={'Select duration'}
                                                // disabled={!formik.values.isEnable}
                                                >
                                                    {arrayThreshold.map((item, index) => (
                                                        <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                    ))}

                                                </DefaultSelect>
                                            </Stack>
                                            <Stack width={'40%'}>
                                                <DefaultInput
                                                    value={formik.values.onlineValue}
                                                    name={'onlineValue'}
                                                    type={'number'}
                                                    placeholder={'Enter a value'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.onlineValue && formik.errors.onlineValue)}
                                                    helperText={formik.touched.onlineValue && formik.errors.onlineValue}
                                                // disabled={!formik.values.isEnable}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>} */}
                                </Grid>
                            </Box>
                        </Stack>
                    </Box>
                )}
                {tabs[2].value === activeTab && (
                    <>
                        <Stack spacing={2} flex={1}>
                            <Stack>
                            </Stack>
                            <Stack gap={3} maxWidth={650}>
                                <Box>
                                    <Grid spacing={3} container>
                                        <Grid xs={11} item>
                                            <Box
                                                position={'relative'}
                                                height={'100%'}
                                                display={'flex'}
                                                alignItems={'flex-end'}
                                                pb={0.5}
                                                pl={1.5}
                                            >
                                                <Stack>
                                                    <Typography color={'#1F243B'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                                        Select Action
                                                    </Typography>
                                                    <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} mt={1}>
                                                        Select an action when rule is triggered by the conditions.
                                                    </Typography>
                                                    <Stack>
                                                        <FormControlLabel
                                                            name={'isEmail'}
                                                            control={
                                                                <Checkbox
                                                                    checked={formik.values.isEmail}
                                                                    onChange={(e) => {
                                                                        if (formik.values.isEmail) {
                                                                            formik.setFieldValue("isEmail", false)
                                                                        } else {
                                                                            formik.setFieldValue("isEmail", true)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label='Send Email'
                                                        />
                                                        <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} ml={3.8}>
                                                            Execute rule for a device when it receives a new measurement that is referenced in a condition.
                                                        </Typography>

                                                        <FormControlLabel
                                                            name={'isSms'}
                                                            control={
                                                                <Checkbox
                                                                    checked={formik.values.isSms}
                                                                    onChange={(e) => {
                                                                        if (formik.values.isSms) {
                                                                            formik.setFieldValue("isSms", false)
                                                                        } else {
                                                                            formik.setFieldValue("isSms", true)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label='Send SMS'
                                                        />
                                                        <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} ml={3.8}>
                                                            Execute rule for a device when it goes offline by exceeding the time value.
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                        {formik.values.isEmail && <Grid xs={12} item>
                                            <Stack pl={1.5}>
                                                <DefaultInput
                                                    value={formik.values.subject}
                                                    name={'subject'}
                                                    label={'Subject (Email Only)'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.subject && formik.errors.subject)}
                                                    helperText={formik.touched.subject && formik.errors.subject}
                                                // disabled={!formik.values.isEnable}
                                                />
                                            </Stack>
                                        </Grid>}
                                        {/* <Grid xs={12} item>
                                            <Stack pl={1.5}>
                                                <DefaultInput
                                                    value={formik.values.message}
                                                    name={'message'}
                                                    multiline
                                                    rows={4}
                                                    label={'Message'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.message && formik.errors.message)}
                                                    helperText={formik.touched.message && formik.errors.message}
                                                // disabled={!formik.values.isEnable}
                                                />
                                            </Stack>
                                        </Grid> */}
                                        <Grid xs={6} item>
                                            <Stack pl={1.5}>
                                                <DefaultInput
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            pr: 2,
                                                        },
                                                    }}
                                                    label={'Recipients'}
                                                    value={formik.values.recipient}
                                                    placeholder={'example@domain.com or 000-000-0000'}
                                                    name={'recipient'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.recipient && formik.errors.recipient)}
                                                    helperText={formik.touched.recipient && formik.errors.recipient}
                                                ></DefaultInput>
                                            </Stack>
                                        </Grid>
                                        <Grid xs={6} item mt={2.3} ml={-1.5}>
                                            <ButtonBase
                                                sx={{
                                                    py: 1,
                                                    px: 3,
                                                    border: '1px solid #E2E2EA',
                                                    borderRadius: '5px',
                                                    bgcolor: '#FFFFFF',
                                                }}
                                                onClick={() => { addRecipients(formik.values.recipient) }}
                                            >
                                                <Typography fontSize={13} color={'#1F243B'} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                                    Add
                                                </Typography>
                                            </ButtonBase>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Stack pl={1.5}>
                                                <Stack
                                                    width={'100%'}
                                                    sx={{
                                                        border: '1px solid #E2E2EA',
                                                        borderRadius: '8px',
                                                        borderRightWidth: '1px',
                                                    }}
                                                    overflow={'hidden'}
                                                    position={'relative'}
                                                    mb={3}
                                                >
                                                    <Stack direction={'row'} ml={-1.5}>
                                                        <DefaultDataGrid columns={recipientsColoumn(removeRecipients)} rows={arrayRecipients.map((recipient, index) => ({ id: index + 1, recipient }))}
                                                        // onRowClick={(e) => { onTrackerClick(e.row) }}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </Stack>
                    </>
                )}
            </Stack>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider >
    );
};
