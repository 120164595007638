import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ColorStatus } from '../../ui/ColorStatus/index.js';
import "@flaticon/flaticon-uicons/css/all/all.css";
export const SidebarItem = ({ data, isOpened }) => {
    const location = useLocation();
    const isActive = location.pathname.includes(data.link) || (data.link === '/asset/asset-mapview' && location.pathname === '/');
    return (
        <>
            <NavLink to={data.link}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={!isOpened && 'center'}
                    gap={2}
                    position={'relative'}
                    px={isOpened ? 3 : 1.5}
                    py={1}
                    sx={{
                        pointerEvents: isActive ? 'none' : 'auto',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            svg: {
                                fill: '#fff',
                            },
                        },
                    }}
                >
                    {isActive && (
                        <Box
                            position={'absolute'}
                            left={0}
                            height={'100%'}
                            bgcolor={'#1E75FF'}
                            width={3}
                            borderRadius={'0 13px 13px 0'}
                        />
                    )}
                    <Stack

                        alignItems={'center'}
                        width={24}
                        height={24}
                        sx={{ paddingTop: '1px' }}

                    >
                        {!data.isFont ?
                            <i className={data.icon} style={{ fontSize: "22px", color: "white" }}></i>
                            :
                            <Box component={'img'} src={data.icon} sx={{ fontSize: "22px", color: "white" }}></Box>
                        }
                    </Stack>
                    <Box
                        sx={{
                            transition: 'all 0.15s ease',
                            transitionDelay: isOpened ? '1s' : '0s',
                            display: isOpened ? 'block' : 'none',
                            height: isOpened ? '20px' : 0,
                        }}
                    >
                        <Typography
                            sx={{
                                transform: isActive ? 'translate(3px)' : 'unset',
                                transition: 'all 0.15s ease',
                            }}
                            color={'common.white'}
                            fontWeight={isActive ? 700 : 400}
                            fontFamily={'Roboto'}
                            fontSize={14}
                            whiteSpace={'nowrap'}
                        >
                            {data.title}
                        </Typography>
                    </Box>
                    {data.statusCode && !isOpened && data.notificationsCount != 0 && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 'calc(50% + 4px)',
                                top: 4,
                            }}
                        >
                            <ColorStatus code={data.statusCode} />
                        </Box>
                    )}
                    {isOpened && data.notificationsCount && (
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            {data.statusCode && <ColorStatus code={data.statusCode} />}
                            <Box px={2} py={0.5} border={'2px solid'} borderColor={'common.white'} borderRadius={'24px'}>
                                <Typography fontSize={12} color={'common.white'} lineHeight={1}>
                                    {data.notificationsCount}
                                </Typography>
                            </Box>
                        </Stack>
                    )}
                </Stack>
            </NavLink>
        </>
    );
};
