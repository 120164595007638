import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

export const Tabs = ({ data, activeTab, setActiveTab, inline, small, activeColor, activeFontMedium }) => {
    var activeTabColor = activeColor ? activeColor : 'secondary.main'
    var activeTabFontStyle = activeFontMedium ? activeFontMedium : 'Bold'

    useEffect(() => {
        console.log("small", small);
        if (!small) {
            if (inline) {
                const storedActiveTab = localStorage.getItem('activeTab');
                if (storedActiveTab) {
                    setActiveTab(storedActiveTab);
                }
            }
        }
    }, [setActiveTab, inline, small]);

    const handleTabClick = (tabValue) => {
        // Update the active tab both in state and localStorage

        setActiveTab(tabValue);
        if (!small) {
            localStorage.setItem('activeTab', tabValue);
        }

    };
    return (
        <>
            {!inline && (
                <Stack display={'inline-flex'} direction={'row'} borderBottom={'1px solid #E1E3E8'}>
                    {data?.map((item, idx) => {
                        var showTab = false;
                        if (item.roles == null) {
                            showTab = true;
                        } else if (item.roles.includes(localStorage.getItem("role"))) {
                            showTab = true;
                        }
                        if (showTab) {
                            const isActive = item.value === activeTab;
                            return (
                                <Stack
                                    pt={1}
                                    pb={1}
                                    px={'15px'}
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                    key={idx}
                                    position={'relative'}
                                    onClick={() => setActiveTab(item.value)}
                                >
                                    <Typography
                                        fontSize={14}
                                        fontWeight={isActive ? activeTabFontStyle : 400}
                                        color={isActive ? activeTabColor : '#787C81'}

                                        ml={'7px'}
                                    >
                                        {item.title}
                                    </Typography>
                                    {isActive && (
                                        <Box
                                            width={'40px'}
                                            height={3}
                                            bgcolor={activeTabColor}
                                            position={'absolute'}
                                            bottom={0}
                                            left={0}
                                            ml={'22px'}
                                        />
                                    )}
                                </Stack>
                            );
                        } else {
                            return "";
                        }
                    })}
                </Stack>
            )}

            {inline && (
                <Stack
                    display={small ? 'flex' : 'inline-flex'}
                    direction={'row'}
                    border={'1px solid #F1F1F5'}
                    p={'3px'}
                    bgcolor={'#FCFCFE'}
                    height={'30px'}
                    sx={{
                        borderRadius: '8px',
                        overflow: 'hidden',
                    }}
                    alignItems={'center'}
                >
                    {data?.map((item, idx) => {
                        const isActive = item.value === activeTab;

                        return (
                            <Stack
                                pt={small ? 0 : 1}
                                pb={small ? 0 : 1}
                                height={'30px'}
                                px={small ? 1 : 2}
                                sx={{
                                    cursor: 'pointer',
                                }}
                                key={idx}
                                direction={item.isIconRight ? 'row-reverse' : 'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                flex={small ? 1 : 'unset'}
                                gap={1}
                                position={'relative'}
                                onClick={() => handleTabClick(item.value)} // Handle tab click
                                bgcolor={isActive ? 'common.white' : 'transparent'}
                                borderRadius={'8px'}
                                boxShadow={isActive ? '0px 2px 6px rgba(146, 146, 157, 0.07)' : 'none'}
                            >
                                {item?.icon && (
                                    <Stack
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        sx={{
                                            svg: {
                                                color: isActive ? "#4CDCB9" : "#979797",
                                                width: 16,
                                                height: 16,
                                            },
                                        }}
                                    >
                                        {item.icon}
                                    </Stack>
                                )}
                                <Typography
                                    fontSize={12}
                                    fontWeight={isActive ? 700 : 400}
                                    color={isActive ? activeTabColor : 'primary.main'}
                                >
                                    {item.title}
                                </Typography>
                            </Stack>
                        );
                    })}
                </Stack>
            )}
        </>
    );
};
