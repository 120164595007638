import { Box, Stack, Typography, Backdrop, CircularProgress, MenuItem, ButtonBase, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Tabs } from '../../../ui/Tabs/index.js';
import { Datepicker } from '../../../ui/Datepicker/index.js';
import { internalEventTabs } from '../../../modules/Asset/data/internalSensorsTabs.js';
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/index.js';
import { eventsColumns } from '../../../modules/Asset/data/events.jsx';
import dayjs from 'dayjs';
import api from '../../../lib/axios.js';
import { Filter } from '../../../ui/Filter/Filter.jsx';
import { columns } from '../../../modules/RuleListing/data/rule.jsx';
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';
import { arrayCondition, arrayMeasurment } from '../../../modules/RuleNew/data/arrayData.jsx';
import { arrayDeviceProduct, arrayDeviceType } from '../../../modules/RuleNew/data/arrayData.jsx';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { enqueueSnackbar } from 'notistack';
import excelFile from '../../../assets/images/excelFile.svg';


export const Events = ({ assetData, trackerData }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(internalEventTabs[0].value);
    const [arrayNotification, setArrayNotification] = useState([])
    const [arrayFilter, setArrayFilter] = useState([])
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [selectedAllEvent, setSelectedAllEvents] = useState(-1)
    const [arrayRule, setArrayRule] = useState([])
    const [isForRuleListing, setIsForRuleListing] = useState(false);
    const [arrayTrackerType, setArrayTrackerType] = useState([]);
    const [arrayEventsCounts, setArrayEventsCounts] = useState([]);

    useEffect(() => {
        if (assetData != null) {
            getNotifications(startDate, endDate)
            getRuleListByAssest()
        }
    }, [assetData]);

    useEffect(() => {
        getTrackerType()
    }, []);

    function getTrackerType() {
        api.get('/apis/TrackerTypes/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var arrayDevice = []
                resData.map(obj => {
                    arrayDevice.push({ id: obj._id, value: obj.name })
                })
                setArrayTrackerType(arrayDevice)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getNotifications = async (startDate, endDate) => {
        if (trackerData == null) {
            return
        }
        setIsLoading(true);
        let response = await api.post('/apis/RuleEvents/byAsset', { startDate: startDate.format('MM-DD-YYYY'), endDate: endDate.format('MM-DD-YYYY'), assetID: assetData._id })
        setIsLoading(false);
        const resData = response.data.data.arrayData
        const arrayEvent = response.data.data.eventsWithCounts
        setArrayNotification(resData)
        setArrayEventsCounts(arrayEvent)
        setArrayFilter(resData)
        // filter(response.data.data, activeTab)
    }

    // const filter = (arrayNotification, type) => {
    //     let filteredNotifications = arrayNotification
    //     if (type == 1) {
    //         filteredNotifications = arrayNotification.filter(
    //             (notification) => notification.eventType === "1" || notification.eventType === "2"
    //         );
    //     } else if (type == 2) {
    //         filteredNotifications = arrayNotification.filter(
    //             (notification) => notification.eventType === "5" || notification.eventType === "6"
    //         );
    //     } else if (type == 3) {
    //         filteredNotifications = arrayNotification.filter(
    //             (notification) => notification.eventType === "3" || notification.eventType === "4"
    //         );
    //     } else if (type == 4) {
    //         filteredNotifications = arrayNotification.filter(
    //             (notification) => notification.eventType === "-1"
    //         );
    //     }
    //     var filterArrayEvent = applyFilters(filteredNotifications, selectedAllEvent)
    //     setArrayFilter(filterArrayEvent)
    // }

    const handleStartDateChange = (date) => {
        const isStartDateAfterEndDate = date.isAfter(endDate);
        const isStartDateEqualToEndDate = date.isSame(endDate, 'day');
        if (isStartDateAfterEndDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setStartDate(date)
            getNotifications(date, endDate)
        }
    };

    const handleEndDateChange = (date) => {
        const isEndDateAfterStartDate = date.isAfter(startDate);
        const isStartDateEqualToEndDate = date.isSame(startDate, 'day');
        if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("End date must be after start date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setEndDate(date)
            getNotifications(startDate, date)
        }
    };

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
        filter(arrayNotification, tab)
    };

    const applyFilters = (arrayDataAll, selectedEvents) => {
        var finalArray = arrayDataAll
        if (selectedEvents !== null && selectedEvents != -1) {
            if (arrayDataAll.length > 0) {
                var filteredArrayEvents = arrayDataAll
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        console.log("dataObj.ruleID", dataObj.ruleID);
                        console.log("selectedAllEvent._id", selectedEvents);

                        if (dataObj.ruleID === selectedEvents) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
            } else {
                return arrayDataAll
            }
            finalArray = [...filteredArrayEvents]
        }

        if (selectedEvents === -1) {
            return arrayDataAll
        }
        return finalArray
    }

    const getRuleListByAssest = () => {
        setIsLoading(true)
        api.get('/apis/Rules/byAsset/' + assetData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayRule(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);

        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }

    const handleExportEventsData = () => {
        if (arrayFilter.length > 0) {
            const updatedArrayData = arrayFilter.map((res) => {
                const timeStamp = moment(res.updateAt).format("MM/DD/YY hh:mma")
                const measurment = arrayMeasurment.find((measurement) => measurement.id == res.sensorMesurement)?.value
                const condition = arrayCondition.find((condition) => condition.id == res.condition)?.value
                return { "Event ID": res.eventID, Rule: res.ruleName, Measurement: measurment, Condition: condition, "Data value": res.value, Timestamp: timeStamp };
            });
            const csvData = Papa.unparse(updatedArrayData);

            // Create a Blob object containing the CSV data
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a download link and trigger the click event to download the CSV file
            const link = document.createElement('a');
            link.href = url;
            link.download = `Events.csv`;
            link.click();

            // Release the Blob URL
            URL.revokeObjectURL(url);
        } else {
            enqueueSnackbar("There is no data to export", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }

    }

    const handleExportRulesData = () => {
        if (arrayRule.length > 0) {
            const updatedArrayData = arrayRule.map((res) => {
                const timeStamp = moment(res.updatedAt).format("MM/DD/YY hh:mma")
                const status = res.status == "1" ? "Active" : "Disabled"
                const device = arrayTrackerType.find((type) => type.id == res.type)?.value
                const product = arrayDeviceProduct.find((product) => product.id == res.product)?.value
                return { "Rule Name": res.ruleName, Status: status, Product: product, Device: device, "Last Modified": timeStamp };
            });
            const csvData = Papa.unparse(updatedArrayData);

            // Create a Blob object containing the CSV data
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a download link and trigger the click event to download the CSV file
            const link = document.createElement('a');
            link.href = url;
            link.download = `Rules.csv`;
            link.click();

            // Release the Blob URL
            URL.revokeObjectURL(url);
        } else {
            enqueueSnackbar("There is no data to export", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }

    return (
        <>
            <Stack mb={2} direction={'row'} gap={"10px"} alignItems={'center'}>
                {isForRuleListing == false && < Box maxWidth={200} width={'100%'} >
                    <Filter
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedAllEvents(value)
                            var filterArray = applyFilters(arrayNotification, value,)
                            setArrayFilter(filterArray)
                        }}
                        defaultValue={selectedAllEvent}
                        placeholder="All Event"
                    >
                        {arrayRule.map((item, index) => (
                            <MenuItem key={index} value={item._id}> {item.ruleName}</MenuItem>
                        ))}
                    </Filter>
                </Box>}
                <Stack direction={'row'} gap={1} >
                    <Datepicker initialDate={startDate} handleDateChange={handleStartDateChange} />
                    <Datepicker initialDate={endDate} handleDateChange={handleEndDateChange} />
                </Stack>
                <ButtonBase
                    sx={{
                        pt: 1,
                        pb: 1,
                        px: 5,
                        border: '1px solid #E2E2EA',
                        bgcolor: isForRuleListing == true ? "#1E75FF" : "#FCFCFE",
                        borderRadius: '5px',
                        height: 30,
                    }}
                    onClick={() => {
                        if (isForRuleListing) {
                            setIsForRuleListing(false)
                        } else {
                            setIsForRuleListing(true)
                        }
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                        <IconButton
                            sx={{
                                height: 10,
                                width: 10,
                                pr: 2
                            }}
                        >
                            <SettingsIcon sx={{ color: isForRuleListing == true ? "#FFFFFF" : "#151D26", }} />
                        </IconButton >
                        <Typography fontSize={12} fontFamily={"Roboto"} fontWeight={'Medium'} color={isForRuleListing == true ? "#FAFAFB" : "#151D26"}>
                            {isForRuleListing == true ? "Events" : "Rules"}
                        </Typography>
                    </Stack>
                </ButtonBase>
                <ButtonBase
                    sx={{
                        height: 30,
                        width: 30,
                        px: 2,
                        py: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        border: '1px solid #E2E2EA',
                        bgcolor: '#F6F8FA',
                        borderRadius: '6px',
                    }}
                    onClick={() => {
                        if (!isForRuleListing) {
                            handleExportEventsData()
                        } else {
                            handleExportRulesData()
                        }
                    }}
                >
                    <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />
                </ButtonBase>
                <Stack direction={"row"} gap={'10px'}>
                    {arrayEventsCounts.map((data, index) => (
                        <Box
                            key={index}
                            sx={{
                                border: '1px solid #E2E2EA',
                                borderRadius: '20px',
                                bgcolor: '#F7F7F7',
                                height: '30px',
                                pr: "34px",
                                pl: '15px',
                                alignContent: 'center'
                            }}
                        >
                            <Stack direction={'row'} gap={"3px"}>
                                <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={400} color={'#44444F'}>{index == 0 ? data.eventType : arrayMeasurment.find(obj => obj.id == data.eventType)?.value}</Typography>
                                <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={700} color={'#44444F'}>{index == 0 ? data.count : data.count}</Typography>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            </Stack>
            <Stack

                direction={'row'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 'none',
                    height: 'calc(100vh - 238px)'

                }}
                mb={1}
                width={'calc(100% + 24px)'}
                overflow={'hidden'}
            >
                {/* <DefaultDataGrid rows={arrayFilter} columns={eventsColumns} pageItems={6} pageSizeOptions={[6, 10, 20]} /> */}
                {isForRuleListing ? (
                    <DefaultDataGrid rows={arrayRule} columns={columns(calculateDuration, arrayTrackerType)} onRowClick={(e) => navigate(`/rule-listing/manage`, { state: { id: e.row._id } })} />
                ) : (
                    <DefaultDataGrid rows={arrayFilter} columns={eventsColumns} />
                )}

                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack>
        </>
    );
};
