import { Box, ButtonBase, Grid, LinearProgress, Stack, Typography } from "@mui/material"
import React, { useState, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as Yup from 'yup';
import { columns, row } from "./data/machineLearning";
import { DefaultDataGrid } from "../../../ui/DefaultDataGrid";
import { PlusIcon } from "../../../assets/icons/PlusIcon";
import { DefaultToggle } from '../../../ui/DefaultToggle/DefaultToggle.jsx';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import { Datepicker } from "../../../ui/Datepicker";
import { Filter } from '../../../ui/Filter/Filter.jsx';
import { FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";
import { Tabs } from "../../../ui/Tabs/index.js";
import { tabs as initialTabs } from './data/tabs.jsx';
import { GraphData } from "./GraphData";
import { Logs } from "./Logs";



export const MachineLearning = ({ modelSetupOpen, setModelSetupOpen }) => {
    const [tabs, setTabs] = useState(initialTabs);
    const [activeTab, setActiveTab] = useState(initialTabs[0].value);
    const [selectedDatasource, setSelectedDatasource] = useState(-1)
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());

    const formik = useFormik({
        initialValues: {
            startDate: '',
            endDate: '',
            selectedDataSource: null,
            selectedModel: null,
            isEnable: false,
        },
        validationSchema: Yup.object().shape({
            selectedDataSource: Yup.string().max(255).required('DataSource is required'),
            selectedModel: Yup.object().required('Model is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    startDate: values.startDate,
                    endDate: values.endDate,
                    selectedDataSource: values.selectedDataSource,
                    selectedModel: values.selectedModel,
                    isEnable: values.isEnable,
                }
                formik.setValues(dataValues)
                // setIsLoading(true)
                // var data = await apicalling(values);
                // setIsLoading(false)
                // if (data !== undefined && data.data.success) {
                //     setStatus({ success: true });
                //     setSubmitting(false);
                //     console.log("formik", formik.errors);

                //     enqueueSnackbar('Secondary datas ' + (selectedDataId == "" ? "added" : "updated"), {
                //         anchorOrigin: {
                //             horizontal: 'right',
                //             vertical: 'top'
                //         },
                //         variant: 'success'
                //     });
                //     onClose(true)
                // } else {
                //     setStatus({ success: false });
                //     setSubmitting(false);
                //     enqueueSnackbar(data.data.message, {
                //         anchorOrigin: {
                //             horizontal: 'right',
                //             vertical: 'top'
                //         },
                //         variant: 'error'
                //     });
                // }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        pieChart("chartdiv1")
        pieChart("chartdiv2")
    }, []);

    const pieChart = (chartID) => {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5percent.PieChart.new(root,
            {
                innerRadius: am5.percent(75),
                layout: root.verticalLayout
            }));

        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: 20
        }));

        if (chartID == 'chartdiv1') {

            series.get("colors").set("colors", [
                am5.color(0x24D5AA),
                am5.color(0xE6E7E8),
            ]);

            series.data.setAll([
                { sector: "", size: 95 },
                { sector: "", size: 5 },
            ]);

            label.set("text", "95%");

            // if (nodeData != null && nodeData.arrayGraph != null && sensorData != null && sensorData.arraySecondaryData != null) {
            //     var objGraph = nodeData.arrayGraph.find(obj => obj.graph == "1")
            //     if (objGraph) {
            //         var objPin = sensorData.arraySecondaryData.find(obj => obj.pin == objGraph.pin)
            //         if (objPin) {
            //             series.get("colors").set("colors", [
            //                 am5.color(objGraph.color),
            //                 am5.color(0xE6E7E8),
            //             ]);
            //             const maxValue = isNaN(parseFloat(objGraph.maxValue)) ? 0 : parseFloat(objGraph.maxValue);
            //             const selectedValue = isNaN(parseFloat(objPin.value)) ? 0 : parseFloat(objPin.value);
            //             const totalSize = (selectedValue / maxValue) * 100;
            //             console.log("totalSize", totalSize)
            //             const remainingValue = 100 - totalSize;
            //             console.log("remainingValue", remainingValue)
            //             series.data.setAll([
            //                 { sector: objGraph.graphName, size: totalSize },
            //                 { sector: "", size: remainingValue },
            //             ]);
            //             const integerValue = parseInt(totalSize)
            //             const percentageValue = objGraph.showPercentage == "1" ? `${integerValue}%` : integerValue
            //             label.set("text", percentageValue);
            //         }
            //     }
            // }

        } else {
            series.get("colors").set("colors", [
                am5.color(0x24D5AA),
                am5.color(0xE6E7E8),
            ]);

            series.data.setAll([
                { sector: "", size: 45 },
                { sector: "", size: 55 },
            ]);
            label.set("text", "45%");

            // if (nodeData != null && nodeData.arrayGraph != null && sensorData != null && sensorData.arraySecondaryData != null) {
            //     var objGraph3 = nodeData.arrayGraph.find(obj => obj.graph == "3")
            //     if (objGraph3) {
            //         var objPin3 = sensorData.arraySecondaryData.find(obj => obj.pin == objGraph3.pin)
            //         if (objPin3) {
            //             series.get("colors").set("colors", [
            //                 am5.color(objGraph3.color),
            //                 am5.color(0xE6E7E8),
            //             ]);
            //             const maxValue = isNaN(parseFloat(objGraph3.maxValue)) ? 0 : parseFloat(objGraph3.maxValue);
            //             const selectedValue = isNaN(parseFloat(objPin3.value)) ? 0 : parseFloat(objPin3.value);
            //             const totalSize = (selectedValue / maxValue) * 100;
            //             console.log("totalSize3", totalSize)
            //             const remainingValue = 100 - totalSize;
            //             console.log("remainingValue", remainingValue)
            //             series.data.setAll([
            //                 { sector: objGraph3.graphName, size: totalSize },
            //                 { sector: "", size: remainingValue },
            //             ]);
            //             const integerValue = parseInt(totalSize)
            //             const percentageValue = objGraph3.showPercentage == "1" ? `${integerValue}%` : integerValue
            //             label.set("text", percentageValue);
            //         }
            //     }
            // }
        }

        series.appear(1000, 100);
    }

    const handleStartDateChange = (date) => {
        setStartDate(date)
        // getSensorData(date, chartEndDate)
    };

    const handleEndDateChange = (date) => {
        setEndDate(date)
        // getSensorData(chartStartDate, date)
    };

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Stack
                    flex={1}
                    direction={'row'}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '8px 8px 8px 8px',
                        bgcolor: '#FFFFFF',
                        overflow: 'hidden',
                    }}
                    mb={1}
                    width={'100%'}
                    // width={'calc(100% + 24px)'}
                    component={'form'}
                    onSubmit={formik.handleSubmit}
                >
                    <Stack gap={2} borderRight={'1px solid #E2E2EA'} width={'100%'} flexShrink={0}
                        sx={{
                            overflowY: 'auto',
                            height: '100%',
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                width: '4px',
                                backgroundColor: '#bdbdbd',
                            },
                        }}>
                        <Stack ml={'21px'} mt={"16px"} pr={'58px'}>
                            <Typography fontFamily={'Roboto'} fontWeight={'Bold'} color={'#1F243B'} fontSize={15}>MACHINE LEARNING</Typography>
                            <Stack direction={'row'} alignItems={'center'} gap={3}>
                                <Stack gap={2} width={'70%'}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Stack gap={1}>
                                                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>10022</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>ML Session ID</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Stack gap={1}>
                                                <Stack direction={'row'} alignItems={'center'} gap={3}>
                                                    <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>Active</Typography>
                                                    <Box
                                                        sx={{
                                                            width: 8,
                                                            height: 8,
                                                            borderRadius: '50%',
                                                            // backgroundColor: sensorData != null && sensorData.state == "1" ? "green.main" : "red.main",
                                                            backgroundColor: "green.main",
                                                        }}
                                                    />                                                </Stack>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Status</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Stack gap={1}>
                                                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>07/01-15/2024</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Data Range</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack gap={1}>
                                                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>HBOS - Historical Based Outlier</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Model</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Stack gap={1}>
                                                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>In Progress</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Training Task</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Stack gap={1.5} mt={1}>
                                                <LinearProgress
                                                    sx={{
                                                        width: 100,
                                                        borderRadius: '3px',
                                                        height: 10,
                                                        bgcolor: '#EFEFEF',
                                                        '.MuiLinearProgress-bar': {
                                                            borderRadius: '3px',
                                                            bgcolor: '#24D5AA',
                                                        },
                                                    }}
                                                    variant={'determinate'}
                                                    value={65}
                                                />
                                                <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#A0A3A6'}>65%</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Stack gap={1}>
                                                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>Vibration Data</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Datasource</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Stack mt={-1}>
                                                <DefaultToggle name={'isEnable'} />
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'} pt={0.2}>Activation</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>

                                <Stack direction={'row'} gap={4}>
                                    <Stack alignItems={'center'} >
                                        <Box id={'chartdiv1'} sx={{ height: '120px', width: '155px' }} />
                                        <Typography mt={1} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={12}>Data 1</Typography>
                                    </Stack>

                                    <Stack alignItems={'center'}>
                                        <Box id={'chartdiv2'} sx={{ height: '120px', width: '155px' }} />
                                        <Typography mt={1} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={12}>Data 2</Typography>
                                    </Stack>

                                    <Stack gap={2}>
                                        <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography mt={1}
                                                color={'#A0A3A6'}
                                                fontFamily={'Roboto'}
                                                fontWeight={'Regular'}
                                                fontSize={12}
                                                sx={{ whiteSpace: 'nowrap' }}
                                            >Data 1</Typography>
                                            <Box sx={{ bgcolor: "#24D5AA", borderRadius: 10, }}>
                                                <Typography fontFamily={'Roboto'} fontWeight={'Regular'} color={'#FFFFFF'} fontSize={12} sx={{
                                                    px: "18px", py: "7px"
                                                }}>15</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography fontFamily={'Roboto'} fontWeight={'Regular'} color={'#A0A3A6'} fontSize={12}>Data 2</Typography>
                                            <Box sx={{ bgcolor: "#FFBC24", borderRadius: 10 }}>
                                                <Typography fontFamily={'Roboto'} fontWeight={'Regular'} color={'#FFFFFF'} fontSize={10} sx={{
                                                    px: "18px", py: "7px"
                                                }}>4</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography fontFamily={'Roboto'} fontWeight={'Regular'} color={'#A0A3A6'} fontSize={12}>Data 3</Typography>
                                            <Box sx={{ bgcolor: "#E6E7E8", borderRadius: 10 }}>
                                                <Typography fontFamily={'Roboto'} fontWeight={'Regular'} color={'#374147'} fontSize={10} sx={{
                                                    px: "18px", py: "7px"
                                                }}>4</Typography>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                        {modelSetupOpen ? (
                            <Stack sx={{ borderTop: '1px solid #8F98AB45', md: 'none', height: "100%" }}>
                                <Stack ml={'21px'} direction={'row'} justifyContent={'space-between'} mr={'34px'} alignItems={'center'} mt={2} mb={2}>
                                    <Typography fontFamily={'Roboto'} fontWeight={'Bold'} color={'#1F243B'} fontSize={15} >MODEL SETUP</Typography>
                                </Stack>
                                <Stack direction={'row'} mb={'10px'} sx={{ height: "100%" }}>
                                    <Stack ml={'21px'} width={450}>
                                        <Typography fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'} fontSize={12}>1. Select Training Data Range</Typography>
                                        <Typography fontFamily={'Roboto'} fontWeight={'Regular  '} color={'#AAB0BC'} fontSize={12} sx={{ mt: '4px', ml: '13px' }}>Select the date range of the data to be utilized for model training.</Typography>
                                        <Stack direction={'row'} gap={2} mt={'10px'} ml={'9px'}>
                                            <Datepicker
                                                sx={{
                                                    width: '150px'
                                                }}
                                                initialDate={startDate}
                                                handleDateChange={handleStartDateChange}
                                            ></Datepicker>

                                            <Datepicker
                                                sx={{
                                                    width: '150px'
                                                }}
                                                initialDate={endDate}
                                                handleDateChange={handleEndDateChange}
                                            />
                                        </Stack>
                                        <Typography fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'} fontSize={12} sx={{ mt: '25px' }}>2. Select Datasource</Typography>
                                        <Typography fontFamily={'Roboto'} fontWeight={'Regular  '} color={'#AAB0BC'} fontSize={12} sx={{ mt: '4px', ml: '13px' }}>Select the desired datasource for this model.</Typography>
                                        <Stack mt={'15px'} ml={'9px'} >
                                            <Filter
                                                sx={{
                                                    height: 40,
                                                    width: "320px",
                                                    alignItems: 'start'
                                                }}
                                                // onChange={(event) => {
                                                //     var value = event.target.value
                                                //     setSelectedChart(value)
                                                // }}
                                                defaultValue={selectedDatasource}
                                                placeholder="Select Datasource"
                                            >
                                                {/* {arrayChartType.map((item, index) => (
                                            <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                        ))} */}
                                            </Filter>
                                        </Stack>
                                        <Typography fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'} fontSize={12} sx={{ mt: '25px' }}>3. Select Model</Typography>
                                        <Typography fontFamily={'Roboto'} fontWeight={'Regular'} color={'#AAB0BC'} fontSize={12} sx={{ mt: '4px', ml: '13px' }}>Select the desired machine learning model to be applied to this dataset.</Typography>
                                        <Stack mt={'15px'} ml={'9px'} >
                                            <Filter
                                                sx={{
                                                    height: 40,
                                                    width: "320px",
                                                    alignItems: 'start'
                                                }}
                                                // onChange={(event) => {
                                                //     var value = event.target.value
                                                //     setSelectedChart(value)
                                                // }}
                                                defaultValue={selectedDatasource}
                                                placeholder="Select Model"
                                            >
                                                {/* {arrayChartType.map((item, index) => (
                                            <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                        ))} */}
                                            </Filter>
                                        </Stack>
                                        <Box flexGrow={1} />
                                        <Stack direction={'row'} gap={1.5} mb={2} mt={2} ml={'9px'}>
                                            <ButtonBase
                                                sx={{
                                                    py: 1,
                                                    px: 3,
                                                    border: '1px solid #E2E2EA',
                                                    bgcolor: '#F6F8FA',
                                                    borderRadius: '5px',
                                                    height: '30px'

                                                }}
                                            // onClick={(e) => {
                                            //     setDeleteConfirmationShouldOpen(true)
                                            // }}
                                            >

                                                <Typography fontSize={13} fontWeight={'Medium'} color={"#151D26"} fontFamily={'Roboto'}>
                                                    DELETE TASK
                                                </Typography>
                                            </ButtonBase>
                                            <ButtonBase
                                                sx={{
                                                    py: 1,
                                                    px: 3,
                                                    border: '1px solid #E2E2EA',
                                                    bgcolor: '#F6F8FA',
                                                    borderRadius: '5px',
                                                    height: '30px'

                                                }}
                                                onClick={() => { setModelSetupOpen(false) }}
                                            >
                                                <Typography fontSize={13} fontWeight={'Medium'} color={"#151D26"} fontFamily={'Roboto'} >
                                                    CANCEL
                                                </Typography>
                                            </ButtonBase>
                                            <ButtonBase
                                                sx={{
                                                    py: 1,
                                                    px: 3,
                                                    bgcolor: '#1E75FF',
                                                    borderRadius: '5px',
                                                    height: '30px'

                                                }}
                                            // disabled={formik.isSubmitting}
                                            // type="submit"
                                            // onClick={() => console.log('Save button clicked', formik.errors)} // Add this line

                                            >
                                                <Typography fontSize={13} fontWeight={'Medium'} color={"#FAFAFB"} fontFamily={'Roboto'}>
                                                    TRAIN DATA
                                                </Typography>
                                            </ButtonBase>
                                        </Stack>
                                    </Stack>
                                    <Stack flex={1}>
                                        <Stack width={'265px'}>
                                            <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} activeColor={'#151D26'} />
                                        </Stack>
                                        {activeTab === 'graphData' && <GraphData />}
                                        {activeTab === 'logs' && <Logs />}
                                    </Stack>
                                </Stack>
                            </Stack>
                        ) : (
                            <DefaultDataGrid rows={row} columns={columns} onRowClick={(e) => { setModelSetupOpen(true) }} />
                        )}

                    </Stack>
                </Stack >
            </FormikProvider>
        </>
    )
}