import { atom } from 'recoil';

export const sidebarOpened = atom({
    key: 'IsOpenedSidebar',
    default: localStorage.getItem("isSideOpen") == "1",
});

export const eventOpened = atom({
    key: 'IsOpenedEvent',
    default: true,
});
