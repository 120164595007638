import mapboxgl from 'mapbox-gl';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { sidebarOpened } from '../../core/store/index.js';
import greenPin from '../../assets/images/greenPin.png';
import bluePin from '../../assets/images/bluePin.png';
import orangePin from '../../assets/images/orangePin.png';
import { useNavigate } from 'react-router-dom';
import { Tabs } from '../../ui/Tabs/index.js';
import { tabs } from './data/tabs.js';
import moment from 'moment';
mapboxgl.accessToken = 'pk.eyJ1Ijoic2lnbmFsdHJhY2tzIiwiYSI6ImNsd3Rjd3A1bTAyYnQybnBuNXZnajJ2YjEifQ.6DdrfKM2ke64TpX-Igogdg';

export const MapView = ({ sx, data, isTimeLine, isPersonnel, isAsset, isNodes }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const handleChangeTab = (value) => {
        setActiveTab(value);
    };
    const navigate = useNavigate();
    const isOpenedSidebar = useRecoilValue(sidebarOpened);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-74.01521529520066);
    const [lat, setLat] = useState(40.73760113154407);
    const [zoom, setZoom] = useState(9);

    const getAssetStatusColor = (code) => {
        switch (code) {
            case 'Ready':
                return '#0061FF';
            case 'In Service':
                return '#19A872';
            case 'In Maintenance':
                return '#f47979';
            case 'Not In Service':
                return '#121212';
            case 'In Repair':
                return '#FFAA50';
            default:
                return '#19A872';
        }
    };

    function createCluster(props) {
        let html = `<div class="map-cluster">
        <span></span>
        <span></span>
        ${props.point_count}
            <div />`;

        const el = document.createElement('div');
        el.innerHTML = html;
        return el.firstChild;
    }
    const renderMap = () => {
        var arrayData = []
        if (data) {
            if (isAsset) {
                data.arrayAsset.map(objData => {
                    if (objData.tracker != null && objData.tracker.positionLongitude != null && objData.tracker.positionLatitude != null && objData.tracker.positionLongitude != "-" && objData.tracker.positionLatitude != "-") {
                        var obj = {
                            _id: objData._id,
                            name: objData.assetName,
                            serialNumber: objData.serialNumber,
                            speed: isNaN(parseFloat(objData.tracker.positionSpeed)) ? 0 : parseFloat(objData.tracker.positionSpeed),
                            category: objData.category,
                            type: objData.type,
                            temperature: objData.tracker.temperature,
                            altitude: objData.tracker.positionAltitude,
                            latitude: objData.tracker.positionLatitude,
                            longitude: objData.tracker.positionLongitude,
                            state: objData.tracker.state,
                            timestamp: objData.tracker.timestamp,
                            dataType: 1
                        }
                        arrayData.push(obj)
                    }
                })
            }

            if (isNodes) {
                data.arrayNode.map(objData => {
                    if (objData.sensor != null && objData.sensor.longitude != null && objData.sensor.latitude != null && objData.sensor.longitude != "" && objData.sensor.latitude != "") {
                        var obj = {
                            _id: objData._id,
                            name: objData.nodeName,
                            events: objData.events,
                            serialNumber: objData.sensorNumber,
                            speed: isNaN(parseFloat(objData.speed)) ? 0 : parseFloat(objData.speed),
                            category: objData.nodeCategory,
                            type: objData.nodeType,
                            temperature: objData.sensor.temperature,
                            altitude: objData.sensor?.altitude || "",
                            latitude: objData.sensor.latitude,
                            longitude: objData.sensor.longitude,
                            state: objData.sensor.state,
                            timestamp: objData.sensor.timestamp,
                            battery: objData.sensor?.battery || "",
                            dataType: 2
                        }
                        arrayData.push(obj)
                    }
                })
            }

            if (isPersonnel) {
                data.arrayPersonnel.map(objData => {
                    if (objData.sensor != null && objData.sensor.longitude != null && objData.sensor.latitude != null && objData.sensor.longitude != "" && objData.sensor.latitude != "") {
                        var obj = {
                            _id: objData._id,
                            name: objData.firstName + " " + objData.lastName,
                            events: objData.events,
                            serialNumber: objData.sensorNumber,
                            speed: isNaN(parseFloat(objData.speed)) ? 0 : parseFloat(objData.speed),
                            light: objData.sensor?.light || "",
                            temperature: objData.sensor.temperature,
                            altitude: objData.sensor?.altitude || "",
                            latitude: objData.sensor.latitude,
                            longitude: objData.sensor.longitude,
                            state: objData.sensor.state,
                            timestamp: objData.sensor.timestamp,
                            battery: objData.sensor?.battery || "",
                            dataType: 3,
                            path: objData.path
                        }
                        arrayData.push(obj)
                    }
                })
            }

        }

        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: `mapbox://styles/signaltracks/${activeTab}`,
                center: [lng, lat],
                zoom: zoom,
            });
            map.current.addControl(new mapboxgl.NavigationControl());
            let arrayCluster = []
            arrayData.map((objData) => {
                arrayCluster.push({ ...objData, pinType: objData.dataType == "3" ? 'orange-pin' : objData.speed > 0 ? 'green-pin' : 'blue-pin' })
            })
            if (map.current && arrayCluster.length > 0) {
                map.current.setCenter([arrayCluster[0].longitude, arrayCluster[0].latitude])
            }
            const waypoints = arrayCluster.map((cluster) => ({
                type: "Feature",
                properties: cluster,
                geometry: {
                    type: "Point",
                    coordinates: [cluster.longitude, cluster.latitude, 0.0],
                },
            }));

            var objCluster = {
                "type": "FeatureCollection",
                "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                "features": waypoints
            }

            map.current.on('load', () => {
                map.current.loadImage(greenPin, function (error, greenImage) {
                    if (error) throw error;
                    map.current.addImage('green-pin', greenImage);
                });

                map.current.loadImage(bluePin, function (error, blueImage) {
                    if (error) throw error;
                    map.current.addImage('blue-pin', blueImage);
                });

                map.current.loadImage(orangePin, function (error, orangeImage) {
                    if (error) throw error;
                    // Load custom images for each unique path in arrayCluster
                    arrayCluster.forEach(cluster => {
                        if (cluster.path) {
                            if (cluster.dataType === 3) {
                                const sensorData = cluster;
                                const el = document.createElement('div');

                                if (sensorData.path) {
                                    const avatarImg = document.createElement('img');
                                    avatarImg.src = sensorData.path; // Dynamic or default path
                                    avatarImg.alt = "User Avatar";
                                    avatarImg.style.width = '35px';
                                    avatarImg.style.height = '35px';
                                    avatarImg.style.borderRadius = '50%';
                                    el.appendChild(avatarImg);
                                } else {
                                    map.current.loadImage(cluster.path, function (error, customImage) {
                                        console.log("error, customImage", error)
                                        if (!error && customImage) {
                                            map.current.addImage('orange-pin', customImage);
                                        }
                                    });
                                }

                                // Create and add the marker to the map
                                const marker = new mapboxgl.Marker(el)
                                    .setLngLat([sensorData.longitude, sensorData.latitude])
                                    .addTo(map.current);

                                var personnelPopup = new mapboxgl.Popup({ anchor: 'bottom', closeOnClick: true })
                                    .setHTML(
                                        `<div class='marker-wrapper'>
                                     <div class='marker-header' style="margin-left: 16px;">
                                        <div class="marker-header-item">
                                            <div class="marker-header-item-title"">${sensorData?.name || "-"}</div>
                                        </div>
                                    </div>
                                    <div class='marker-header' style="margin-left: 16px;">
                                        <div class="marker-header-item">
                                            <div class="marker-item-box">
                                                <div class="marker-header-item-value">${sensorData != null && sensorData.state != "" ? sensorData.state == "1" ? "online" : "Offline" : "-"}</div>
                                                <div class='marker-item-value marker-item-circle' style="background-color: ${getAssetStatusColor(sensorData.state)};"></div>
                                            </div>
                                            <div class="marker-header-item-name">Status</div>
                                        </div>
                                         <div class="marker-header-item">
                                            <div class="marker-header-item-value">${sensorData != null && sensorData.timestamp != "" ? moment(sensorData.timestamp).format("MM/DD/YY hh:mmA") : "-"}</div>
                                            <div class="marker-header-item-name" >Updated</div>
                                        </div>
                                    </div>
                                    <div class='marker-header' style="margin-left: 16px;">
                                        <div class="marker-header-item">
                                            <div class="marker-header-item-value">${sensorData.light != null && sensorData.light != "" ? `${sensorData.light}%` : "-"}</div>
                                            <div class="marker-header-item-name">Light</div>
                                        </div>
                                        <div class="marker-header-item">
                                            <div class="marker-header-item-value">${sensorData?.events || "-"}</div>
                                            <div class="marker-header-item-name">Events</div>
                                        </div>
                                    </div>
                                    <div class='marker-header' style="margin-left: 16px;">
                                        <div class="marker-header-item">
                                            <div class="marker-header-item-value">${sensorData != null && sensorData.battery != "" ? `${sensorData.battery}%` : "-"}</div>
                                            <div class="marker-header-item-name">Batt.</div>
                                        </div>
                                        <div class="marker-header-item">
                                            <div class="marker-header-item-value">${sensorData != null && sensorData.temperature != "" ? `${sensorData.temperature} °F` : "-"}</div >
                                            <div class="marker-header-item-name">Temp</div>
                                        </div >
                                    </div >
                                    <div class='marker-header' style="margin-left: 16px;">
                                        <div class='marker-item'>
                                            <div class="marker-item-box">
                                                <div class='marker-header-item-value'>${sensorData?.serialNumber || "-"}</div>
                                            </div>
                                            <div class='marker-header-item-name'>ID</div>
                                        </div>
                                        <button type="button" class="marker-footer-btn"></button>
                                    </div>
                                </div >`
                                    )
                                // Bind popup to the marker
                                marker.setPopup(personnelPopup);

                                marker.getElement().addEventListener('click', () => {
                                    if (personnelPopup.isOpen()) {
                                        personnelPopup.remove();
                                    } else {
                                        personnelPopup.addTo(map.current);
                                    }
                                });


                                personnelPopup.on('open', () => {
                                    const button = personnelPopup.getElement().querySelector('.marker-footer-btn');
                                    if (button) {
                                        button.addEventListener('click', function (e) {
                                            e.stopPropagation();
                                            navigate(`/personnel-listing/detail`, { state: { id: sensorData._id, isFromMap: true } });
                                        });
                                    }
                                });

                                // personnelPopup.getElement().querySelector('.marker-footer-btn').addEventListener('click', function (e) {
                                //     e.stopPropagation();
                                //     navigate(`/personnel-listing/detail`, { state: { id: sensorData._id, isFromMap: true } })
                                // });


                            }
                        }
                    });
                });

                map.current.addSource('tracks', {
                    type: 'geojson',
                    data: objCluster,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                });

                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'tracks',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': 'transparent',
                        'circle-radius': 40,
                    },
                });

                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'tracks',
                    filter: ['has', 'point_count'],
                });

                map.current.addLayer({
                    id: 'unclustered-point',
                    type: 'symbol',
                    source: 'tracks',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        "icon-image": ['get', 'pinType'],
                        "icon-size": 0.4,
                    },
                });

                map.current.on('click', 'clusters', (e) => {
                    const features = map.current.queryRenderedFeatures(e.point, {
                        layers: ['clusters'],
                    });
                    const clusterId = features[0].properties.cluster_id;
                    map.current.getSource('tracks').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) return;

                        map.current.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom: zoom,
                        });
                    });
                });

                map.current.on('click', 'unclustered-point', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const tracker = e.features[0].properties;
                    if (tracker.dataType == 1) {
                        var popup = new mapboxgl.Popup({ anchor: 'bottom', closeOnClick: true })
                            .setLngLat(coordinates)
                            .setHTML(
                                `<div class='marker-wrapper'>
                                 <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-title">${tracker?.name || "-"}</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value" style="font-size: 10px; ">${tracker.serialNumber || "-"}</div>
                                        <div class="marker-header-item-name" style="font-size: 10px; color: #AAB0BC">ID</div>
                                    </div>
                                     <div class="marker-header-item">
                                        <div class="marker-header-item-value" style="font-size: 10px; ">${tracker != null ? `${tracker.speed} MPH` : "-"}</div>
                                        <div class="marker-header-item-name" style="font-size: 10px; color: #AAB0BC">Speed</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value" style="font-size: 10px; ">${tracker?.category || "-"}</div>
                                        <div class="marker-header-item-name" style="font-size: 10px; color: #AAB0BC">Category</div>
                                    </div>
                                     <div class="marker-header-item">
                                        <div class="marker-header-item-value" style="font-size: 10px; ">${tracker?.type || "-"}</div>
                                        <div class="marker-header-item-name" style="font-size: 10px; color: #AAB0BC">Type</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value" style="font-size: 10px; ">${tracker != null && tracker.temperature != "" ? `${tracker.temperature} °F` : "-"}</div >
                                        <div class="marker-header-item-name" style="font-size: 10px; color: #AAB0BC">Temp</div>
                                    </div >
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value" style="font-size: 10px; ">${tracker != null && tracker.altitude != "" ? `${tracker.altitude}(m)` : "-"}</div>
                                        <div class="marker-header-item-name" style="font-size: 10px; color: #AAB0BC">Alt.</div>
                                    </div>
                                </div >
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class='marker-item'>
                                        <div class="marker-item-box">
                                            <div class='marker-header-item-value' style="font-size: 10x;">${tracker?.latitude || "-"} ,${tracker?.longitude || "-"}</div>
                                        </div>
                                        <div class='marker-header-item-name' style="font-size: 10px; color: #AAB0BC">Lat/Long</div>
                                    </div>
                                    <button type="button" class="marker-footer-btn"></button>
                                </div>
                            </div >`
                            )
                            .addTo(map.current);

                        popup.getElement().querySelector('.marker-footer-btn').addEventListener('click', function (e) {
                            e.stopPropagation();
                            if (tracker.dataType == 1) {
                                navigate(`/asset-listing/detail`, { state: { id: tracker._id, isFromMap: true } })
                            }

                        });
                    } else if (tracker.dataType == 2) {
                        var nodePopup = new mapboxgl.Popup({ anchor: 'bottom', closeOnClick: true })
                            .setLngLat(coordinates)
                            .setHTML(
                                `<div class='marker-wrapper'>
                                 <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-title"">${tracker?.name || "-"}</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-item-box">
                                            <div class="marker-header-item-value">${tracker != null && tracker.state != "" ? tracker.state == "1" ? "online" : "Offline" : "-"}</div>
                                            <div class='marker-item-value marker-item-circle' style="background-color: ${getAssetStatusColor(tracker.state)};"></div>
                                        </div>
                                        <div class="marker-header-item-name">Status</div>
                                    </div>
                                     <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker != null && tracker.timestamp != "" ? moment(tracker.timestamp).format("MM/DD/YY hh:mmA") : "-"}</div>
                                        <div class="marker-header-item-name" >Updated</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker.type || "-"}</div>
                                        <div class="marker-header-item-name">Type</div>
                                    </div>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker?.events || "-"}</div>
                                        <div class="marker-header-item-name">Events</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker != null && tracker.battery != "" ? `${tracker.battery}%` : "-"}</div>
                                        <div class="marker-header-item-name">Batt.</div>
                                    </div>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker != null && tracker.temperature != "" ? `${tracker.temperature} °F` : "-"}</div >
                                        <div class="marker-header-item-name">Temp</div>
                                    </div >
                                </div >
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class='marker-item'>
                                        <div class="marker-item-box">
                                            <div class='marker-header-item-value'>${tracker?.serialNumber || "-"}</div>
                                        </div>
                                        <div class='marker-header-item-name'>ID</div>
                                    </div>
                                    <button type="button" class="marker-footer-btn"></button>
                                </div>
                            </div >`
                            )
                            .addTo(map.current);

                        nodePopup.getElement().querySelector('.marker-footer-btn').addEventListener('click', function (e) {
                            e.stopPropagation();
                            navigate(`/node-listing/detail`, { state: { id: tracker._id, isFromMap: true } })
                        });
                    } else if (tracker.dataType == 3) {
                        var personnelPopup = new mapboxgl.Popup({ anchor: 'bottom', closeOnClick: true })
                            .setLngLat(coordinates)
                            .setHTML(
                                `<div class='marker-wrapper'>
                                 <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-title"">${tracker?.name || "-"}</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-item-box">
                                            <div class="marker-header-item-value">${tracker != null && tracker.state != "" ? tracker.state == "1" ? "online" : "Offline" : "-"}</div>
                                            <div class='marker-item-value marker-item-circle' style="background-color: ${getAssetStatusColor(tracker.state)};"></div>
                                        </div>
                                        <div class="marker-header-item-name">Status</div>
                                    </div>
                                     <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker != null && tracker.timestamp != "" ? moment(tracker.timestamp).format("MM/DD/YY hh:mmA") : "-"}</div>
                                        <div class="marker-header-item-name" >Updated</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                       <div class="marker-header-item-value">${tracker != null && tracker.light != "" ? `${tracker.light}%` : "-"}</div>
                                            <div class="marker-header-item-name">Light</div>
                                    </div>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker?.events || "-"}</div>
                                        <div class="marker-header-item-name">Events</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker != null && tracker.battery != "" ? `${tracker.battery}%` : "-"}</div>
                                        <div class="marker-header-item-name">Batt.</div>
                                    </div>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${tracker != null && tracker.temperature != "" ? `${tracker.temperature} °F` : "-"}</div >
                                        <div class="marker-header-item-name">Temp</div>
                                    </div >
                                </div >
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class='marker-item'>
                                        <div class="marker-item-box">
                                            <div class='marker-header-item-value'>${tracker?.serialNumber || "-"}</div>
                                        </div>
                                        <div class='marker-header-item-name'>ID</div>
                                    </div>
                                    <button type="button" class="marker-footer-btn"></button>
                                </div>
                            </div >`
                            )
                            .addTo(map.current);

                        personnelPopup.getElement().querySelector('.marker-footer-btn').addEventListener('click', function (e) {
                            e.stopPropagation();
                            navigate(`/personnel-listing/detail`, { state: { id: tracker._id, isFromMap: true } })
                        });
                    }

                });

                const markers = {};
                let markersOnScreen = {};

                function updateMarkers() {
                    const newMarkers = {};
                    const features = map.current.querySourceFeatures('tracks');

                    for (const feature of features) {
                        const coords = feature.geometry.coordinates;
                        const props = feature.properties;
                        if (!props.cluster) continue;
                        const id = props.cluster_id;

                        let marker = markers[id];
                        if (!marker) {
                            const el = createCluster(props);
                            marker = markers[id] = new mapboxgl.Marker({
                                element: el,
                            }).setLngLat(coords);
                        }
                        newMarkers[id] = marker;

                        if (!markersOnScreen[id]) marker.addTo(map.current);
                    }

                    for (const id in markersOnScreen) {
                        if (!newMarkers[id]) markersOnScreen[id].remove();
                    }
                    markersOnScreen = newMarkers;
                }

                map.current.on('render', () => {
                    if (!map.current.isSourceLoaded('tracks')) return;
                    updateMarkers();
                });

                map.current.on('move', () => {
                    setLng(map.current.getCenter().lng.toFixed(4));
                    setLat(map.current.getCenter().lat.toFixed(4));
                    setZoom(map.current.getZoom().toFixed(2));
                });

                map.current.on('mouseenter', 'clusters', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'clusters', () => {
                    map.current.getCanvas().style.cursor = '';
                });
            });
        }
    };

    const handleStorageChange = useDebouncedCallback(() => {
        if (map.current) {
            map.current.remove();
            map.current = null
        }
        renderMap();
    }, 500);

    useEffect(() => {
        handleStorageChange()
    }, [data, isOpenedSidebar, activeTab, isPersonnel, isAsset, isNodes]);

    useEffect(() => {
        if (map.current) {
            map.current.resize(); // Resize the map after layout changes
        }
    }, [isOpenedSidebar, isTimeLine]);
    return (
        <Box position={'relative'} width={'100%'} height={'100%'}>
            <Stack direction={'row'} justifyContent={'space-between'} position="relative">
                <Box position={'absolute'} left={12} top={12} zIndex={3}>
                    <Tabs data={tabs} setActiveTab={handleChangeTab} activeTab={activeTab} inline />
                </Box>
            </Stack>

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    ...sx,
                }}
                ref={mapContainer}
            />
        </Box >
    );
};
