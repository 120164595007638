import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ArrowUpIcon from '../../../assets/icons/ArrowUpIcon.svg'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon.svg'
import SettingsIcon from '../../../assets/icons/settings.svg'
import { arrayMeasurment } from '../../RuleNew/data/arrayData.jsx'

export const GraphBox = ({ nodeData, sensorData, onGraphSettingClick, componentOrder, index, onUp, onDown }) => {

    useEffect(() => {
        pieChart("chartdiv", "1")
        pieChart("chartdiv2", "2")
        pieChart("chartdiv3", "3")
        pieChart("chartdiv4", "4")
        pieChart("chartdiv5", "5")
    }, [nodeData, sensorData]);

    const pieChart = (chartID, graph) => {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5percent.PieChart.new(root,
            {
                innerRadius: 50,
                layout: root.verticalLayout
            }));

        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));
        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: 20
        }));

        series.get("colors").set("colors", [
            am5.color(0x1E75FF),
            am5.color(0xE6E7E8),
        ]);

        series.data.setAll([
            { sector: "", size: 0 },
            { sector: "", size: 100 },
        ]);

        label.set("text", "0%");
        if (nodeData != null && nodeData.arrayGraph != null && sensorData != null && sensorData.arraySecondaryData != null) {
            var objGraph = nodeData.arrayGraph.find(obj => obj.graph == graph)
            if (objGraph) {
                var objPin = null
                var selectedValue = 0
                if (objGraph.isExternal != null && objGraph.isExternal == 1) {
                    objPin = sensorData.arraySecondaryData.find(obj => obj.pin == objGraph.pin)
                    selectedValue = isNaN(parseFloat(objPin.value)) ? 0 : parseFloat(objPin.value);
                } else {
                    objPin = arrayMeasurment.find(obj => obj.id == objGraph.pin)
                    selectedValue = isNaN(parseFloat(sensorData[objPin.field])) ? 0 : parseFloat(sensorData[objPin.field]);
                }
                console.log("objPin---", objPin)
                console.log("selectedValue---", selectedValue)
                if (objPin) {
                    series.get("colors").set("colors", [
                        am5.color(objGraph.color),
                        am5.color(0xE6E7E8),
                    ]);
                    const maxValue = isNaN(parseFloat(objGraph.maxValue)) ? 0 : parseFloat(objGraph.maxValue);
                    const totalSize = (selectedValue / maxValue) * 100;
                    const remainingValue = 100 - totalSize;
                    series.data.setAll([
                        { sector: objGraph.graphName, size: totalSize },
                        { sector: "", size: remainingValue },
                    ]);
                    const integerValue = parseInt(totalSize)
                    let percentageValue;
                    if (integerValue === 0) {
                        percentageValue = objGraph.showPercentage == "1" ? "0%" : "0";  // Ensure correct display for 0
                    } else {
                        percentageValue = objGraph.showPercentage == "1" ? `${integerValue}%` : integerValue;
                    }
                    label.set("text", percentageValue);
                }
            }
        }
        series.appear(1000, 100);
    }

    return (
        <Box sx={{ width: "100%", border: '1px solid #E2E2EA', borderRadius: '3.5px', marginBottom: "10px" }}>
            <Stack direction="row" sx={{ display: "flex", height: "40px", borderBottom: '1px solid #E2E2EA', alignItems: 'center' }} px={'15px'}>
                <Typography fontWeight={500} fontSize={12} >{nodeData?.graphGroupName || "Data Group 1"}</Typography>
                <Box flexGrow={1}></Box>
                <Stack direction={'row'} alignItems={'center'} gap="18px">
                    {/* <Box
                        sx={{
                            height: '18px',
                            width: '18px',
                            cursor: 'pointer',
                        }}
                        component={'img'}
                        onClick={(e) => {
                            onGraphSettingClick(true)
                        }}
                        src={SettingsIcon}
                    /> */}
                    <Box
                        sx={{
                            height: '13px',
                            width: '10px',
                            opacity: index === 0 ? 0.5 : 1,
                            cursor: index === 0 ? 'not-allowed' : 'pointer',
                        }}
                        component={'img'}
                        onClick={index > 0 ? () => onUp() : null}
                        src={ArrowUpIcon}
                    />
                    <Box
                        sx={{
                            height: '13px',
                            width: '10px',
                            opacity: (index === (componentOrder.length - 1)) ? 0.5 : 1,
                            cursor: (index === (componentOrder.length - 1)) ? 'not-allowed' : 'pointer',
                        }}
                        component={'img'}
                        onClick={index != (componentOrder.length - 1) ? () => onDown() : null}
                        src={ArrowDownIcon}></Box>
                </Stack>
            </Stack>
            <Stack direction="row" sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }} px="24px" py="34px">
                <Stack width={'100px'} sx={{ position: 'relative' }}  >
                    <Box
                        sx={{
                            height: '105px',
                        }}
                        id={'chartdiv'}
                    />
                    <Typography mt={1} width={'100%'} textAlign={'center'} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={12}>
                        {nodeData != null && nodeData.arrayGraph ? (nodeData.arrayGraph.find(obj => obj.graph == "1")?.graphName || "Data 1") : "Data 1"}
                    </Typography>
                </Stack>
                <Stack width={'100px'} sx={{ position: 'relative' }} >
                    <Box
                        sx={{
                            height: '105px',
                        }}
                        id={'chartdiv2'}
                    />
                    <Typography mt={1} width={'100%'} textAlign={'center'} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={12}>
                        {nodeData != null && nodeData.arrayGraph ? (nodeData.arrayGraph.find(obj => obj.graph == "2")?.graphName || "Data 2") : "Data 2"}
                    </Typography>
                </Stack>
                <Stack width={'100px'} sx={{ position: 'relative' }} >
                    <Box
                        sx={{
                            height: '105px',
                        }}
                        id={'chartdiv3'}
                    />
                    <Typography mt={1} width={'100%'} textAlign={'center'} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={12}>
                        {nodeData != null && nodeData.arrayGraph ? (nodeData.arrayGraph.find(obj => obj.graph == "3")?.graphName || "Data 3") : "Data 3"}
                    </Typography>

                </Stack>
                <Stack width={'100px'} sx={{ position: 'relative' }} >
                    <Box
                        sx={{
                            height: '105px',
                        }}
                        id={'chartdiv4'}
                    />
                    <Typography mt={1} width={'100%'} textAlign={'center'} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={12}>
                        {nodeData != null && nodeData.arrayGraph ? (nodeData.arrayGraph.find(obj => obj.graph == "4")?.graphName || "Data 4") : "Data 4"}
                    </Typography>

                </Stack>
                <Stack width={'100px'} sx={{ position: 'relative' }} >
                    <Box
                        sx={{
                            height: '105px',
                        }}
                        id={'chartdiv5'}
                    />
                    <Typography mt={1} width={'100%'} textAlign={'center'} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={12}>
                        {nodeData != null && nodeData.arrayGraph ? (nodeData.arrayGraph.find(obj => obj.graph == "5")?.graphName || "Data 5") : "Data 5"}
                    </Typography>

                </Stack>
            </Stack>
        </Box>
    );
};
