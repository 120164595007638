
import { useLocation, useRoutes } from 'react-router-dom';
import { CompanyProvider } from './contexts/CompanyContext';
import { SnackbarProvider } from 'notistack';
import routes from './routes';
import useAuth from './hooks/useAuth';
import SplashScreen from './components/SplashScreen';
import { Sidebar } from './components/Sidebar';
import { HeaderMenu } from './components/HeaderMenu';

export const App = () => {
    const location = useLocation();
    const content = useRoutes(routes);
    const auth = useAuth();
    const shouldHideHeaderMenu =
        location.pathname.includes('authentication') ||
        location.pathname.includes('print') ||
        location.pathname.includes('profile') ||
        location.pathname.includes('asset-mapview') ||
        location.pathname === '/'; // Hide on root page

    return (
        <div className={'app'}>
            {!location.pathname.includes('authentication') && !location.pathname.includes('print') && <Sidebar />}
            <div className={'app-inner'}>
                <CompanyProvider>
                    {!shouldHideHeaderMenu && <HeaderMenu />}
                    <main className={'main'}>
                        <SnackbarProvider
                            dense
                            maxSnack={3}
                        >
                            {auth.isInitialized ? content : <SplashScreen />}
                        </SnackbarProvider>
                    </main>
                </CompanyProvider>
            </div>
        </div>
    );
};
