import { Box, Grid, Stack, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

export const Notification = (props) => {
    const queryParams = queryString.parse(location.search);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("queryParams", queryParams.id)
        if (queryParams.id) {
            if (queryParams.type == "Assets") {
                navigate(`/asset-listing/detail`, { state: { id: queryParams.id, isFromNotification: true } })
            } else if (queryParams.type == "Nodes") {
                navigate('/node-listing/detail', { state: { id: queryParams.id, isFromNotification: true } })
            } else if (queryParams.type == "Personnels") {
                navigate(`/personnel-listing/detail`, { state: { id: queryParams.id, isFromNotification: true } })
            } else {
                navigate(`/`)
            }
        } else {
            navigate(`/`)
        }
    }, []);

    return (
        <Stack
            alignItems={'flex-start'}
            justifyContent={'center'}
            bgcolor={'#000000'}
            py={10}
            px={20}
            width={'100%'}
            position={'relative'}
            overflow={'hidden'}
        >

        </Stack>
    )
}