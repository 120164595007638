
import React, { useRef, useState, useEffect } from 'react';
import { Typography, Box, Stack, Drawer, Backdrop, CircularProgress, ButtonBase } from '@mui/material';
import { BurgerIcon } from '../assets/icons/BurgerIcon.jsx';
import notificationIcon from '../assets/images/notificationIcon.svg'
import blackEventIcon from '../assets/images/blackEventIcon.svg'
import { CheckBox } from '@mui/icons-material';
import CloseIcon from '../assets/icons/CloseIcon.svg';
import api from '../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { getEventType } from '../core/functions/statusHelpers.js';

export const HeaderMenu = () => {
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [arrayData, setArrayData] = useState([]);

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        setIsLoading(true)
        api.post('/apis/RuleEvents/getTodayEvent').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                setArrayData(resData)

            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const readData = (arrayEventID) => {
        setIsLoading(true)
        const data = {
            arrayEventID: arrayEventID
        }
        api.post('/apis/RuleEvents/read', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                enqueueSnackbar("Clear Successfully", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom'
                    },
                    variant: 'success'
                });
                getData()
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <>
            <Stack width={'100%'} alignItems={'flex-end'} px="27px" pt="14px">
                <Stack
                    component={'aside'}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    bgcolor={'#F6F8FA'}
                    width={'200px'}
                    height={'30px'}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '5px'
                    }}
                    onClick={() => {
                        setIsNotificationOpen(!isNotificationOpen)
                    }}
                    zIndex={isNotificationOpen ? 1300 : 1}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Box
                            width={'35px'}
                            height={'35px'}
                            ml={-2.1}
                            alignContent={'center'}
                            justifyItems={'center'}
                        >
                            <Box component={'img'} src={arrayData != null && arrayData.length > 0 ? notificationIcon : blackEventIcon} />
                        </Box>
                        <Box
                            width={24}
                            height={16}
                            ml={'8px'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{
                                cursor: 'pointer',
                                svg: {
                                    width: 16,
                                    height: 10,
                                    fill: '#92929D',
                                },
                            }}
                        >
                            <BurgerIcon />
                        </Box>
                        <Typography fontSize={15} fontWeight={400} >
                            System Events
                        </Typography>
                    </Stack>
                    <Stack mr={'16px'}>
                        <Typography fontSize={15} fontWeight={700}>
                            {arrayData ? arrayData.length : "0"}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Drawer
                anchor={'right'}
                PaperProps={{
                    sx: { width: "355px" },
                }}
                open={isNotificationOpen}
                onClose={() => {
                    setIsNotificationOpen(false)
                }}
                zIndex={999}
            >
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                        px: '8px',
                        zIndex: 1,
                        mt: "70px",
                        mb: "10px",
                        gap: '10px',
                        overflowY: 'auto', // This will ensure overflow management if content grows
                    }}
                >
                    {arrayData.map((data, index) => (
                        <Box
                            key={data._id}
                            sx={{
                                minHeight: '80px', // Use minHeight to ensure the box doesn’t shrink smaller than 80px
                                width: '100%',
                                border: '1px solid #E6E7E8',
                                borderRadius: '5px',
                                justifyContent: 'space-between', // Proper space distribution
                            }}
                        >
                            <Stack direction={'row'} width="100%" sx={{ height: '100%' }} >
                                <Box
                                    sx={{
                                        width: '35px',
                                        height: '35px',
                                        mt: '9px',
                                        ml: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',

                                    }}
                                >
                                    <Box component={'img'} src={index % 2 === 0 ? notificationIcon : notificationIcon} />
                                </Box>
                                <Stack ml={'20px'} flex={1} justifyContent="center" mt={'8px'} mb={'8px'}>
                                    <Stack
                                        direction={'row'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                    >
                                        <Typography
                                            fontSize={12}
                                            fontWeight={500}
                                            fontFamily={'Roboto'}
                                        >
                                            {data.name || "-"}
                                        </Typography>
                                        <Box
                                            sx={{
                                                width: '16px',
                                                height: '16px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mr: '8px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                const singleIdArray = [data._id];
                                                // console.log('Single ID in Array:', singleIdArray);
                                                // setIsNotificationOpen(false);
                                                readData(singleIdArray);
                                            }}
                                        >
                                            <Box component={'img'} src={CloseIcon} sx={{ width: '20px', height: "20px" }} />
                                        </Box>
                                    </Stack>
                                    {data.eventType != "30" && data.eventType != "31" && <Box flexGrow={1} />}
                                    <Typography fontSize={12} fontWeight={400} mt={data.eventType != "30" && data.eventType != "31" ? "" : "12px"}>
                                        {data.eventType == "-1" ? `${data.eventText} ${data.value}` : data.eventType == "30" ? "Pressed SOS Button" : data.eventType == "31" ? "Pressed Single Press Button" : `${getEventType(parseInt(data.eventType))} : ${data.value} is
                                        ${data.eventText || "-"} Threshold value: ${data.thresholdValue}`}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    ))}
                    {arrayData.length > 0 && < Stack direction={'row'} justifyContent={'space-between'}>
                        <Box />
                        <ButtonBase
                            sx={{
                                width: '100px',
                                pl: "15px",
                                pr: "8px",
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '5px',
                                height: '30px',
                            }}
                            onClick={() => {
                                const allIds = arrayData.map((data) => data._id);
                                readData(allIds)
                                console.log('All IDs:', allIds);
                                // setIsNotificationOpen(false)
                            }}
                        >
                            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                                <Typography fontSize={12} fontWeight={500} fontFamily={'Roboto'} alignItems={'center'}>
                                    Clear All
                                </Typography>
                                <Box
                                    width={'16px'}
                                    height={'16px'}
                                    mr={'8px'}
                                >
                                    <Box component={'img'} src={CloseIcon} sx={{ width: '16px', height: "16px" }} />
                                </Box>
                            </Stack>
                        </ButtonBase>
                    </Stack>}
                </Stack >
            </Drawer >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
