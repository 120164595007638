import { Box, Stack, Typography, Backdrop, CircularProgress, MenuItem, ButtonBase, Grid, LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { DefaultCircularProgress } from '../../ui/DefaultCircularProgress/index.js';
import { ArrowIcon } from '../../assets/icons/ArrowIcon.jsx';
import { SearchInput } from '../../components/SearchInput/index.js';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { arrayAllAssetNode, columns } from './data/events.jsx';
import { FormikProvider, useFormik } from 'formik';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import excelFile from '../../assets/images/excelFile.svg';
import api from '../../lib/axios.js';
import dayjs from 'dayjs';
import { Tabs } from '../../ui/Tabs/index.js';
import { Datepicker } from '../../ui/Datepicker/Datepicker.jsx';
import { internalEventTabs } from '../Asset/data/internalSensorsTabs.js';
import { useNavigate } from 'react-router-dom';
import { Filter } from '../../ui/Filter/Filter.jsx';
import { arrayMeasurment } from '../RuleNew/data/arrayData.jsx';
import moment from 'moment';
import Papa from 'papaparse';
import { enqueueSnackbar } from 'notistack';

export const Events = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [arrayNotification, setArrayNotification] = useState([])
    const [arrayAssetNode, setArrayAssetNode] = useState([])
    const [arrayDevices, setArrayDevices] = useState([])
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [activeTab, setActiveTab] = useState(internalEventTabs[0].value);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs().add(1, 'day'));
    const [selectedMesurement, setSelectedMesurement] = useState(-2)
    const [selectedDevices, setSelectedDevices] = useState(-1)
    const [selectedNodeAsset, setSelectedNodeAsset] = useState(-1)

    const formik = useFormik({
        initialValues: {
            search: '',
            groups: '',
            status: '',
            devices: '',
        },
        onSubmit: (values) => {
            console.log(values);
        },
        validateOnChange: false,
    });

    useEffect(() => {
        getNotifications()
    }, [startDate, endDate]);

    useEffect(() => {
        getTrackerType()
    }, []);

    const getNotifications = async () => {
        setIsLoading(true);
        let response = await api.post('/apis/RuleEvents/getAll', { startDate: startDate.format('MM-DD-YYYY'), endDate: endDate.format('MM-DD-YYYY') })
        setIsLoading(false);
        var filterArray = applyFilters(response.data.data, query, selectedNodeAsset, selectedDevices, selectedMesurement)
        setFilteredArrayData(filterArray)
        setArrayNotification(response.data.data)
    }

    function getTrackerType() {
        setIsLoading(true)
        api.get('/apis/TrackerTypes/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var arrayDevice = [
                    { id: "1", value: "HAWK" },
                    { id: "2", value: "FlexSense" }
                ]
                resData.map(obj => {
                    arrayDevice.push({ id: obj._id, value: obj.name })
                })
                console.log("arrayDevice", arrayDevice)
                setArrayDevices(arrayDevice)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleStartDateChange = (date) => {
        const isStartDateAfterEndDate = date.isAfter(endDate);
        const isStartDateEqualToEndDate = date.isSame(endDate, 'day');
        if (isStartDateAfterEndDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setStartDate(date)
        }
    };

    const handleEndDateChange = (date) => {
        const isEndDateAfterStartDate = date.isAfter(startDate);
        const isStartDateEqualToEndDate = date.isSame(startDate, 'day');
        if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("End date must be after start date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setEndDate(date)
        }

    };

    const applyFilters = (arrayDataAll, query, selectedNodeAsset, selectedDevices, selectedMesurement) => {
        if (query === "" && selectedNodeAsset === -1 && selectedDevices === 1 && selectedMesurement === -2) {
            return arrayDataAll
        }
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['name', 'eventID', 'value', 'eventText'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []
        if (selectedNodeAsset !== null && selectedNodeAsset != -1) {
            var filteredArrayNodeAsset = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    // if (dataObj.assetID === selectedNodeAsset || dataObj.nodeID === selectedNodeAsset) {
                    //     containsQuery = true;
                    // }
                    if (selectedNodeAsset == 1) {
                        if (dataObj.assetID != null) {
                            containsQuery = true;
                        }
                    } else {
                        if (dataObj.nodeID != null) {
                            containsQuery = true;
                        }
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayNodeAsset]
        }

        if (selectedDevices !== null && selectedDevices != -1) {
            var filteredArrayTracker = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.type == selectedDevices) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayTracker]
        }

        if (selectedMesurement !== null && selectedMesurement != -2) {
            var filteredArrayMesurement = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.eventType === selectedMesurement) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayMesurement]
        }


        return finalArray
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayNotification, event.target.value, activeTab)
        setFilteredArrayData(filterArray)
    };

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
        var filterArray = applyFilters(arrayNotification, query, tab)
        setFilteredArrayData(filterArray)
    };

    const totalTemp = arrayNotification.filter(obj => obj.eventType == "1").length
    const totalAltitude = arrayNotification.filter(obj => obj.eventType == "2").length
    const totalSpeed = arrayNotification.filter(obj => obj.eventType == "3").length
    const totalGeoFence = arrayNotification.filter(obj => obj.eventType == "-1").length

    return (
        <FormikProvider value={formik}>
            <Stack width={'100%'} mb={1}>
                <Stack width={'100%'} alignItems={'flex-end'} mt={'14px'} pr={'27px'}>
                </Stack>
                <Stack p={3}>
                    <Stack direction={'row'} gap={10} alignItems={'center'}>
                        <Stack>
                            <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'}>
                                EVENTS
                            </Typography>
                            <Stack direction={'row'} alignItems={'center'} gap={5}>
                                <Stack alignItems={'center'} mr={3}>
                                    <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                        Total
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: status.color,
                                            mt: '16px'
                                        }}
                                    />
                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                        {arrayNotification.length}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                        <Stack gap={3} flex={1}>
                            {/* <Stack direction={'row'} gap={5}>
                                <DefaultCircularProgress value={((totalAltitude / arrayNotification.length) * 100 || 0)} text={totalAltitude} title={'Altitude'} />
                                <DefaultCircularProgress value={((totalSpeed / arrayNotification.length) * 100) || 0} text={totalSpeed} title={'Speed'} color={'#3DD598'} />
                                <DefaultCircularProgress value={((totalTemp / arrayNotification.length) * 100) || 0} text={totalTemp} title={'Temp'} color={'#FF974A'} />
                                <DefaultCircularProgress value={((totalGeoFence / arrayNotification.length) * 100) || 0} text={totalGeoFence} title={'Geofence'} color={'#FF974A'} />
                            </Stack> */}
                            <Grid container xs={12} columnSpacing={"26px"}>
                                <Grid item xs={3} >
                                    <Stack>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                            {'Altitude' + " " + `${parseInt((totalAltitude / arrayNotification.length) * 100) || 0}%`}
                                        </Typography>
                                        <LinearProgress
                                            sx={{
                                                width: '100%',
                                                height: "6px",
                                                flexShrink: 0,
                                                borderRadius: '2px',
                                                bgcolor: '#EFEFEF',
                                                mt: "17px",
                                                '.MuiLinearProgress-bar': {
                                                    borderRadius: '3px',
                                                    bgcolor: "",
                                                },
                                            }}
                                            variant={'determinate'}
                                            value={(totalAltitude / arrayNotification.length) * 100 || 0}
                                        />
                                        <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                            {totalAltitude}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={3} >
                                    <Stack>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                            {'Speed' + " " + `${parseInt((totalSpeed / arrayNotification.length) * 100) || 0}%`}
                                        </Typography>
                                        <LinearProgress
                                            sx={{
                                                width: '100%',
                                                height: "6px",
                                                flexShrink: 0,
                                                borderRadius: '2px',
                                                bgcolor: '#EFEFEF',
                                                mt: "17px",
                                                '.MuiLinearProgress-bar': {
                                                    borderRadius: '3px',
                                                    bgcolor: "#3DD598",
                                                },
                                            }}
                                            variant={'determinate'}
                                            value={((totalSpeed / arrayNotification.length) * 100) || 0}
                                        />
                                        <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                            {totalSpeed}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={3}>
                                    <Stack>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                            {'Temp' + " " + `${parseInt((totalTemp / arrayNotification.length) * 100) || 0}%`}
                                        </Typography>
                                        <LinearProgress
                                            sx={{
                                                width: '100%',
                                                height: "6px",
                                                flexShrink: 0,
                                                borderRadius: '2px',
                                                bgcolor: '#EFEFEF',
                                                mt: "17px",
                                                '.MuiLinearProgress-bar': {
                                                    borderRadius: '3px',
                                                    bgcolor: "#FF974A",
                                                },
                                            }}
                                            variant={'determinate'}
                                            value={((totalTemp / arrayNotification.length) * 100) || 0}
                                        />
                                        <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                            {totalTemp}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={3}>
                                    <Stack>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                            {'Geofence' + " " + `${parseInt((totalGeoFence / arrayNotification.length) * 100) || 0}%`}
                                        </Typography>
                                        <LinearProgress
                                            sx={{
                                                width: '100%',
                                                height: "6px",
                                                flexShrink: 0,
                                                borderRadius: '2px',
                                                bgcolor: '#EFEFEF',
                                                mt: "17px",
                                                '.MuiLinearProgress-bar': {
                                                    borderRadius: '3px',
                                                    bgcolor: "#FF974A",
                                                },
                                            }}
                                            variant={'determinate'}
                                            value={((totalGeoFence / arrayNotification.length) * 100) || 0}
                                        />
                                        <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                            {totalGeoFence}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} gap={2} mt={"32px"}>
                        {/* <SearchInput name={'search'} handleQueryChange={handleQueryChange} /> */}
                        <Box maxWidth={200} width={'100%'}>
                            <Filter
                                onChange={(event) => {
                                    var value = event.target.value
                                    setSelectedNodeAsset(value)
                                    var filterArray = applyFilters(arrayNotification, query, value, selectedDevices, selectedMesurement)
                                    setFilteredArrayData(filterArray)
                                }}
                                defaultValue={selectedNodeAsset}
                                placeholder="All Asset/Node"
                            >
                                {arrayAllAssetNode.map((item, index) => (
                                    <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                ))}
                            </Filter>
                        </Box>
                        <Box maxWidth={200} width={'100%'}>
                            <Filter
                                onChange={(event) => {
                                    var value = event.target.value
                                    setSelectedDevices(value)
                                    var filterArray = applyFilters(arrayNotification, query, selectedNodeAsset, value, selectedMesurement)
                                    setFilteredArrayData(filterArray)
                                }}
                                defaultValue={selectedDevices}
                                placeholder="All Devices"
                            >
                                {arrayDevices.map((item, index) => (
                                    <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                ))}
                            </Filter>
                        </Box>

                        <Box maxWidth={200} width={'100%'}>
                            <Filter
                                onChange={(event) => {
                                    var value = event.target.value
                                    setSelectedMesurement(value)
                                    var filterArray = applyFilters(arrayNotification, query, selectedNodeAsset, selectedDevices, value)
                                    setFilteredArrayData(filterArray)
                                }}
                                defaultValue={selectedMesurement}
                                placeholder="All Measurements"
                                menuKey={-2}
                            >
                                {arrayMeasurment.map((item, index) => (
                                    <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                ))}
                            </Filter>
                        </Box>
                        <Datepicker initialDate={startDate} handleDateChange={handleStartDateChange} sx={{
                            height: 30, alignItems: 'center',
                            justifyContent: 'center',
                        }} />
                        <Datepicker initialDate={endDate} handleDateChange={handleEndDateChange} sx={{
                            height: 30, alignItems: 'center',
                            justifyContent: 'center',
                        }} />
                        <ButtonBase
                            sx={{
                                height: 30,
                                width: 30,
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}
                            onClick={() => {
                                if (filteredArrayData.length > 0) {
                                    const updatedArrayData = filteredArrayData.map((res) => {
                                        const measurment = arrayMeasurment.find((measurement) => measurement.id == res.eventType)?.value
                                        return { "Asset/Node": res.name, "Event ID": res.eventID, "Rule": res.ruleName, "Mesurement": measurment, "Condition": res.eventText, "value": res.value, Timestamp: res.createdAt };
                                    });
                                    const csvData = Papa.unparse(updatedArrayData);

                                    // Create a Blob object containing the CSV data
                                    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

                                    // Create a URL for the Blob
                                    const url = URL.createObjectURL(blob);

                                    // Create a download link and trigger the click event to download the CSV file
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.download = `Events.csv`;
                                    link.click();

                                    // Release the Blob URL
                                    URL.revokeObjectURL(url);
                                } else {
                                    enqueueSnackbar("There is no data to export", {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                }

                            }}
                        >
                            <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />

                        </ButtonBase>
                        {/* <Tabs data={internalEventTabs} activeTab={activeTab} setActiveTab={handleChangeTab} inline /> */}
                        {/* <Box maxWidth={200} width={'100%'}>
                            <DefaultSelect defaultValue={'Groups'} name={'groups'} />
                        </Box>
                        <Box maxWidth={200} width={'100%'}>
                            <DefaultSelect defaultValue={'Status'} name={'status'} />
                        </Box>
                        <Box maxWidth={200} width={'100%'}>
                            <DefaultSelect defaultValue={'Devices'} name={'devices'} />
                        </Box> */}
                    </Stack>
                </Stack>
                <Stack flex={1} borderTop={'1px solid #E1E3E8'} direction={'row'}>
                    <DefaultDataGrid rows={filteredArrayData} columns={columns} onRowClick={(e) => {
                        if (e.row.assetID) {
                            navigate(`/asset-listing/detail`, { state: { id: e.row.assetID, activeTab: 'events' } })
                        } else if (e.row.nodeID) {
                            navigate(`/node-listing/detail`, { state: { id: e.row.nodeID, activeTab: 'events' } })
                        } else if (e.row.personnelID) {
                            navigate(`/personnel-listing/detail`, { state: { id: e.row.personnelID, activeTab: 'events' } })
                        }

                    }} />
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider>
    );
};
