import React, { useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Typography } from '@mui/material';

export const DefaultDataGrid = ({
    columns,
    rows,
    sx,
    disablePagination,
    pageItems,
    pageSizeOptions = [25, 50, 100],
    handlePageChange,
    onRowClick = () => { },
    defaultSize = 13,
    ...otherProps
}) => {
    const handlePageSizeChange = useCallback(
        ({ pageSize }) => {
            handlePageChange && handlePageChange(pageSize);
        },
        [handlePageChange]
    );
    const NoRowsOverlay = () => (
        <Stack sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography>No Available Data</Typography>
        </Stack>
    );

    const renderCell = (params) => {
        return (
            <Typography
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '200px', // adjust the max width as needed
                }}
            >
                {params.value}
            </Typography>
        );
    };
    return (
        <DataGrid
            onPaginationModelChange={handlePageSizeChange}
            initialState={{
                pagination: { paginationModel: { pageSize: pageItems || 25 } },
            }}
            pageSizeOptions={pageSizeOptions}
            onRowClick={onRowClick}
            sx={{
                minHeight: '100px',
                border: 'none',
                fontSize: defaultSize,
                '.MuiDataGrid-columnHeader': {
                    '&:last-of-type': {
                        overflow: 'hidden',
                        pr: 0,
                    },
                    '&:last-of-type .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '&:first-of-type': {
                        pl: 0,
                    },
                    borderBottom: '1px dashed #8F98AB45',  // Add this for dashed bottom border
                },
                '.MuiDataGrid-columnHeaders': {
                    border: 'none',
                    borderRadius: '5px',
                    fontSize: 12,
                    overflow: 'visible',
                    borderBottom: '1px dashed #8F98AB45',
                },
                '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 500,
                    color: '#262B40',
                },
                '.MuiDataGrid-footerContainer': {
                    border: 'none',
                    display: (disablePagination || rows.length === 0) && 'none',
                },
                '.MuiDataGrid-cell': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    bgcolor: '#FFFFFF',
                    border: 'none',
                    '&:last-of-type': {
                        pr: 0,
                    },
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:first-of-type': {
                        pl: 0,
                    },

                    borderBottom: '1px dashed #8F98AB45',  // Dashed border for cells
                    maxWidth: '200px',
                },
                '.MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '.MuiDataGrid-row:nth-of-type(even)': {
                    bgcolor: '#FFFFFF',

                    '.MuiAvatar-root': {
                        backgroundColor: 'secondary.main',
                        color: 'common.white',
                    },
                },
                '.MuiTablePagination-root': {
                    width: '100%',
                    mr: 'auto',
                },
                '.MuiToolbar-gutters': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                },
                '.MuiTablePagination-displayedRows': {
                    mr: 'auto',
                },
                '.MuiTablePagination-spacer': {
                    display: 'none',
                },
                '.MuiDataGrid-selectedRowCount': {
                    flexShrink: 0,
                },
                '.MuiDataGrid-virtualScroller': {
                    overflow: 'auto',
                    overflowX: 'visible',
                },
                '.MuiDataGrid-main': {
                    overflowX: 'hidden',
                },
                '.MuiDataGrid-columnHeadersInner': {
                    bgcolor: '#FFFFFF',
                },
                '.MuiDataGrid-columnHeaderTitleContainerContent': {
                    flexGrow: 1,
                    maxWidth: '200px',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    width: '4px',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                    width: '4px',
                    backgroundColor: '#bdbdbd',
                },
                ...sx,
            }}
            localeText={{
                noRowsLabel: "No Available Data",
                ...otherProps.localeText
            }}
            columns={columns}
            rows={rows}
            getRowId={(row) => {
                if (row.id) {
                    return row.id
                }
                return row._id
            }}
            {...otherProps}
        />
    );
};
