import { Backdrop, Box, ButtonBase, CircularProgress, Stack, Typography, MenuItem, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SearchInput } from '../../components/SearchInput/index.js';
import plusIcon from '../../assets/images/plusIcon.svg';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { columns, rows } from './data/rule.jsx'
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios.js';
import { Filter } from '../../ui/Filter/Filter.jsx';
import { arrayDeviceProduct, arrayMeasurment, arrayPersonnelDeviceProduct } from '../RuleNew/data/arrayData.jsx';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export const RuleListing = () => {
    const navigate = useNavigate();
    const [arrayRulesData, setArrayRulesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedProduct, setSelectedProduct] = useState(-1)
    const [selectedDevice, setSelectedDevice] = useState(-1)
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arraySummeryGroups, setArraySummeryGroups] = useState([]);
    const [arrayData, setArrayData] = useState([]);
    const [arrayDevices, setArrayDevices] = useState([])

    const assetStatus = ['Active', 'Disabled'];
    const arrayTopStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Active", totals: 0, color: "#36B368" },
        { id: "2", name: "Disabled", totals: 0, color: "#979797" }
    ]
    useEffect(() => {
        getTrackerType()
        getSummery()
        getData()
        const arrayAllProduct = [
            ...arrayDeviceProduct,
            ...arrayPersonnelDeviceProduct
        ]
        setArrayData(arrayAllProduct)
    }, []);


    useEffect(() => {
        if (arraySummeryGroups.length > 0) {
            if (arraySummeryGroups.length > 0) {
                arraySummeryGroups.map((obj, index) => {
                    // pieChart(`chartdiv${index + 1}`, obj.percentage, obj.color, obj.name)
                })
            }
        }
    }, [arraySummeryGroups]);

    const getSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Rules/getSummery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                const arraySummary = []
                resData.map((data, index) => {
                    arrayMeasurment.map((measurment) => {
                        if (measurment.id == data.sensorMesurement) {
                            console.log("index", index);
                            let obj = {
                                _id: measurment.id,
                                name: measurment.value,
                                count: data.count,
                                color: index == 0 ? "#FC5308" : index == 1 ? "#0246FE" : index == 2 ? "#6790FE" : index == 3 ? "#8602B0" : "#FF3941",
                            }
                            arraySummary.push(obj)
                        }
                    })
                })
                setArraySummeryGroups(arraySummary)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Rules/getAll/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                var filterArray = applyFilters(resData, query, selectedStatus, selectedProduct, selectedDevice)
                console.log(filterArray)
                setArrayRulesData(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getTrackerType() {
        // setIsLoading(true)
        api.get('/apis/TrackerTypes/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var arrayDevice = [
                    { id: "1", value: "HAWK" },
                    { id: "2", value: "Monitor One" },
                    { id: "3", value: "FlexSense" }
                ]
                resData.map(obj => {
                    arrayDevice.push({ id: obj._id, value: obj.name })
                })
                console.log("arrayDevice", arrayDevice)
                setArrayDevices(arrayDevice)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);

        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }

    const applyFilters = (arrayDataAll, query, selectedStatus, selectedProduct, selectedDevice) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['ruleName'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (selectedStatus !== null && selectedStatus != -1) {
            var filteredArrayStatus = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    var speedStatus = dataObj.status == "1" ? "Active" : "Disabled"
                    if (selectedStatus == speedStatus) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayStatus]
        }

        if (selectedProduct !== null && selectedProduct != -1) {
            var filteredArrayGroup = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.product == selectedProduct) {
                        if (dataObj.product.includes(selectedProduct)) {
                            containsQuery = true;
                        }
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayGroup]
        }

        if (selectedDevice !== null && selectedDevice != -1) {
            var filteredArrayTracker = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.type == selectedDevice) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayTracker]
        }

        if (query === "" && selectedStatus === -1 && selectedProduct === -1 && selectedDevice === -1) {
            return arrayDataAll
        }
        return finalArray
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayRulesData, event.target.value, selectedStatus, selectedProduct, selectedDevice)
        setFilteredArrayData(filterArray)
    };

    function pieChart(chartID, percentageInSummery, color, name) {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            // console.log("root.dom.id", root.dom.id);
            // console.log("chartID", chartID);

            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        let chart = null
        if (chartID == 'chartdiv') {
            chart = root.container.children.push(am5percent.PieChart.new(root,
                {
                    innerRadius: am5.percent(90),
                    layout: root.verticalLayout
                }));
        } else {
            chart = root.container.children.push(am5percent.PieChart.new(root,
                {
                    innerRadius: am5.percent(91),
                    layout: root.verticalLayout
                }));
        }


        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: 20
        }));

        if (chartID == 'chartdiv') {
            series.get("colors").set("colors", [
                am5.color(0x1E75FF),
                am5.color(0xE6E7E8),
            ]);
            series.data.setAll([
                { sector: "", size: 0 },
                { sector: "", size: 100 },
            ]);
            label.set("text", "0%");

            if (!isNaN(percentageInSummery) && percentageInSummery != 0) {
                const remainingValue = 100 - percentageInSummery;
                console.log("remainingValue", remainingValue)
                series.data.setAll([
                    { sector: "", size: percentageInSummery },
                    { sector: "", size: remainingValue },
                ]);
                label.set("text", `${percentageInSummery}%`);
            } else {
                series.get("colors").set("colors", [
                    am5.color(0x1E75FF),
                    am5.color(0xE6E7E8),
                ]);
                series.data.setAll([
                    { sector: "", size: 0 },
                    { sector: "", size: 100 },
                ]);
                label.set("text", "0%");
            }
        } else {
            if (!isNaN(percentageInSummery) && percentageInSummery != 0) {
                series.get("colors").set("colors", [
                    am5.color(color),
                    am5.color(0xE6E7E8),
                ]);

                const remainingValue = 100 - percentageInSummery;
                console.log("remainingValue", remainingValue)
                series.data.setAll([
                    { sector: name, size: percentageInSummery },
                    { sector: "", size: remainingValue },
                ]);
                label.set("text", `${percentageInSummery}%`);
            } else {
                series.get("colors").set("colors", [
                    am5.color(0x1E75FF),
                    am5.color(0xE6E7E8),
                ]);

                series.data.setAll([
                    { sector: "", size: 0 },
                    { sector: "", size: 100 },
                ]);
                label.set("text", "0%");
            }
        }

        series.appear(1000, 100);
    }

    return (
        <Stack width={'100%'} mb={1}>
            <Stack p={3}>
                <Stack direction={'row'} gap={5} alignItems={'center'}>
                    <Stack>
                        <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={'15px'} >
                            RULES
                        </Typography>

                        <Stack direction={'row'} alignItems={'center'} gap={5}>
                            {arrayTopStatusData.map((status) => (
                                <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                    <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                        {status.name}
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: status.color,
                                            mt: '16px'
                                        }}
                                    />
                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                        {status.id == "0"
                                            ? arrayRulesData.length
                                            : status.id == "1"
                                                ? arrayRulesData.filter((data) => data.status == "1").length
                                                : arrayRulesData.filter((data) => data.status == "0").length
                                        }
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                    <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />

                    <Stack width={"100%"}>
                        <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                            {arraySummeryGroups.length > 0 ? "TOP RULES" : ""}
                        </Typography>
                        {arraySummeryGroups.length > 0 ?
                            <Grid container xs={12} columnSpacing={"26px"}>
                                {arraySummeryGroups.map((obj) => {
                                    return (
                                        <Grid item xs={2.4} key={obj._id}>
                                            <Stack>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                    {obj.name + " " + `${(obj.count / arrayData.length) * 100}%`}
                                                </Typography>
                                                <LinearProgress
                                                    sx={{
                                                        width: '100%',
                                                        height: "6px",
                                                        flexShrink: 0,
                                                        borderRadius: '2px',
                                                        bgcolor: '#EFEFEF',
                                                        mt: "17px",
                                                        '.MuiLinearProgress-bar': {
                                                            borderRadius: '3px',
                                                            bgcolor: obj.color ? obj.color : "",
                                                        },
                                                    }}
                                                    variant={'determinate'}
                                                    value={obj.count ? (obj.count / arrayData.length) * 100 : 0}
                                                />
                                                <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                    {obj.count}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            : <Stack height="70px" />}
                    </Stack>
                </Stack>
                <Stack direction={'row'} gap={1} mt={"32px"}>
                    <SearchInput name={'search'} handleQueryChange={handleQueryChange} sx={{ minWidth: '200px' }} />

                    {arrayData.map((item, index) => {
                        console.log("item", item)
                    })}
                    <Box maxWidth={160} width={'100%'}>
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedProduct(value)
                                var filterArray = applyFilters(arrayRulesData, query, selectedStatus, value, selectedDevice)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedProduct}
                            placeholder="Product"
                        >
                            {arrayData.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Box maxWidth={150} width={'100%'}>
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedStatus(value)
                                var filterArray = applyFilters(arrayRulesData, query, value, selectedProduct, selectedDevice)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedStatus}
                            placeholder="Status"
                        >
                            {assetStatus.map((item, index) => (
                                <MenuItem key={index} value={item}> {item}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    {/* <Box maxWidth={160} width={'100%'}>
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedCategory(value)
                                var filterArray = applyFilters(arrayRulesData, query, selectedStatus, selectedProduct, selectedDevice, value, selectedType)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedCategory}
                            placeholder="All Categories"
                        >
                            {arrayCategory.map((item, index) => (
                                <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Box maxWidth={160} width={'100%'}>
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedType(value)
                                var filterArray = applyFilters(arrayRulesData, query, selectedStatus, selectedProduct, selectedDevice, selectedCategory, value)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedType}
                            placeholder="All Asset Types"
                        >
                            {arrayType.map((item, index) => (
                                <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                            ))}
                        </Filter>
                    </Box> */}
                    <Box maxWidth={150} width={'100%'}>
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedDevice(value)
                                var filterArray = applyFilters(arrayRulesData, query, selectedStatus, selectedProduct, value)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedDevice}
                            placeholder="Devices"
                        >
                            {arrayDevices.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <ButtonBase
                        sx={{
                            height: 30,
                            pl: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #E2E2EA',
                            gap: 1,
                            borderRadius: '6px',
                        }}
                        onClick={() => navigate('/rule-listing/manage', { state: { id: "" } })}
                    >
                        <Stack direction={'row'} alignItems={'center'} spacing={1} width={'100%'}>
                            <Typography fontSize={12} color={'#615D5D'}>
                                Add
                            </Typography>
                            <Box component={'img'} src={plusIcon} sx={{ pl: 1, pr: 1 }} />
                        </Stack>
                    </ButtonBase>
                    {/* <ButtonBase
                        sx={{
                            height: 30,
                            px: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            border: '1px solid #E2E2EA',
                            bgcolor: '#F6F8FA',
                            borderRadius: '6px',
                        }}
                    // onClick={() => {
                    //     const updatedArrayData = filteredArrayData.map((res) => {
                    //         const date = res.tracker && res.tracker.timestamp ? res.tracker.timestamp : null
                    //         var formatedDate = date != null ? calculateDuration(date, moment().format("MM/DD/YY hh:mm a")) : "-"

                    //         var arrayGroup = res.groupData
                    //         var groupText = arrayGroup.map(group => group.name).join(',')

                    //         return { Asset: res.assetName, Status: speedStatus, Device: res.tracker.deviceSerialNumber, Groups: groupText, "Last Updated": formatedDate };
                    //     });
                    //     const csvData = Papa.unparse(updatedArrayData);

                    //     // Create a Blob object containing the CSV data
                    //     const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

                    //     // Create a URL for the Blob
                    //     const url = URL.createObjectURL(blob);

                    //     // Create a download link and trigger the click event to download the CSV file
                    //     const link = document.createElement('a');
                    //     link.href = url;
                    //     link.download = `Asset.csv`;
                    //     link.click();

                    //     // Release the Blob URL
                    //     URL.revokeObjectURL(url);
                    // }}
                    >
                        <Typography fontSize={14} >
                            Export
                        </Typography>
                    </ButtonBase> */}
                </Stack>
            </Stack>
            <Stack flex={1} borderTop={'1px solid #E1E3E8'} direction={'row'}>
                <DefaultDataGrid rows={filteredArrayData} columns={columns(calculateDuration, arrayDevices)}
                    onRowClick={(e) => navigate(`/rule-listing/manage`, { state: { id: e.row._id } })}
                />
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
