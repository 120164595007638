import { Backdrop, Box, ButtonBase, CircularProgress, MenuItem, Stack, Typography, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import { DefaultInput } from '../../../ui/DefaultInput/DefaultInput.jsx';
import api from '../../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { arrayMeasurment } from '../../RuleNew/data/arrayData.jsx'

export const SelectTimelineAlert = ({ selectedDataId, nodeData, sensorData, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arraySensorDataType, setArraySensorDataType] = useState([]);
    const formik = useFormik({
        initialValues: {
            groupName: nodeData?.objMachineRuntime?.groupName || '',
            selectedSensor: null,
            selectedField: null,
            minimumRuntime: nodeData?.objMachineRuntime?.minimumRuntime || '',
            maximumThreshhold: nodeData?.objMachineRuntime?.maximumThreshhold || '',
        },
        validationSchema: Yup.object().shape({
            groupName: Yup.string().required('Group name is required'),
            selectedSensor: Yup.object().required('Sensor is required'),
            selectedField: sensorData != null && sensorData.product == '4' ? Yup.object().test('is-error', 'Field is required', function (value) {
                const { selectedSensor } = this.parent;
                console.log("selectedSensor: ", selectedSensor)
                if (selectedSensor != null && selectedSensor.id != '1' && selectedSensor.id != '4' && selectedSensor.id != '6') {
                    return true;
                }
                return value != null
            }) : "",
            minimumRuntime: Yup.string().required('Minimum runtime is required'),
            maximumThreshhold: Yup.string().required('Maximum Threshold is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    groupName: values.groupName,
                    selectedSensor: values.selectedSensor,
                    selectedField: values.selectedField,
                    minimumRuntime: values.minimumRuntime,
                    maximumThreshhold: values.maximumThreshhold,
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apicalling(values);
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('Chart updated', {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    onClose(true)
                } else {
                    setStatus({ success: false });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        if (nodeData != null && sensorData != null) {
            if (sensorData.product == '4') {
                const arrayMeasurment = [
                    { id: "1", value: "Temperature", field: "temperature", symbol: "°F" },
                    { id: "4", value: "Battery", field: "battery", symbol: "" },
                    { id: "6", value: "Rssi", field: "rssi", symbol: "" },
                    { id: '7', value: 'rms_ACC_G ', field: "RmsACCG" },
                    { id: '8', value: 'max_ACC_G', field: "MaxACCG" },
                    { id: '9', value: 'velocity_mm_sec', field: "VelocityMmSec" },
                    { id: '10', value: 'displacement_mm', field: "DisplacementMm" },
                    { id: '11', value: 'peak_one_Hz', field: "PeakOneHz" },
                    { id: '12', value: 'peak_two_Hz', field: "PeakTwoHz" },
                    { id: '13', value: 'peak_three_Hz', field: "PeakThreeHz" }
                ]
                setArraySensorDataType(arrayMeasurment)
            } else {
                getSecondaryData()
            }
        }
    }, []);

    useEffect(() => {
        var selectedSensor = arraySensorDataType.find(obj => obj.id == nodeData?.objMachineRuntime?.sensor)
        var selectedField = null
        if (sensorData != null && sensorData.product == '4' && selectedSensor != null && selectedSensor.id != '1' && selectedSensor.id != '4' && selectedSensor.id != '6') {
            if (nodeData?.objMachineRuntime?.field == "1") {
                selectedField = { id: "1", value: `x_${selectedSensor.value}` }

            } else if (nodeData?.objMachineRuntime?.field == "2") {
                selectedField = { id: "2", value: `y_${selectedSensor.value}` }
            }
            else if (nodeData?.objMachineRuntime?.field == "3") {
                selectedField = { id: "3", value: `z_${selectedSensor.value}` }
            }
        }
        var dataValues = {
            groupName: nodeData?.objMachineRuntime?.groupName || '',
            selectedSensor: selectedSensor,
            selectedField: selectedField,
            minimumRuntime: nodeData?.objMachineRuntime?.minimumRuntime || '',
            maximumThreshhold: nodeData?.objMachineRuntime?.maximumThreshhold || '',
        }
        formik.setValues(dataValues)


    }, [arraySensorDataType]);


    const getSecondaryData = () => {
        setIsLoading(true)
        api.get('/apis/SecondaryDatas/byNode/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                let arraySensorDataType = arrayMeasurment.filter(item => item.array.includes(sensorData.product + ""))
                data.forEach((obj) => {
                    const exists = arraySensorDataType.some(sensor =>
                        sensor.id === obj._id);
                    if (!exists) {
                        let newData = { id: obj._id, value: obj.name, field: "pin", symbol: "" }
                        arraySensorDataType.push(newData);

                    }
                })
                setArraySensorDataType(arraySensorDataType)

            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }


    async function apicalling(values) {
        var data = {
            groupName: values.groupName,
            sensor: values.selectedSensor.id,
            field: values.selectedField?.id || "",
            minimumRuntime: values.minimumRuntime,
            maximumThreshhold: values.maximumThreshhold,
        }
        return await api.put('/apis/Nodes/updateRunTimeMachine/' + selectedDataId, data)
    }


    return (
        <>
            <FormikProvider value={formik}>
                <Stack
                    ml={1}
                    mr={3}
                    gap={3}
                    p="17px"
                    sx={{
                        border: '1px solid #E6E7E8',
                        borderRadius: '3.5px',
                        width: 332,
                    }}
                    component={'form'}
                    onSubmit={formik.handleSubmit}>
                    <Stack gap={2}>
                        <Typography fontFamily={'Roboto'} fontWeight={'Bold'} color={'#1F243B'} fontSize={15}>Runtime Configurator</Typography>
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                            <Stack gap="15px" width={'100%'}>
                                <Stack maxWidth={200}>
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 2,
                                            },
                                        }}
                                        label={'Data Group Name'}
                                        value={formik.values.groupName}
                                        name={'groupName'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.groupName && formik.errors.groupName)}
                                        helperText={formik.touched.groupName && formik.errors.groupName}
                                    />
                                </Stack>
                                <Stack maxWidth={200}>
                                    <DefaultSelect
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: 40,
                                            },
                                        }}
                                        label={'Select Sensor'}
                                        value={formik.values.selectedSensor}
                                        onItemSelected={(e) => {
                                            var selectedObj = e.target.value
                                            formik.setFieldValue('selectedSensor', selectedObj)
                                            if (sensorData != null && sensorData.product == '4' && selectedObj != null) {
                                                if (selectedObj.id == '1' || selectedObj.id == '4' || selectedObj.id == '6') {
                                                    formik.setFieldValue('selectedField', null)
                                                } else {
                                                    formik.setFieldValue('selectedField', { id: "1", value: `x_${selectedObj.value}` })
                                                }

                                            }
                                        }}
                                        helperText={formik.touched.selectedSensor && formik.errors.selectedSensor}
                                        error={Boolean(formik.touched.selectedSensor && formik.errors.selectedSensor)}
                                    >
                                        {arraySensorDataType.map((item, index) => (
                                            <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                        ))}
                                    </DefaultSelect>
                                </Stack>

                                {sensorData.product == '4' && formik.values.selectedSensor != null && formik.values.selectedSensor.id != "1" && formik.values.selectedSensor.id != "4" && formik.values.selectedSensor.id != "6" && <Stack maxWidth={200}>
                                    <DefaultSelect
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: 40,
                                            },
                                        }}
                                        label={'Select Field'}
                                        value={formik.values.selectedField}
                                        onItemSelected={(e) => {
                                            formik.setFieldValue('selectedField', e.target.value)
                                        }}
                                        helperText={formik.touched.selectedField && formik.errors.selectedField}
                                        error={Boolean(formik.touched.selectedField && formik.errors.selectedField)}
                                    >

                                        <MenuItem key={0} value={{ id: "1", value: `x_${formik.values.selectedSensor.value}` }}> {`x_${formik.values.selectedSensor.value}`}</MenuItem>
                                        <MenuItem key={1} value={{ id: "2", value: `y_${formik.values.selectedSensor.value}` }}> {`y_${formik.values.selectedSensor.value}`}</MenuItem>
                                        <MenuItem key={2} value={{ id: "3", value: `z_${formik.values.selectedSensor.value}` }}> {`z_${formik.values.selectedSensor.value}`}</MenuItem>

                                    </DefaultSelect>
                                </Stack>}

                                <Stack maxWidth={200}>
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 2,
                                                width: 200
                                            },
                                        }}
                                        type={'number'}
                                        label={'Runtime Minimum'}
                                        value={formik.values.minimumRuntime}
                                        name={'minimumRuntime'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.minimumRuntime && formik.errors.minimumRuntime)}
                                        helperText={formik.touched.minimumRuntime && formik.errors.minimumRuntime}
                                    />
                                </Stack>

                                <Stack maxWidth={200}>
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 2,
                                                width: 200
                                            },
                                        }}
                                        type={'number'}
                                        label={'Maximum Threshold'}
                                        value={formik.values.maximumThreshhold}
                                        name={'maximumThreshhold'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.maximumThreshhold && formik.errors.maximumThreshhold)}
                                        helperText={formik.touched.maximumThreshhold && formik.errors.maximumThreshhold}
                                    />
                                </Stack>

                                <Stack direction={'row'} gap={2} >
                                    <Box flex={1}></Box>
                                    <ButtonBase
                                        sx={{
                                            border: '1px solid #E2E2EA',
                                            bgcolor: '#F6F8FA',
                                            borderRadius: '5px',
                                            height: '30px',
                                            width: '100px'
                                        }}
                                        onClick={() => { onClose(false) }}
                                    >
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                            Cancel
                                        </Typography>
                                    </ButtonBase>
                                    <ButtonBase
                                        sx={{
                                            height: '30px',
                                            width: '100px',
                                            bgcolor: '#1E75FF',
                                            borderRadius: '5px',
                                        }}
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={500} color={'#FAFAFB'}>
                                            Save
                                        </Typography>
                                    </ButtonBase>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </FormikProvider>
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}