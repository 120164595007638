import { Box, Stack, Typography,} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import batteryIcon from '../../assets/images/batteryIcon.svg';
import redBatteryIcon from '../../assets/images/redBatteryIcon.svg';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const Dashboard = ({ data, index }) => {
    const navigate = useNavigate();

    useEffect(() => {
        pieChart(`chartDiv${index + 1}`)
    }, []);

    const pieChart = (chartID) => {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5percent.PieChart.new(root,
            {
                innerRadius: 50,
                layout: root.verticalLayout
            }));

        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: 20
        }));

        series.get("colors").set("colors", [
            am5.color(0x1E75FF),
            am5.color(0xE6E7E8),
        ]);
        console.log("1234", data?.health || 0)
        var other = 100 - (data?.health || 0)
        series.data.setAll([
            { sector: "", size: data?.health || 0 },
            { sector: "", size: other },
        ]);
        label.set("text", `${data?.health || 0}%`);
        series.appear(1000, 100);
    }

    return (
        <Stack sx={{ overflow: 'hidden', width: '196px', height: '194px' }}
            onClick={() => { navigate(`/node-listing/detail`, { state: { id: data._id } }) }}
        >
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    bgcolor: data.state == "1" ? "#FFFFFF" : "#FEF8F8",
                    borderRadius: "5px",
                    border: '1px solid ',
                    borderColor: "#D5D5D5",
                }}
            >
                <Stack height={'100%'} width={'100%'}>
                    <Stack direction={'row'} justifyContent={'center'} width={'100%'} height={'100%'} pl={'11px'} pr={'10px'} pt={'9px'} alignItems={'center'} pb={'6px'}>
                        <Stack justifyContent={'space-between'} height={'100%'} width={'100%'} alignItems={'center'}>
                            <Typography fontSize={15} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'}
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {data.nodeName || "-"}
                            </Typography>

                            <Box
                                sx={{
                                    height: '100px',
                                    width: '100px',
                                }}
                                id={`chartDiv${index + 1}`}
                            />
                            <Stack direction={"row"} width={'100%'} alignItems={'center'} gap={'22px'}>
                                <Box
                                    sx={{
                                        height: '16px',
                                        width: '16px',
                                    }}
                                >
                                    {data.isFavourite == "1" && <CheckBoxIcon sx={{
                                        height: '16px',
                                        width: '16px',
                                    }} />}
                                </Box>
                                <Typography fontSize={15} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                    {data.temperature != "-" && data.temperature ? `${data.temperature} °F` : "-"}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box flexGrow={1} />
                        <Stack gap={'10px'} height={'100%'} justifyContent={'space-between'}>
                            <Stack direction={'row'} alignItems={'center'} gap={'4px'} mt={'4px'}>
                                <Typography fontSize={10} color={"#151D26"} fontWeight={400} fontFamily={'Roboto'} >
                                    {data.state == "1" ? "Active" : "Offline"}
                                </Typography>
                                <Box
                                    sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        backgroundColor: data.state == "1" ? '#1AAB87' : "#D34C46",
                                    }}
                                />
                            </Stack>
                            <Stack gap={'7px'}>
                                <Typography fontSize={15} color={data.totalEvent == 0 ? '#151D26' : '#3333CC'} fontWeight={500} fontFamily={'Roboto'}  >
                                    {data.totalEvent || 0}
                                </Typography>
                                <Typography fontSize={10} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} >
                                    Events
                                </Typography>
                            </Stack>
                            <Stack gap={'7px'}>
                                <Typography fontSize={15} color={data.totalAnomalies == 0 ? '#151D26' : '#1E75FF'} fontWeight={500} fontFamily={'Roboto'}  >
                                    {data.totalAnomalies || 0}
                                </Typography>
                                <Typography fontSize={10} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} >
                                    Anomaly
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} gap={'10px'} alignItems={'center'} mt={'10px'}>
                                <Typography fontSize={'15px'} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} >
                                    {data.battery != "-" && data.battery ? (data.product != null && data.product === "4" ? `${parseInt(data.battery)}%` : `${data.battery}v`) : "-"}
                                </Typography>
                                <Box component={'img'} src={data.battery != "-" && data.battery ? (parseFloat(data.battery).toFixed(1) < 3.5 ? redBatteryIcon : batteryIcon) : batteryIcon} sx={{ height: '18px', width: '18px', rotate: data.battery != "-" && data.battery ? (parseFloat(data.battery).toFixed(1) < 3.5 ? '0deg' : '-90deg') : '-90deg' }} />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box sx={{
                        height: "6px",
                        width: "100%",
                        bgcolor: data.isAnomalies == "1" ? "#FF861E" : data.isEvent == "1" ? "#3333CC" : data.state == "1" ? '#1AAB87' : "#D34C46",
                        borderRadius: "0px 0px 5px 5px",
                    }}>

                    </Box>
                </Stack>

            </Box >
        </Stack >
    )
}