export const arrayRole = [
    { id: "1", value: "Admin" },
    { id: "2", value: "Standard" },
]

export const arrayStatus = [
    { id: "1", value: "Active" },
    { id: "2", value: "Inactive" },
]

export const arrayGroupType = [
    { id: "1", value: "Asset" },
    { id: "2", value: "Node" },
    { id: "3", value: "Personnel" },
]