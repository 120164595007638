import { Box, Stack, Typography, Backdrop, CircularProgress, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AssetItem } from '../../modules/AssetMapView/AssetItem.jsx';
import { MapView } from '../../components/Map/MapView.jsx';
import api from '../../lib/axios';
import moment from 'moment';
import { NodeItem } from './NodeItem.jsx';
import TimelineTimePanel from '../../modules/NodeDetail/Timeline/TimelineTimePanel.jsx';
import { TimeLineGraph } from '../../components/Timeline/TimeLineGraph.jsx';
import { AssetMapViewFilter } from './AssetMapViewFilter.jsx';
import { PersonnelItem } from './PersonnelItem.jsx';

export const AssetMapView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [mapViewData, setMapViewData] = useState(null);
    const [filterMapViewData, setFiterMapViewData] = useState(null);
    const [arraySummeryGroups, setArraySummeryGroups] = useState([]);
    const [arraySummeryGroupsNode, setArraySummeryGroupsNode] = useState([]);
    const [isAssetOrNode, setIsAssetOrNode] = useState(false);
    const [eventPercentage, setEventPercentage] = useState(-1)
    const [arrayTimeline, setArrayTimeline] = useState([]);
    const [isTimeline, setIsTimline] = useState(localStorage.getItem("mapViewTimeLineSelected") || "1");
    const [isTotals, setIsTotals] = useState(localStorage.getItem("mapViewTotalsSelected") || "1");
    const [isPersonnel, setIsPersonnel] = useState(localStorage.getItem("mapViewPersonnelSelected") || "1");
    const [isAsset, setIsAsset] = useState(localStorage.getItem("mapViewAssetSelected") || "1");
    const [isNodes, setIsNodes] = useState(localStorage.getItem("mapViewNodesSelected") || "1");

    const arrayTopAssetStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Moving", totals: 0, color: "#36B368" },
        { id: "2", name: "Events", totals: 0, color: "#1E75FF" },
        { id: "3", name: "Anomalies", totals: 0, color: "#FFAD2C" },
        { id: "4", name: "Idle", totals: 0, color: "#979797" },
    ]

    const arrayTopNodeStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Active", totals: 0, color: "#36B368" },
        { id: "2", name: "Events", totals: 0, color: "#1E75FF" },
        { id: "3", name: "Anomalies", totals: 0, color: "#FFAD2C" },
        { id: "4", name: "Offline", totals: 0, color: "#979797" },
    ]

    useEffect(() => {
        getAllEventTimeLineData()
        getSummery()
        getNodeSummery()
    }, []);


    const getSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Assets/summery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySummeryGroups(resData.arrayGroup)
                // setPercentageInSummery(resData.inServicePercentage)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getNodeSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Nodes/summery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySummeryGroupsNode(resData.arrayGroup)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getAllEventTimeLineData = () => {
        api.post('/apis/RuleEvents/getAllEventTimelineData/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayTimeline(resData.arrayData)

                const now = moment();
                const hours = now.hours();
                const minutes = now.minutes();
                const seconds = now.seconds();

                // Convert hours, minutes, and seconds into total seconds
                const currentSecond = (hours * 3600) + (minutes * 60) + seconds;
                const totalSecondsInDay = 24 * 60 * 60;
                const percentageWidth = (currentSecond / totalSecondsInDay) * 100;
                setEventPercentage(percentageWidth)

            }
            //setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <Stack height={"100vh"} width="100%" overflow={"hidden"}>
            <Stack flex={1} borderTop={'1px solid #E1E3E8'} direction={'row'} height={"100%"} width="100%" overflow={"hidden"}>
                <Box
                    width={"300px"}
                    flexShrink={0}
                    borderRight={'1px solid #E1E3E8'}
                    display={"auto"}
                    height={"100vh"}
                    sx={{
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            width: '4px',
                            backgroundColor: '#bdbdbd',
                        },
                    }}
                >
                    <AssetMapViewFilter
                        setMapViewData={setMapViewData}
                        mapViewData={mapViewData}
                        filterMapViewData={filterMapViewData}
                        setFiterMapViewData={setFiterMapViewData}
                        isTimeline={isTimeline}
                        isTotals={isTotals}
                        isPersonnel={isPersonnel}
                        isAsset={isAsset}
                        isNodes={isNodes}
                        setIsTimline={setIsTimline}
                        setIsTotals={setIsTotals}
                        setIsPersonnel={setIsPersonnel}
                        setIsAsset={setIsAsset}
                        setIsNodes={setIsNodes}
                    />

                    <Box sx={{ height: '1px', bgcolor: "#8F98AB45", mt: '20px', mb: '11px' }} ></Box>
                    {isAsset == "1" &&
                        <Stack>
                            <Typography fontWeight={700} fontSize={14} color={"#151D26"} whiteSpace={'nowrap'} fontFamily={'Roboto'} pl={"16px"} pt={'9px'}>
                                Assets
                            </Typography>
                            {filterMapViewData != null && filterMapViewData.arrayAsset.length > 0 ? filterMapViewData.arrayAsset.map((item, index) => {
                                var speedStatus = item.tracker != null && item.tracker.state != null && item.tracker.state == 1 ? "Moving" : "Idle"
                                return <AssetItem
                                    key={`asset-item-${index}`}
                                    id={item._id}
                                    title={item.assetName}
                                    temp={item.tracker != null && item.tracker.temperature != null ? item.tracker.temperature : ""}
                                    speed={item.tracker != null && item.tracker.positionSpeed != null ? item.tracker.positionSpeed : ""}
                                    tags={[
                                        {
                                            text: speedStatus,
                                            textSize: 12,
                                            borderColor: speedStatus == "Moving" ? 'green.main' : "#E1E3E8",
                                        },
                                    ]}
                                    deviceID={item.tracker != null && item.tracker.deviceSerialNumber != null ? item.tracker.deviceSerialNumber : ""}
                                    date={item.tracker != null && item.tracker.timestamp != null ? item.tracker.timestamp : ""}
                                />
                            }) : <Stack
                                justifyContent="center"
                                alignItems="center"
                                height="50px"
                            >
                                <Typography>No Available Data</Typography>
                            </Stack>}
                        </Stack>}
                    {isNodes == "1" &&
                        <Stack>
                            <Typography fontWeight={700} fontSize={14} color={"#151D26"} whiteSpace={'nowrap'} fontFamily={'Roboto'} pl={"16px"} pt={'9px'}>
                                Nodes
                            </Typography>
                            {filterMapViewData != null && filterMapViewData.arrayNode.length > 0 ? filterMapViewData.arrayNode.map((item, index) => {
                                return <NodeItem
                                    key={`node-item-${index}`}
                                    id={item._id}
                                    title={item.nodeName}
                                    temp={item.sensor != null && item.sensor.temperature != null ? item.sensor.temperature : ""}
                                    speed={item.sensor != null && item.sensor.positionSpeed != null ? item.sensor.positionSpeed : ""}
                                    deviceID={item.sensor != null && item.sensor.sensorNumber != null ? item.sensor.sensorNumber : ""}
                                    battery={item.sensor != null && item.sensor.battery != null ? item.sensor.battery : ""}
                                    product={item.sensor != null && item.sensor.product != null ? item.sensor.product : ""}
                                    isEvent={item.isEvent}
                                    date={item.sensor != null && item.sensor.timestamp != null ? item.sensor.timestamp : ""}
                                />
                            }) : <Stack
                                justifyContent="center"
                                alignItems="center"
                                height="50px"
                            >
                                <Typography>No Available Data</Typography>
                            </Stack>}
                        </Stack>}
                    {isPersonnel == "1" &&
                        <Stack>
                            <Typography fontWeight={700} fontSize={14} color={"#151D26"} whiteSpace={'nowrap'} fontFamily={'Roboto'} pl={"16px"} pt={'9px'}>
                                Personnel
                            </Typography>
                            {filterMapViewData != null && filterMapViewData.arrayPersonnel.length > 0 ? filterMapViewData.arrayPersonnel.map((item, index) => {
                                return <PersonnelItem
                                    key={`personnel-item-${index}`}
                                    id={item._id}
                                    title={item.firstName + " " + item.lastName}
                                    temp={item.sensor != null && item.sensor.temperature != null ? item.sensor.temperature : ""}
                                    deviceID={item.sensor != null && item.sensor.sensorNumber != null ? item.sensor.sensorNumber : ""}
                                    light={item.sensor != null && item.sensor.light != null ? item.sensor.light : ""}
                                    battery={item.sensor != null && item.sensor.battery != null ? item.sensor.battery : ""}
                                    isEvent={item.isEvent}
                                    date={item.sensor != null && item.sensor.timestamp != null ? item.sensor.timestamp : ""}
                                />
                            }) : <Stack
                                justifyContent="center"
                                alignItems="center"
                                height="50px"
                            >
                                <Typography>No Available Data</Typography>
                            </Stack>}
                        </Stack>
                    }
                </Box>
                <Stack flex={1}>
                    {isTotals == "1" && (!isAssetOrNode ?
                        (<Stack>
                            <Stack direction={'row'} alignItems={'center'} gap={5} ml={'25px'} mt={'19px'}>
                                <Stack>
                                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={'15px'} >
                                        ASSETS
                                    </Typography>
                                    <Stack direction={'row'} alignItems={'center'} gap={5}>
                                        {arrayTopAssetStatusData.map((status) => {
                                            var arrayAsset = mapViewData != null && mapViewData.arrayAsset != null ? mapViewData.arrayAsset : []
                                            return <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                                <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                                    {status.name}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: '50%',
                                                        backgroundColor: status.color,
                                                        mt: '16px'
                                                    }}
                                                />
                                                <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>

                                                    {status.id == "0"
                                                        ? arrayAsset.length
                                                        : status.id == "1"
                                                            ? arrayAsset.filter((data) => data && data.tracker && data.tracker.positionSpeed > 0).length
                                                            : status.id == "2"
                                                                ? arrayAsset.filter((data) => data && data.isEvent == "1").length
                                                                : status.id == "4"
                                                                    ? arrayAsset.filter((data) => data && data.tracker && (data.tracker.positionSpeed === "0.0" || data.tracker.positionSpeed === "-")).length
                                                                    : status.totals
                                                    }
                                                </Typography>
                                            </Stack>
                                        })}
                                    </Stack>
                                </Stack>
                                <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                                <Stack width={"100%"}>
                                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                        {arraySummeryGroups.length > 0 ? "TOP METRICS" : ""}
                                    </Typography>
                                    {arraySummeryGroups.length > 0 ?
                                        <Grid container xs={12} columnSpacing={"26px"}>
                                            {arraySummeryGroups.map((obj) => {
                                                return (
                                                    <Grid item xs={2.4} key={obj._id}>
                                                        <Stack>
                                                            <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                                {obj.name + " " + `${obj.percentage}%`}
                                                            </Typography>
                                                            <LinearProgress
                                                                sx={{
                                                                    width: '100%',
                                                                    height: "6px",
                                                                    flexShrink: 0,
                                                                    borderRadius: '2px',
                                                                    bgcolor: '#EFEFEF',
                                                                    mt: "17px",
                                                                    '.MuiLinearProgress-bar': {
                                                                        borderRadius: '3px',
                                                                        bgcolor: obj.color ? obj.color : "",
                                                                    },
                                                                }}
                                                                variant={'determinate'}
                                                                value={obj.percentage ? obj.percentage : 0}
                                                            />
                                                            <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                                {obj.assignedAsset}
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        : <Stack height="70px" />}
                                </Stack>
                            </Stack> < Box sx={{ border: '1px solid #E1E3E8' }}></Box>
                        </Stack>)
                        :
                        (<Stack>
                            <Stack direction={'row'} alignItems={'center'} gap={5} ml={'25px'} mt={'19px'}>
                                <Stack>
                                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                        NODES
                                    </Typography>
                                    <Stack direction={'row'} alignItems={'center'} gap={5} >
                                        {arrayTopNodeStatusData.map((status) => {
                                            var arrayNode = mapViewData != null && mapViewData.arrayNode != null ? mapViewData.arrayNode : []
                                            return <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                                <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                                    {status.name}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: '50%',
                                                        backgroundColor: status.color,
                                                        mt: '16px'
                                                    }}
                                                />
                                                <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                                    {status.id == "0" ? arrayNode.length : status.id == "1" ? arrayNode.filter((data) => data.sensor.state == "1").length : status.id == "2" ? arrayNode.filter((data) => data.isEvent == "1").length : status.id == "4" ? arrayNode.filter((data) => data.sensor.state == "0").length : status.totals}
                                                </Typography>
                                            </Stack>
                                        })}
                                    </Stack>
                                </Stack>
                                <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                                <Stack width={"100%"}>
                                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                        {arraySummeryGroupsNode.length > 0 ? "TOP METRICS" : ""}
                                    </Typography>
                                    {arraySummeryGroupsNode.length > 0 ?
                                        <Grid container xs={12} columnSpacing={"26px"}>
                                            {arraySummeryGroupsNode.map((obj) => {
                                                return (
                                                    <Grid item xs={2.4} key={obj._id}>
                                                        <Stack>
                                                            <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                                {obj.name + " " + `${obj.percentage}%`}
                                                            </Typography>
                                                            <LinearProgress
                                                                sx={{
                                                                    width: '100%',
                                                                    height: "6px",
                                                                    flexShrink: 0,
                                                                    borderRadius: '2px',
                                                                    bgcolor: '#EFEFEF',
                                                                    mt: "17px",
                                                                    '.MuiLinearProgress-bar': {
                                                                        borderRadius: '3px',
                                                                        bgcolor: obj.color ? obj.color : "",
                                                                    },
                                                                }}
                                                                variant={'determinate'}
                                                                value={obj.percentage ? obj.percentage : 0}
                                                            />
                                                            <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                                {obj.assignedNode}
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        : <Stack height="70px" />}
                                </Stack>
                            </Stack>
                            < Box sx={{ border: '1px solid #E1E3E8' }}></Box>
                        </Stack>)
                    )}

                    <MapView
                        data={filterMapViewData}
                        isPersonnel={isPersonnel}
                        isAsset={isAsset}
                        isNodes={isNodes}
                        isTimeLine={isTimeline}
                    />

                    {isTimeline == "1" &&
                        <Box
                            key="timePannel"
                            sx={{
                                bgcolor: '#FFFFFF',
                                borderRadius: '3.5px',
                                border: '1px solid #E6E7E8',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {arrayTimeline.length > 0 && <TimeLineGraph arrayData={arrayTimeline} totalPercentage={eventPercentage} sx={{ height: '32px', marginBottom: '8px' }} />}
                            <Box width={'100%'} height={'2px'} bgcolor={"#E6E7E8"} />
                            <TimelineTimePanel />
                        </Box>
                    }
                </Stack>
            </Stack >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
};
