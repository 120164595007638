export const arraySensor = [
    { id: "1", value: "Temperature", field: "temperature", apiField: 'temperature', unit: " °F" },
    { id: "4", value: "Battery", field: "battery", apiField: 'battery', unit: "" },
    { id: "2", value: "Light", field: "light", apiField: 'light', unit: " %" },
    { id: "3", value: "SOS", field: "sos", apiField: 'sos', unit: "" },
    { id: "5", value: "Single Press", field: "buttonPressed", apiField: 'buttonPressed', unit: "" }
]

export const arrayChartType = [
    { id: "1", value: "Line Chart" },
    { id: "2", value: "Column Chart" }
]