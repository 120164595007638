import { Dialog, DialogContent, Stack, Box, Button } from "@mui/material"
import React, { useEffect, useRef, useState } from "react";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
export const CropProfilePicture = ({src,setProfilePicture,setShouldOpenCrop,setProfilePictureURL}) => {
    const cropperRef = useRef();
    const [crop, setCrop] = useState({ aspect: 1 / 1 });
    const isFirstRender = useRef(true);  //
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;  // Skip on the first run
            console.log("Crop", src);  // Load image only once
        }
    }, [src]);
    
    const handleCropComplete = () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const croppedCanvas = cropper.getCroppedCanvas();
      
            const originalFileType = src?.type || "image/png";
            const originalFileName = `${Date.now()}.png`;
            console.log("originalFileType",originalFileType)
            croppedCanvas.toBlob((blob) => {
                if (blob) {
                    const croppedFile = new File([blob], originalFileName, { type: originalFileType });
                    console.log("croppedFile",croppedFile)
                    const croppedFileUrl = URL.createObjectURL(croppedFile);

                    // Set the profile picture to the URL
                    setProfilePictureURL(croppedFileUrl);
                    setProfilePicture(croppedFile);
                    setShouldOpenCrop(false)
                }
            }, originalFileType);
          }
    };
    const handleCloseCrop = () => {
        setProfilePicture(src)
        setShouldOpenCrop(false)
    }

    return (
     
            <Dialog
                open
                fullScreen
                onClose={handleCloseCrop}
               
            >
                <DialogContent dividers={true}>
                    <Stack direction="row" sx={{ height: 'calc(100%)' }} spacing={0}>
                        <Box style={{ display: 'flex', flexDirection: 'column' }} marginTop="5" gap="10px">
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{ fontSize: "12px", fontWeight: "normal" }}
                                onClick={
                                    handleCropComplete
                                }
                            >
                                Done
                            </Button>
                       
                            <Button
                                variant="outlined"
                                sx={{ fontSize: "12px", fontWeight: "normal" }}
                                onClick={handleCloseCrop}
                            >
                                Close
                            </Button>
                        </Box>
                        <div style={{ padding: '16px', height: '100%', maxWidth: '100%', flexGrow: 1 }}>
                            <Cropper
                                ref={cropperRef}
                                src={src}
                                guides={true}
                                aspectRatio={1}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%' }}
                            />
                        </div>
                    </Stack>
                </DialogContent>
            </Dialog>

    );
};