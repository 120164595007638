import { TimeLineGraph } from "./TimeLineGraph"
import { Box, Stack, Typography, Backdrop, CircularProgress } from "@mui/material"
import React, { useState, useEffect } from 'react';
import ArrowUpIcon from '../../../assets/icons/ArrowUpIcon.svg'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon.svg'
import SettingsIcon from '../../../assets/icons/settings.svg'
import api from '../../../lib/axios.js';
import moment from 'moment';

export const MachineTimelineChart = ({ nodeData, title, timelineOrder, index, onUp, onDown, onSettingClick, showSetting = true }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [runTime, setRunTime] = useState("0 hrs 0 mins");
    const [downTime, setDownTime] = useState("0 hrs 0 mins");
    const [arrayData, setArrayData] = useState([]);
    const [arrayThreshold, setArrayThreshold] = useState([]);
    const [totalPercentage, setTotalPercentage] = useState(-1)

    useEffect(() => {
        if (nodeData != null) {
            getSensorData()
        }
    }, [nodeData?.objMachineRuntime]);

    const getSensorData = async () => {
        setIsLoading(true);
        var data = { nodeID: nodeData._id }
        let response = await api.post('/apis/NodeSensors/vibrationSensorMachineRunTime', data)
        var redData = response.data.data
        setArrayData(redData.arrayThreshold)
        setArrayThreshold(redData.arrayRunTime)
        setRunTime(redData.runtime)
        setDownTime(redData.downtime)

        const now = moment();
        const hours = now.hours();
        const minutes = now.minutes();
        const seconds = now.seconds();

        // Convert hours, minutes, and seconds into total seconds
        const currentSecond = (hours * 3600) + (minutes * 60) + seconds;
        const totalSecondsInDay = 24 * 60 * 60;
        const percentageWidth = (currentSecond / totalSecondsInDay) * 100;
        setTotalPercentage(percentageWidth)
        setIsLoading(false);
    }


    return (
        <>
            <Box
                sx={{
                    bgcolor: '#FFFFFF',
                    borderRadius: '3.5px',
                    border: '1px solid #E6E7E8',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: "10px"
                }}
            >
                <Stack direction={'row'} justifyContent={'space-between'} px={'15px'}>
                    <Stack direction={'row'} py={'18px'} alignItems={'center'} gap={'25px'}>
                        <Typography fontFamily={'Roboto'} fontWeight={500} fontSize={12} color={'#051219'} sx={{ width: '200px' }}>{title} </Typography>
                        <Box width={'1px'} height={'18px'} bgcolor={"#051219"} />
                        <Typography fontFamily={'Roboto'} fontWeight={500} fontSize={12} color={'#051219'}> {`Run Time: ${runTime}`} </Typography>
                        <Box width={'1px'} height={'18px'} bgcolor={"#051219"} />
                        <Typography fontFamily={'Roboto'} fontWeight={500} fontSize={12} color={'#051219'}>{`Downtime: ${downTime}`}</Typography>
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} gap="18px">
                        {showSetting && <Box
                            sx={{
                                height: '18px',
                                width: '18px',
                                cursor: 'pointer',
                            }}
                            component={'img'}
                            onClick={(e) => {
                                onSettingClick(true)
                            }}
                            src={SettingsIcon}
                        />}
                        <Box
                            sx={{
                                height: '13px',
                                width: '10px',
                                opacity: index === 0 ? 0.5 : 1,
                                cursor: index === 0 ? 'not-allowed' : 'pointer',
                            }}
                            component={'img'}
                            onClick={index > 0 ? () => onUp() : null}
                            src={ArrowUpIcon}
                        ></Box>
                        <Box
                            sx={{
                                height: '13px',
                                width: '10px',
                                opacity: (index === (timelineOrder.length - 1)) ? 0.5 : 1,
                                cursor: (index === (timelineOrder.length - 1)) ? 'not-allowed' : 'pointer',
                            }}
                            component={'img'}
                            onClick={index != (timelineOrder.length - 1) ? () => onDown() : null}
                            src={ArrowDownIcon}></Box>
                    </Stack>
                </Stack>
                <Box width={'100%'} height={'2px'} bgcolor={"#E6E7E8"} />

                {arrayData.length > 0 && <TimeLineGraph arrayData={arrayData} arrayData2={arrayThreshold} totalPercentage={totalPercentage} />}
                {/* {arrayData.length > 0 && <TimeLineGraph arrayData={arrayData} totalPercentage={totalPercentage} />} */}
            </Box>
            {/* <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </>
    )

}