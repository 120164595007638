import { Backdrop, Box, ButtonBase, CircularProgress, Stack, Typography, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { columns, rows } from './data/personnel.jsx';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios.js';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { DefaultSearchInput } from '../../ui/DefaultSearchInput/DefaultSearchInput.jsx';
import plusIconWhite from '../../assets/images/plusIconWhite.svg';

export const PersonnelListing = () => {
    const navigate = useNavigate();
    const [arrayPersonnelData, setArrayPersonnelData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arraySummeryGroups, setArraySummeryGroups] = useState([]);
    const arrayTopStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Active", totals: 0, color: "#36B368" },
        { id: "2", name: "Pressed", totals: 0, color: "#1E75FF" },
        { id: "3", name: "SOS", totals: 0, color: "#FF3941" },
        { id: "4", name: "Offline", totals: 0, color: "#979797" },
    ]
    useEffect(() => {
        getSummery()
        getData()
    }, []);

    const getSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Personnels/summery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySummeryGroups(resData.arrayGroup)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Personnels/sensors/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                var filterArray = applyFilters(resData, query)
                console.log(filterArray)
                setArrayPersonnelData(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function calculateDuration(startTime, endTime) {

        const start = new Date(startTime);
        const end = new Date(endTime);

        const durationMs = end - start;

        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);




        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }

    const applyFilters = (arrayDataAll, query) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['firstName', 'lastName'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (query === "") {
            return arrayDataAll
        }
        finalArray = arrayDataAll.filter((dataObj) => {
            // Combine firstName and lastName
            const fullName = `${dataObj.firstName} ${dataObj.lastName}`.toLowerCase();

            // Check if the full name contains the query
            return fullName.includes(query.toLowerCase());
        });
        return finalArray
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayPersonnelData, event.target.value)
        setFilteredArrayData(filterArray)
    };

    function onCheck(personnelData, isFavourite) {
        setIsLoading(true)
        var data = {
            sensorNumberID: personnelData.sensorNumberID,
            sensorNumber: personnelData.sensorNumber,
            isFavourite: isFavourite
        }
        api.put('/apis/Personnels/' + personnelData._id, data).then((res) => {

            getData()
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <Stack width={'100%'} mb={1}>
            <Stack px={3} pt={3}>
                <Stack direction={'row'} gap={5} alignItems={'center'} >
                    <Stack>
                        <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={'15px'} >
                            PERSONNEL
                        </Typography>
                        <Stack direction={'row'} alignItems={'center'} gap={5}>
                            {arrayTopStatusData.map((status) => (
                                <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                    <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                        {status.name}
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: status.color,
                                            mt: '16px'
                                        }}
                                    />
                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                        {/* {status.totals} */}
                                        {status.id == "0"
                                            ? arrayPersonnelData.length
                                            : status.id == "1"
                                                ? arrayPersonnelData.filter((data) => data && data.sensor.state == "1").length
                                                : status.id == "2"
                                                    ? arrayPersonnelData.filter((data) => data && data.sensor.buttonPressed == "1").length
                                                    : status.id == "3"
                                                        ? arrayPersonnelData.filter((data) => data && data.sensor.sos == "1").length
                                                        : status.id == "4"
                                                            ? arrayPersonnelData.filter((data) => data && data.sensor.state == "0").length
                                                            : status.totals
                                        }
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                    <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                    <Stack width={"100%"}>
                        <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                            {arraySummeryGroups.length > 0 ? "TOP METRICS" : ""}
                        </Typography>
                        {arraySummeryGroups.length > 0 ?
                            <Grid container xs={12} columnSpacing={"26px"}>
                                {arraySummeryGroups.map((obj) => {
                                    return (
                                        <Grid item xs={2.4} key={obj._id}>
                                            <Stack>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                    {obj.name + " " + `${obj.percentage}%`}
                                                </Typography>
                                                <LinearProgress
                                                    sx={{
                                                        width: '100%',
                                                        height: "6px",
                                                        flexShrink: 0,
                                                        borderRadius: '2px',
                                                        bgcolor: '#EFEFEF',
                                                        mt: "17px",
                                                        '.MuiLinearProgress-bar': {
                                                            borderRadius: '3px',
                                                            bgcolor: obj.color ? obj.color : "",
                                                        },
                                                    }}
                                                    variant={'determinate'}
                                                    value={obj.percentage ? obj.percentage : 0}
                                                />
                                                <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                    {obj.assignedAsset}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            : <Stack height="70px" />}
                    </Stack>
                </Stack>
                <Stack direction={'row'} gap={1} mt={"32px"}>
                    <DefaultSearchInput name={'search'} handleQueryChange={handleQueryChange} sx={{ minWidth: '900px' }} />

                    <ButtonBase
                        sx={{
                            height: 30,
                            pl: '10px',
                            pr: '21px',
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #E2E2EA',
                            bgcolor: '#1E75FF',
                            gap: 1,
                            borderRadius: '6px',
                        }}
                        onClick={() => navigate('/personnel-listing/manage', { state: { id: "" } })}
                    >
                        <Stack direction={'row'} alignItems={'center'} width={'100%'} gap={'11px'}>
                            <Box component={'img'} src={plusIconWhite} />
                            <Typography fontSize={12} color={'#FFFFFF'} fontWeight={400} fontFamily={'Roboto'}>
                                Create
                            </Typography>
                        </Stack>
                    </ButtonBase>
                </Stack>
            </Stack>
            <Stack flex={1} borderTop={'1px solid #E1E3E8'} direction={'row'} mt={'18px'}>
                <DefaultDataGrid rows={filteredArrayData} columns={columns(calculateDuration, onCheck)} onRowClick={(e) => navigate(`/personnel-listing/detail`, { state: { id: e.row._id } })} sx={{
                    '.MuiDataGrid-columnHeaders': {
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: 12,
                        overflow: 'visible',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 500,
                        color: '#615D5D',
                    },
                }} />
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
};
