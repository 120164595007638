import { ButtonBase, Stack, Typography } from '@mui/material';
import { Tag } from '../../../ui/Tag/index.js';
import moment from 'moment';

export const recipientsColoumn = (handleRemoveRecipients) => [
    {
        field: 'recipient',
        headerName: 'Recipients',
        flex: 1,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row && value.row.recipient ? value.row.recipient : '-'}
            </Typography>
        ),
    },
    {
        field: 'assignRule',
        headerName: 'Rule Action',
        width: 200,
        renderCell: (params) => (
            <Stack direction={'row'} alignItems={'center'} width={'100%'} gap={1} justifyContent={'flex-end'} pr={3}>
                <ButtonBase
                    sx={{
                        height: 30,
                        px: 2,
                        py: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        bgcolor: '#151D26',
                        borderRadius: '6px',
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleRemoveRecipients(params.row.recipient)
                    }}
                >
                    <Typography fontSize={14} color={'common.white'}>
                        Remove
                    </Typography>
                </ButtonBase>
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

export const trackersRows = [
    {
        id: 1,
        deviceSerialNumber: '112211',
        assignedAsset: 'None',
        status: {
            text: 'Available',
            borderColor: 'green.main',
        },
        type: 'YabbyEdge4-G',
        role: {
            text: 'None',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        billing: 'None',
        timestamp: '10 minutes ago',
    },
    {
        id: 2,
        deviceSerialNumber: '113344',
        assignedAsset: 'None',
        status: {
            text: 'Available',
            borderColor: 'green.main',
        },
        type: 'YabbyEdge4-G',
        role: {
            text: 'None',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        billing: 'None',
        timestamp: '10 minutes ago',
    },
];

function calculateDuration(startTime, endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Calculate the duration in milliseconds
    const durationMs = end - start;

    // Convert durationMs to days, hours, minutes, and seconds
    const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
    const seconds = Math.floor((durationMs / 1000) % 60);
    // Build the duration string
    let durationString = "";
    if (days > 0) {
        durationString += days + (days === 1 ? " Day " : " Days ");
    }
    if (hours > 0) {
        durationString += hours + (hours === 1 ? " hr " : " hrs ");
    }
    if (minutes > 0) {
        durationString += minutes + (minutes === 1 ? " min " : " mins ");
    }
    if (durationString.trim() === "") {
        durationString = "Just Now";
    }
    return durationString.trim();
}
