import { Box, Stack, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Tag } from '../../ui/Tag/index.js';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from '../../components/SearchInput/index.js';
import { useState } from 'react';
import moment from 'moment';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

export const PersonnelItem = ({ id, title, temp, deviceID, light, battery, date }) => {
    const navigate = useNavigate();
    function calculateDuration(startTime, endTime) {

        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);




        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }
    return (
        <Box sx={{ position: 'relative' }}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                py={'9px'}
                pl={"16px"}
                pr={2.5}
                borderBottom={'2px dashed #E1E3E8'}
                sx={{
                    cursor: 'pointer',
                }}
                onClick={() => navigate(`/personnel-listing/detail`, { state: { id: id, isFromMap: true } })}
            >
                <Stack gap={1}>
                    {/* <SearchInput
                    name={'search'}
                    // handleQueryChange={handleQueryChange} 
                    sx={{ minWidth: '200px' }} /> */}
                    <Typography fontWeight={500} fontSize={14} color={"#151D26"} whiteSpace={'nowrap'} fontFamily={'Roboto'}>
                        {title}
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Box
                            sx={{
                                px: '12px',
                                border: '1px solid #E1E3E8',
                                height: '20px',
                                borderRadius: '5px',
                                bgcolor: parseInt(temp) <= 50 ? "#FF3941" : parseInt(temp) <= 60 ? "#FFAD2C" : "#36B368",
                                alignContent: 'center'
                            }}
                        >
                            <Typography whiteSpace={'nowrap'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#FFFFFF'} textAlign={'center'}>
                                {temp != null && temp != "" && temp != "-" ? (parseFloat(temp).toFixed(1) + " °F") : "-"}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                ml: '5px',
                                px: '12px',
                                height: '20px',
                                borderRadius: '5px',
                                bgcolor: '#F6F7FA',
                                alignContent: 'center'
                            }}
                        >
                            <Typography whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#151D26'} textAlign={'center'}>
                                {battery != null && battery != "" && battery != "-" ? `Batt.${parseFloat(battery).toFixed(1)}%` : "-"}
                            </Typography>
                        </Box>
                        {/* {light != null && light != "" && light != "-" ? `💡 ${parseInt(light)}%` : "-"} */}
                        <Box
                            sx={{
                                ml: '5px',
                                px: '12px',
                                height: '20px',
                                borderRadius: '5px',
                                bgcolor: '#F6F7FA',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                whiteSpace={'nowrap'}
                                textOverflow={'ellipsis'}
                                overflow={'hidden'}
                                fontSize={10}
                                fontWeight={400}
                                fontFamily={'Roboto'}
                                color={'#151D26'}
                                display="flex"
                                alignItems="center"
                            >
                                {light != null && light !== "" && light !== "-" ? (
                                    <>
                                        <LightbulbIcon sx={{ fontSize: 12, mr: 0.5, verticalAlign: 'middle' }} />
                                        {`${parseInt(light)}%`}
                                    </>
                                ) : (
                                    "-"
                                )}
                            </Typography>
                        </Box>


                        {/* <Typography whiteSpace={'nowrap'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#151D26'} ml={'20px'}>
                            {light ? `${parseInt(light)}%` : "-"}
                        </Typography> */}

                        <Typography whiteSpace={'nowrap'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#151D26'} ml={'20px'}>
                            {date != "" ? calculateDuration(date, moment().format("MM/DD/YY hh:mm a")) : "-"}
                        </Typography>
                    </Stack>
                </Stack>
                <Box sx={{ position: "absolute", top: 20, bottom: 20, right: "15px" }}>
                    <ExpandMore
                        sx={{
                            transform: 'rotate(-90deg) translateY(5px)',
                            flexShrink: 0,
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    );
};
