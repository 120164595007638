import { Stack, TextField, Box } from '@mui/material';
import { DefaultIconButton } from '../DefaultIconButton';
import { SearchIcon } from '../../assets/icons/SearchIcon.jsx';
import settingSliderIcon from '../../assets/images/settingSliderIcon.svg';


export const DefaultSearchInput = ({ sx, name, ...props }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <Stack
            direction={'row'}
            sx={{
                bgcolor: '#F6F8FA',
                border: '1px solid #D5D5D545',
                borderRadius: '5px',
                minWidth: '250px',
                overflow: 'hidden',
                height: '30px',
                ...sx,
            }}
        // component={'form'}
        // onSubmit={handleSubmit}
        >
            <DefaultIconButton
                icon={<SearchIcon />}
                type='submit'
                size={'17px'}
                sx={{
                    width: 45,
                    borderRadius: 0,
                    backgroundColor: '#f8f8fa',
                    color: 'primary.main',

                    '&:hover': {
                        backgroundColor: '#f8f8fa',
                    },
                }}
            />
            <Box position={'relative'} width={'100%'}>
                <TextField
                    variant='standard'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={props.handleQueryChange}
                    placeholder={'Search...'}
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                        borderRadius: '6px',
                        '.MuiFormLabel-root': {
                            fontSize: 12,
                            transform: 'unset',
                            color: '#A8A8A7',
                            position: 'static',
                            lineHeight: '1.5',
                            '&.Mui-disabled': {
                                color: 'primary.main',
                            },
                        },
                        '.MuiInput-input': {
                            fontSize: 12,
                            height: 30,
                            // py: 0,
                            // pr: 2,
                            // pl: 2,
                            // border: '1px solid #E2E2EA',
                            // borderRadius: '6px',
                            boxSizing: 'border-box',
                        },
                        '.MuiInputBase-root': {
                            mt: 0,
                            py: 0,
                            '&::before': {
                                border: 'none',
                            },
                            '&.Mui-disabled': {
                                '&::before': {
                                    display: 'none',
                                },
                            },
                            height: '30px',
                            // pr: 2,
                            pt: '1px',
                            '&::after': {
                                bottom: 1.5,
                            },
                        },
                        '.MuiInputBase-input': {
                            fontSize: 12,
                            bgcolor: '#F6F8FA',
                            borderRight: 0,
                        },
                        ...sx,
                    }}
                    {...props}
                />
            </Box>
            <Stack alignItems={'center'} justifyContent={'center'} mr={'7px'}>
                <Box component={'img'} src={settingSliderIcon} sx={{ width: '20px', height: '16px' }}></Box>
            </Stack>
        </Stack>
    );
};
