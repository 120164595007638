import { Box, Checkbox, LinearProgress, Stack, Typography } from '@mui/material';
import { ColorStatus } from '../../../ui/ColorStatus/index.js';
import { ArrowForwardIos } from '@mui/icons-material';
import { Tag } from '../../../ui/Tag/index.js';
import moment from 'moment';

export const columns = (calculateDuration, onCheck) => [
    {
        field: 'nodeName',
        headerName: 'Node',
        width: 180,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{value ? value : "-"}</Typography>
        )
    },
    // {
    //     field: 'sensorNumber',
    //     headerName: 'Sensor ID',
    //     width: 130,
    //     renderCell: (value) => {
    //         const sensorNumber = value.row.sensorNumber ? value.row.sensorNumber : "-";
    //         const displayText = sensorNumber.length > 10 ? sensorNumber.slice(-10) : sensorNumber;
    //         return (

    //             <Typography fontSize={12}>{displayText}</Typography>

    //         )
    //     },
    // },
    {
        field: 'state',
        headerName: 'Status',
        width: 130,
        renderCell: (value) => {
            var status = value.row.sensor && value.row.sensor.state == "1" ? "Active" : "Offline"
            var statusColor = value.row.sensor && value.row.sensor.state == "1" ? "#2BD8A0" : "#979797"
            var borderColor = value.row.sensor && value.row.sensor.state == "1" ? "#2BD8A0" : "#E2E2EA"

            return (
                <Box
                    sx={{
                        width: '100px',
                        border: '1px solid',
                        borderColor: borderColor,
                        borderRadius: '20px',
                        height: '30px',
                        alignContent: 'center',
                    }}
                >
                    <Stack alignItems={'center'} width={'100%'}>
                        <Typography fontSize={12} >{status}</Typography>
                        {/* {value.row.isEvent == "1" && <Box
                            sx={{
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                backgroundColor: "#1E75FF",
                                ml: '10px',
                            }}
                        />} */}
                    </Stack>
                </Box>
            );
        },
    },
    {
        field: 'groups',
        headerName: 'Groups',
        flex: 1,
        renderCell: (params) => {
            var arrayGroup = params.row.groupData
            return (arrayGroup != null && arrayGroup.length > 0 ? <Stack direction={'row'} gap={1}>
                {arrayGroup.slice(0, 2).map((group, index) => (
                    <Box
                        key={group._id}
                        sx={{
                            px: 2,
                            border: '1px solid #E2E2EA',
                            borderRadius: '20px',
                            bgcolor: "#F7F7F7",
                            height: '30px',
                        }}
                    >
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            gap={2}
                            height="100%"
                        >
                            <Typography
                                color={'#44444F'}
                                fontFamily={'Roboto'}
                                fontWeight={400}
                                fontSize={12}
                            >
                                {group.name}
                            </Typography>
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: group.color,
                                }}
                            />
                        </Stack>
                    </Box>
                ))}
                {arrayGroup.length > 2 &&
                    <Box sx={{
                        px: 2,
                        border: '1px solid #E2E2EA',
                        borderRadius: '20px',
                        height: '30px',
                        alignContent: 'center',
                        width: '54px'
                    }}

                    >
                        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                            <Typography
                                color={'#707070'}
                                fontFamily={'Roboto'}
                                fontWeight={'Regular'}
                                fontSize={12}
                            >
                                {`${arrayGroup.length - 2}+`}
                            </Typography>
                            <ArrowForwardIos
                                sx={{
                                    color: '#151D26',
                                    width: 13,
                                    height: 13,
                                }}
                            />
                        </Stack>

                    </Box>
                }
            </Stack> : <Typography fontSize={12}> - </Typography>)
        },
    },

    {
        field: 'health',
        headerName: 'Health',
        width: 50,
        renderCell: ({ value }) => {
            return (
                <Typography fontSize={12}>{value ? `${value} %` : "-"}</Typography>
            )
        },
    },
    {
        field: 'healths',
        headerName: '',
        width: 100,
        renderCell: (params) => {
            return (
                <LinearProgress
                    sx={{
                        height: '6px',
                        // minWidth: '76px',
                        width: '76px',
                        bgcolor: '#F2F2F2',
                        '.MuiLinearProgress-bar': {
                            bgcolor: '#27B270',
                        },
                    }}
                    variant={'determinate'}
                    value={params.row.health}
                />
            )
        },
    },
    {
        field: 'events/anomalies',
        headerName: 'Events/Anomalies',
        width: 180,
        renderCell: (value) => {
            return (value.row.isEvent == "1" ? <Stack direction={'row'} gap={1}>
                <Box
                    sx={{
                        p: "2px",
                        borderRadius: '20px',
                        bgcolor: "#3333CC4F",
                        height: '30px',
                        alignContent: 'center',
                    }}
                >
                    <Typography
                        height={"26px"}
                        width={"60px"}
                        borderRadius='20px'
                        backgroundColor="#3333CC"
                        color={'#FFFFFF'}
                        fontFamily={'Roboto'}
                        fontWeight={400}
                        fontSize={12}
                        alignContent="center"
                        textAlign={'center'}
                    >
                        Event
                    </Typography>
                </Box>
                {/* <Box
                    sx={{
                        p: "2px",
                        borderRadius: '20px',
                        bgcolor: "#1E75FF4F",
                        height: '30px',
                        alignContent: 'center',
                    }}
                >
                    <Typography
                        height={"26px"}
                        width={"60px"}
                        borderRadius='20px'
                        backgroundColor="#1E75FF"
                        color={'#FFFFFF'}
                        fontFamily={'Roboto'}
                        fontWeight={400}
                        fontSize={12}
                        alignContent="center"
                        textAlign={'center'}
                    >
                        Anomaly
                    </Typography>
                </Box> */}
            </Stack> : <Typography fontSize={12}>None</Typography>
            )
        },
    },
    {
        field: 'battery',
        headerName: 'Battery',
        width: 120,
        renderCell: (params) => {
            const battery = params.row.sensor && params.row.sensor.battery ? parseFloat(params.row.sensor.battery) : null
            const product = params.row.sensor && params.row.sensor.product ? params.row.sensor.product : null
            var formatedBattery = battery ? `${battery.toFixed(1)}${product ? product === "4" ? '%' : 'v' : ""}` : "-"
            return (
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'55px'}>
                    <Typography fontSize={12}>{formatedBattery}</Typography>
                    {params.row.sensor && params.row.sensor.battery && battery < 3.5 && <Box
                        sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            backgroundColor: "#FF3941",
                            ml: '10px',
                        }}
                    />}
                </Stack>
            )
        },
    },
    {
        field: 'timestamp',
        headerName: 'Last Update',
        width: 150,
        renderCell: (params) => {
            const date = params.row.sensor && params.row.sensor.timestamp ? params.row.sensor.timestamp : null
            var formatedDate = date != null ? calculateDuration(date, moment().format("MM/DD/YY hh:mm a")) : "-"
            return (
                <Typography fontSize={12}>{formatedDate}</Typography>
            )
        },
    },
    {
        field: 'selected',
        headerName: 'Watch',
        width: 100,
        renderCell: (params) => {
            return (
                <Stack alignItems={'flex-start'} width={'100%'} pr={5}>
                    <Checkbox
                        color="primary"
                        onChange={(e) => {
                            onCheck(params.row, e.target.checked ? "1" : "0");
                        }}
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                        checked={params.row.isFavourite == "1"}
                    />
                </Stack>
            )
        },
    },
];

export const arrayNodeStatus = [
    { id: "1", value: "Online" },
    { id: "2", value: "Offline" },
]