import React, { useEffect, useState } from 'react';
import { Backdrop, Box, ButtonBase, CircularProgress, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';

import { DefaultAvatar } from '../../ui/DefaultAvatar';

import styles from './index.module.scss';
import api from '../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { Title } from '../../ui/Title/Title.jsx';
import DefaultSelect from '../../ui/DefaultSelect/DefaultSelect.jsx';
import { arrayRole, arrayStatus } from './Data/UserData.jsx';
import { useNavigate } from 'react-router';

export const EditProfile = () => {
    const navigate = useNavigate()
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDataId, setSelectedDataID] = useState(null);

    const formik = useFormik({
        initialValues: {
            submit: null,
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            company: '',
            selectedRole: null,
            selectedStatus: null,
        },
        validationSchema: Yup.object().shape(isAdmin ? {
            firstName: Yup.string().required('First name is required'),
            mobile: Yup.string().max(15).required('Mobile is required'),
        } : {
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('LastName is required'),
            mobile: Yup.string().max(15).required('Mobile is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    submit: null,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    mobile: values.mobile,
                    company: values.company,
                    selectedRole: values.selectedRole,
                    selectedStatus: values.selectedStatus
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apiCalling(values.firstName, values.lastName, values.mobile, values.email)
                console.log("On Submit data", data.data)
                if (data !== undefined && data.data.success) {
                    console.log("IF")
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('Profile updated', {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    setIsLoading(false)
                } else {
                    console.log("ELSE")
                    setStatus({ success: false });
                    setErrors({ submit: data.data.message });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                    setIsLoading(false)
                }
            } catch (err) {
                setIsLoading(false)
                console.error("err", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    })
    useEffect(() => {
        getData()
    }, []);

    async function apiCalling(firstName, lastName, mobile, email) {
        if (isAdmin) {
            localStorage.setItem("fullName", firstName)
            var adminData = {
                contact: firstName,
                telephone: mobile,
            }
            if (selectedDataId != "") {
                return await api.put('/apis/Companies/', adminData)
            }
        } else {
            localStorage.setItem("fullName", firstName + " " + lastName)
            var data = {
                firstName: firstName,
                lastName: lastName,
                mobile: mobile,
                email: email
            }
            if (selectedDataId != "") {
                return await api.put('/apis/CompanyUsers/' + selectedDataId, data)
            }

        }

    }

    function getData() {
        var isAdmin = localStorage.getItem("role") === "SuperAdmin"
        setIsLoading(true)
        setIsAdmin(isAdmin)
        console.log("isAdmin", isAdmin)
        if (isAdmin) {
            api.post('/apis/companies/byID').then((res) => {
                if (res.data.success) {
                    var data = res.data.data
                    var selectedStatus = arrayStatus.filter(status => status.id == data.status)
                    if (selectedStatus.length > 0) {
                        selectedStatus = selectedStatus[0]
                    } else {
                        selectedStatus = null
                    }
                    console.log("got data", data)
                    setSelectedDataID(data._id)
                    var dataValues = {
                        submit: null,
                        firstName: data.contact,
                        mobile: data.telephone,
                        selectedRole: arrayRole.find(role => role.id == data.role),
                        selectedStatus: selectedStatus,
                        email: data.email
                    }
                    formik.setValues(dataValues)
                    setIsLoading(false)
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            api.post('/apis/CompanyUsers/byID', { id: localStorage.getItem("userID") }).then((res) => {
                if (res.data.success) {
                    var data = res.data.data
                    var selectedStatus = arrayStatus.filter(status => status.id == data.status)
                    if (selectedStatus.length > 0) {
                        selectedStatus = selectedStatus[0]
                    } else {
                        selectedStatus = null
                    }
                    console.log("got data", data)
                    setSelectedDataID(data._id)
                    var dataValues = {
                        submit: null,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        mobile: data.mobile,
                        company: data.company,
                        selectedRole: arrayRole.find(role => role.id == data.role),
                        selectedStatus: selectedStatus,
                        email: data.email
                    }
                    formik.setValues(dataValues)
                    setIsLoading(false)
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
    return (
        <>
            <FormikProvider value={formik}>
                <Box
                    sx={{
                        borderLeft: '1px solid black',
                        borderColor: 'grey.light',
                        backgroundColor: 'common.white',
                    }}
                    className={styles.settingsWrap}
                    component={'form'}
                    onSubmit={formik.handleSubmit}
                >

                    <Stack p={'35px 50px'} spacing={4}>
                        <Title>Details</Title>
                        <Box>
                            <Title type={'small'} sx={{ mb: 3 }}>
                                Personal
                            </Title>
                            <Stack direction={'row'} spacing={4}>
                                <DefaultAvatar size={150} sx={{ fontSize: 23 }}>
                                    {selectedDataId != "" ? (formik.values.firstName != " " ? formik.values.firstName[0] : "" + (formik.values.lastName != "" ? formik.values.lastName[0] : "")) : ""}
                                </DefaultAvatar>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <DefaultInput
                                            label={'First Name'}
                                            placeholder={'E.g. Bob'}
                                            value={formik.values.firstName}
                                            name={"firstName"}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                            helperText={formik.touched.firstName && formik.errors.firstName}
                                        />
                                    </Grid>
                                    {!isAdmin && <Grid item xs={6}>
                                        <DefaultInput
                                            label={'Last Name'}
                                            placeholder={'E.g. example@oilservices.com'}
                                            value={formik.values.lastName}
                                            name={'lastName'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                            helperText={formik.touched.lastName && formik.errors.lastName}
                                        />
                                    </Grid>}
                                    {/* <Grid item xs={6}>
                                        <DefaultInput
                                            label={'Cellular'}
                                            placeholder={'000-000-0000'}
                                            value={formik.values.mobile}
                                            name={"mobile"}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                                            helperText={formik.touched.mobile && formik.errors.mobile}
                                        />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <DefaultSelect
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    height: 40,
                                                },
                                            }}
                                            label={'Role'}
                                            value={formik.values.selectedRole}
                                            helperText={formik.touched.selectedRole && formik.errors.selectedRole}
                                            error={Boolean(formik.touched.selectedRole && formik.errors.selectedRole)}
                                            placeholder="Select role"
                                            disabled={!formik.values.isEnable}
                                        >
                                            {arrayRole.map((item, index) => (
                                                <MenuItem key={index} value={item}> {item.value} </MenuItem>
                                            ))}
                                        </DefaultSelect>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DefaultSelect
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    height: 40,
                                                },
                                            }}
                                            label={'Status'}
                                            value={formik.values.selectedStatus}
                                            onItemSelected={(e) => {
                                                formik.setFieldValue('selectedStatus', e.target.value)
                                            }}
                                            helperText={formik.touched.selectedStatus && formik.errors.selectedStatus}
                                            error={Boolean(formik.touched.selectedStatus && formik.errors.selectedStatus)}
                                            placeholder="Select status"
                                            disabled={!formik.values.isEnable}
                                        >
                                            {arrayStatus.map((item, index) => (
                                                <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                            ))}
                                        </DefaultSelect>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DefaultInput
                                            label={'Mobile'}
                                            placeholder={'E.g. example@oilservices.com'}
                                            value={formik.values.mobile}
                                            name={'mobile'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                                            helperText={formik.touched.mobile && formik.errors.mobile}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DefaultInput
                                            label={'Email'}
                                            placeholder={'E.g. example@oilservices.com'}
                                            value={formik.values.email}
                                            name={'email'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            disabled={!formik.values.isEnable}
                                            error={Boolean(formik.touched.email && formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                height: '1px',
                                                width: '100%',
                                                borderTop: '1px dashed #D5D5D5',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DefaultInput
                                            label={'Password'}
                                            placeholder={'E.g. ******************'}
                                            value={formik.values.password}
                                            type={'password'}
                                            name={'password'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.password && formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DefaultInput
                                            label={'Confirm Password'}
                                            placeholder={'E.g. ******************'}
                                            value={formik.values.confirmPassword}
                                            type={'password'}
                                            name={'confirmPassword'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                height: '1px',
                                                width: '100%',
                                                borderTop: '1px dashed #D5D5D5',
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Typography color={'#A8A8A7'} fontSize={12} mb={2}>
                                            Invitation Email
                                        </Typography>
                                        <ButtonBase
                                            sx={{
                                                py: 1,
                                                px: 3,
                                                bgcolor: '#EFEFEF',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <Typography fontSize={14} fontWeight={700}>
                                                Send
                                            </Typography>
                                        </ButtonBase>
                                    </Grid> */}
                                </Grid>
                            </Stack>
                        </Box>

                    </Stack>
                    <Stack
                        p={'35px 50px'}
                        direction={'row'}
                        justifyContent={'space-between'}
                        mt={'auto'}
                    ><Box> </Box>
                        <Stack direction={'row'} spacing={2}>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    bgcolor: '#EFEFEF',
                                    borderRadius: '5px',
                                }}
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                cancel
                            </ButtonBase>

                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    bgcolor: '#EFEFEF',
                                    borderRadius: '5px',
                                }}
                                disabled={formik.isSubmitting}
                                type="submit"
                            >
                                Update
                            </ButtonBase>
                        </Stack>
                    </Stack>
                </Box >
            </FormikProvider >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}