import { arrayChartType, arraySensor } from './Data/arrayData.jsx';
import { Datepicker } from '../../../ui/Datepicker/index.js';
import { Stack, Box, MenuItem, ButtonBase, Backdrop, CircularProgress, FormControlLabel, Checkbox, } from '@mui/material';
import { Filter } from '../../../ui/Filter/Filter.jsx';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import api from '../../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { DefaultChart } from './DefaultChart.jsx';
import { Groups } from '../Groups.jsx';
import searchIcon from '../../../assets/images/SearchIcon.svg';
import excelFile from '../../../assets/images/excelFile.svg';
import printIcon from '../../../assets/images/PrintIcon.svg';


export const Charts = ({ personnelData, sensorData, updatePersonnelData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arraySensorData, setArraySensorData] = useState([])
    const [arraySelectedSensor, setArraySelectedSensor] = useState([])
    const [selectedChart, setSelectedChart] = useState("1")
    const [chartStartDate, setChartStartDate] = useState(dayjs());
    const [chartEndDate, setChartEndDate] = useState(dayjs());

    useEffect(() => {
        if (personnelData != null) {
            getSensorData(chartStartDate, chartEndDate)
        }
    }, [personnelData._id]);

    function getSensorData(startDate, endDate) {
        setIsLoading(true);
        api.post('/apis/Personnels/getSensorData', { personnelID: personnelData._id, startDate: startDate.format('MM-DD-YYYY'), endDate: endDate.format('MM-DD-YYYY') }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                let arrayData = []
                resData.map((data) => {
                    let obj = {
                        "temperature": parseFloat(data.temperature),
                        "battery": parseFloat(data.battery),
                        "light": parseFloat(data.light),
                        "sos": parseFloat(data.sos),
                        "buttonPressed": parseFloat(data.buttonPressed),
                        "timestamp": data.createdAt,
                    }
                    arrayData.push(obj)
                })
                setArraySensorData(arrayData)
                if (personnelData != null) {
                    setArraySelectedSensor(personnelData.arrayChart ? personnelData.arrayChart : [])
                }
            }
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });

    }

    function handleSearchClick() {
        if (arraySelectedSensor.length > 0 && selectedChart != -1) {
            const isEndDateAfterStartDate = chartEndDate.isAfter(chartStartDate);
            const isStartDateEqualToEndDate = chartEndDate.isSame(chartStartDate, 'day');
            console.log("******************", isEndDateAfterStartDate, isStartDateEqualToEndDate);

            if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
                enqueueSnackbar("End date must be after start date", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            } else {
                getSensorData(chartStartDate, chartEndDate);
            }
        } else {
            enqueueSnackbar("Please select chart and sensor", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }

    const handleStartDateChange = (date) => {
        const isStartDateAfterEndDate = date.isAfter(chartEndDate);
        const isStartDateEqualToEndDate = date.isSame(chartEndDate, 'day');
        if (isStartDateAfterEndDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            setChartStartDate(date)
        } else {
            setChartStartDate(date)
        }
    };

    const handleEndDateChange = (date) => {
        const isEndDateAfterStartDate = date.isAfter(chartStartDate);
        const isStartDateEqualToEndDate = date.isSame(chartStartDate, 'day');
        if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("End date must be after start date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            setChartEndDate(date)
        } else {
            setChartEndDate(date)
        }
    };

    function updateAsset(arrayChart) {
        var data = {
            sensorNumberID: personnelData.sensorNumberID,
            sensorNumber: personnelData.sensorNumber,
            arrayChart: arrayChart
        }
        api.put('/apis/Personnels/' + personnelData._id, data).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                updatePersonnelData(data)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onCloseChartClick = (id) => {
        var groups = [...arraySelectedSensor]
        if (groups.includes(id)) {
            groups = groups.filter(obj => obj != id)
        } else {
            groups.push(id)
        }
        setArraySelectedSensor(groups)
        updateAsset(groups)
    }

    const onUpChartClick = (id, index) => {
        var groups = [...arraySelectedSensor]
        if (index > 0) {
            [groups[index - 1], groups[index]] = [groups[index], groups[index - 1]];
        }
        setArraySelectedSensor(groups)
        updateAsset(groups)
    }

    const ondownChartClick = (id, index) => {
        var groups = [...arraySelectedSensor]
        if (index != (arraySelectedSensor.length - 1)) {
            [groups[index + 1], groups[index]] = [groups[index], groups[index + 1]];
        }
        setArraySelectedSensor(groups)
        updateAsset(groups)
    }
    return (
        <Stack spacing={2} flex={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} gap={1.5}>
                    <Box maxWidth={200} width={'100%'} >
                        <Groups label="Select Sensors">
                            {arraySensor.length > 0 && <FormControlLabel control={<Checkbox
                                checked={arraySensor.length == arraySelectedSensor.length}
                                onChange={(e) => {
                                    var groups = [...arraySelectedSensor]
                                    if (arraySensor.length == groups.length) {
                                        groups = []
                                    } else {
                                        groups = arraySensor.map(data => data.id)
                                    }
                                    setArraySelectedSensor(groups)
                                    updateAsset(groups)
                                }} />}
                                label='All Sensors' />}
                            {arraySensor.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={<Checkbox
                                        checked={arraySelectedSensor.includes(item.id)}
                                        onChange={(e) => {
                                            var groups = [...arraySelectedSensor]
                                            if (groups.includes(item.id)) {
                                                groups = groups.filter(obj => obj != item.id)
                                            } else {
                                                groups.push(item.id)
                                            }
                                            setArraySelectedSensor(groups)
                                            updateAsset(groups)
                                        }}
                                    />}
                                    label={item.value} />
                            ))}
                        </Groups>
                    </Box>
                    <Box maxWidth={170} width={'100%'} >
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedChart(value)
                            }}
                            defaultValue={selectedChart}
                            placeholder="Select Chart Type"
                        >
                            {arrayChartType.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Datepicker
                        sx={{
                            width: '170px',
                        }}
                        initialDate={chartStartDate}
                        handleDateChange={handleStartDateChange}
                    ></Datepicker>

                    <Datepicker
                        sx={{
                            width: '170px',
                        }}
                        initialDate={chartEndDate}
                        handleDateChange={handleEndDateChange}
                    />

                    <Stack>
                        <ButtonBase
                            sx={{
                                height: 30,
                                width: 30,
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}

                            onClick={() => {
                                handleSearchClick()
                            }}
                        >
                            <Box component={'img'} src={searchIcon} sx={{ width: "20px", height: "20px" }} />
                        </ButtonBase>
                    </Stack>
                </Stack>
                <Stack direction={'row'} gap={1.5}>
                    <ButtonBase
                        sx={{
                            height: 30,
                            width: 30,
                            px: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            border: '1px solid #E2E2EA',
                            bgcolor: '#F6F8FA',
                            borderRadius: '6px',
                        }}

                        onClick={() => {
                            // handleSearchClick()
                        }}
                    >
                        <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />
                    </ButtonBase>
                    <ButtonBase
                        sx={{
                            height: 30,
                            width: 30,
                            px: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            border: '1px solid #E2E2EA',
                            bgcolor: '#F6F8FA',
                            borderRadius: '6px',
                        }}

                        onClick={() => {
                            // handleSearchClick()
                        }}
                    >
                        <Box component={'img'} src={printIcon} sx={{ width: "22px", height: "22px" }} />
                    </ButtonBase>
                </Stack>
            </Stack>
            <Box width={'100%'}
                sx={{
                    overflowY: 'auto',
                    height: 'calc(100vh - 235px)',
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '4px',
                        backgroundColor: '#bdbdbd',
                    },
                }}>
                {arraySelectedSensor.map((id, index) => {
                    return <DefaultChart arrayData={arraySensorData} arraySelectedSensor={arraySelectedSensor} arraySensorDataType={arraySensor} sensorData={sensorData} selectedChart={selectedChart} selectedSensor={id} onUpChartClick={onUpChartClick} ondownChartClick={ondownChartClick} onCloseChartClick={onCloseChartClick} chartId={id} key={id} index={index} />
                })}
            </Box>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    )
}