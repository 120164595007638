import { Box, Stack, Typography } from '@mui/material';
import { ColorStatus } from '../../../ui/ColorStatus/index.js';
import { ArrowForwardIos, Flare } from '@mui/icons-material';
import { Tag } from '../../../ui/Tag/index.js';
import moment from 'moment';
import { arrayBillingCycle, arrayStatus } from './arrayData.jsx';

export const columns = [
    {
        field: 'invoice',
        headerName: 'Invoice',
        width: 130,
        renderCell: ({ value }) => (
            <Typography fontSize={12} fontWeight={400} fontFamily={"Roboto"} color={'#262B40'}>
                {value ? value : "-"}
            </Typography>
        ),
    },
    {
        field: 'invoiceDate', headerName: 'Invoice Date', width: 150,
        renderCell: ({ value }) => (
            <Typography fontSize={12} fontWeight={400} fontFamily={"Roboto"} color={'#262B40'}>
                {value ? moment(value).format("MM/DD/YYYY") : "-"}
            </Typography>
        ),
    },
    {
        field: 'amount', headerName: 'Amount', width: 120,
        valueGetter: (params) => params.row.amount != null && params.row.amount != "" ? parseFloat(params.row.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00",
        renderCell: (params) => (
            <Typography fontSize={12} fontWeight={400} fontFamily={"Roboto"} color={'#262B40'}>
                {params.value ? `$${params.value}` : "-"}
            </Typography>
        ),
    },
    {
        field: 'devices', headerName: 'Devices', width: 120,
        renderCell: ({ value }) => (
            <Typography fontSize={12} fontWeight={400} fontFamily={"Roboto"} color={'#262B40'}>
                {value.length ? value.length : "-"}
            </Typography>
        ),
    },
    {
        field: 'dueDate', headerName: 'Due Date', width: 140,
        renderCell: ({ value }) => (
            <Typography fontSize={12} fontWeight={400} fontFamily={"Roboto"} color={'#262B40'}>
                {value ? moment(value).format("MM/DD/YYYY") : "-"}
            </Typography>
        ),
    },
    {
        field: 'billingCycle', headerName: 'Billing Cycle', width: 150,
        renderCell: ({ value }) => (
            // <Typography fontSize={12} >
            //     {value ? value == "1" ? "Monthly" : "Yearly" : "-"}
            // </Typography>
            <Typography fontSize={12} >
                {value ? arrayBillingCycle.find((cycle) => cycle.id == value)?.value : "-"}
            </Typography>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        renderCell: ({ value }) => {
            // <Box
            //     sx={{
            //         px: 2,
            //         border: '1px solid #E2E2EA',
            //         borderRadius: '5px',
            //         height: '30px',
            //         width: '156px',

            //     }}
            // >
            //     <Stack
            //         direction={'row'}
            //         justifyContent={'space-between'}
            //         alignItems={'center'}
            //         height="100%"
            //     >
            //         <Typography
            //             color={'#44444F'}
            //             fontFamily={'Roboto'}
            //             fontWeight={'Regular'}
            //             fontSize={14}
            //         >
            //             {/* {value == "2" ? "Paid" : "Past Due"} */}
            //             {value ? arrayStatus.find((status) => status.id == value)?.value : "-"}
            //         </Typography>
            //         <Box
            //             sx={{
            //                 width: 8,
            //                 height: 8,
            //                 borderRadius: '50%',
            //                 backgroundColor: value ? arrayStatus.find((status) => status.id == value)?.color : "#32D9A2",
            //             }}
            //         />

            //     </Stack>
            // </Box>
            return (
                <Box
                    sx={{
                        pl: 2,
                        pr: 2,
                        border: '1px solid',
                        borderColor: value ? arrayStatus.find((status) => status.id == value)?.color : "#36B368",
                        borderRadius: '20px',
                        height: '30px',
                        alignContent: 'center'
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} >
                        <Typography fontSize={12}>{value ? arrayStatus.find((status) => status.id == value)?.value : "-"}</Typography>
                    </Stack>
                </Box >
            )
        }
    },
    {
        field: 'arrowIcon',
        headerName: '',
        flex: 1,
        renderCell: () => (
            <Stack direction={'row'} justifyContent={'flex-end'} pr={5} alignItems={'center'} width={'100%'} gap={1}>
                <ArrowForwardIos
                    sx={{
                        color: 'Black',
                        width: 18,
                        height: 18,
                    }}
                />
                <Typography color={'Black'} fontSize={14}>
                    Details
                </Typography>
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

