import { Stack, Typography, Box } from '@mui/material';
import { getPriorityColor } from '../../../../core/functions/statusHelpers.js';
import { ArrowForwardIos } from '@mui/icons-material';
import moment from 'moment';
import { arrayDataType } from './configArrayData.jsx';

export const columns = [
    {
        field: 'temperature',
        headerName: 'Temp.',
        width: 120,
        // flex: 1,
        renderCell: ({ value }) => {
            const truncatValue = value.toFixed(1)
            return (
                <Typography fontSize={12}>
                    {truncatValue ? `${truncatValue} °F` : '-'}
                </Typography>
            )
        },
    },
    {
        field: 'battery',
        headerName: 'Battery',
        width: 120,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.battery ? value.row.battery : '-'}
            </Typography>
        ),
    },
    {
        field: 'speed',
        headerName: 'Speed',
        width: 120,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.speed ? value.row.speed : '-'}
            </Typography>
        ),
    },
    {
        field: 'altitude',
        headerName: 'Altitude',
        width: 120,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.altitude ? `${value.row.altitude}(m)` : '-'}
            </Typography>
        ),
    },
    {
        field: 'cellSignal',
        headerName: 'Cell Signal',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.cellSignal ? `${value.row.cellSignal}%` : '-'}
            </Typography>
        ),
    },
    {
        field: 'latitude',
        headerName: 'Latitude',
        width: 180,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.latitude ? value.row.latitude : '-'}
            </Typography>
        ),
    },
    {
        field: 'longitude',
        headerName: 'Longtitude',
        width: 180,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.longitude ? value.row.longitude : '-'}
            </Typography>
        ),
    },
    {
        field: 'timestamp',
        headerName: 'Timestamp',
        flex: 1,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.timestamp ? moment(value.row.timestamp).format('MM/DD/YY hh:mm a') : '-'}
            </Typography>
        ),
    },
    // {
    //     field: 'arrowIcon',
    //     headerName: '',
    //     flex: 1,
    //     renderCell: () => (
    //         <Stack direction={'row'} justifyContent={'flex-end'} pr={5} alignItems={'center'} width={'100%'} gap={1}>
    //             <ArrowForwardIos
    //                 sx={{
    //                     color: 'secondary.main',
    //                     width: 18,
    //                     height: 18,
    //                 }}
    //             />
    //             <Typography color={'secondary.main'} fontSize={14}>
    //                 Details
    //             </Typography>
    //         </Stack>
    //     ),
    //     sortable: false,
    //     disableColumnMenu: true,
    // },
]

export const columnsVibration = [
    {
        field: 'temperature',
        headerName: 'Temp.',
        width: 80,
        // flex: 1,
        renderCell: ({ value }) => {
            const truncatValue = value.toFixed(1)
            return (
                <Typography fontSize={12}>
                    {truncatValue ? `${truncatValue} °F` : '-'}
                </Typography>
            )
        },
    },
    {
        field: 'battery',
        headerName: 'Battery',
        width: 55,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.battery ? value.row.battery : '-'}
            </Typography>
        ),
    },
    {
        field: 'rssi',
        headerName: 'Rssi',
        width: 45,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.rssi ? value.row.rssi : '-'}
            </Typography>
        ),
    },
    {
        field: 'x_rms_ACC_G',
        headerName: 'Rms_ACC_G',
        width: 125,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.xRmsACCG !== 0 ? parseFloat(value.row.xRmsACCG).toFixed(2) : value.row.xRmsACCG},&nbsp;&nbsp;
                {value.row.yRmsACCG !== 0 ? parseFloat(value.row.yRmsACCG).toFixed(2) : value.row.yRmsACCG},&nbsp;&nbsp;
                {value.row.zRmsACCG !== 0 ? parseFloat(value.row.zRmsACCG).toFixed(2) : value.row.zRmsACCG}
            </Typography>
        ),
    },
    {
        field: 'x_max_ACC_G',
        headerName: 'Max_ACC_G',
        width: 125,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.xMaxACCG !== 0 ? parseFloat(value.row.xMaxACCG).toFixed(2) : value.row.xMaxACCG},&nbsp;&nbsp;
                {value.row.yMaxACCG !== 0 ? parseFloat(value.row.yMaxACCG).toFixed(2) : value.row.yMaxACCG},&nbsp;&nbsp;
                {value.row.zMaxACCG !== 0 ? parseFloat(value.row.zMaxACCG).toFixed(2) : value.row.zMaxACCG}
            </Typography>
        ),
    },
    {
        field: 'x_velocity_mm_sec',
        headerName: 'Velocity_mm_sec',
        width: 125,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.xVelocityMmSec !== 0 ? parseFloat(value.row.xVelocityMmSec).toFixed(2) : value.row.xVelocityMmSec},&nbsp;&nbsp;
                {value.row.yVelocityMmSec !== 0 ? parseFloat(value.row.yVelocityMmSec).toFixed(2) : value.row.yVelocityMmSec},&nbsp;&nbsp;
                {value.row.zVelocityMmSec !== 0 ? parseFloat(value.row.zVelocityMmSec).toFixed(2) : value.row.zVelocityMmSec}
            </Typography>
        ),
    },
    {
        field: 'x_displacement_mm',
        headerName: 'Displacement_mm',
        width: 125,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.xDisplacementMm !== 0 ? parseFloat(value.row.xDisplacementMm).toFixed(2) : value.row.xDisplacementMm},&nbsp;&nbsp;
                {value.row.yDisplacementMm !== 0 ? parseFloat(value.row.yDisplacementMm).toFixed(2) : value.row.yDisplacementMm},&nbsp;&nbsp;
                {value.row.zDisplacementMm !== 0 ? parseFloat(value.row.zDisplacementMm).toFixed(2) : value.row.zDisplacementMm}
            </Typography>
        ),
    },
    {
        field: 'x_peak_one_Hz',
        headerName: 'Peak_one_Hz',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.xPeakOneHz !== 0 ? parseFloat(value.row.xPeakOneHz).toFixed(2) : value.row.xPeakOneHz},&nbsp;&nbsp;
                {value.row.yPeakOneHz !== 0 ? parseFloat(value.row.yPeakOneHz).toFixed(2) : value.row.yPeakOneHz},&nbsp;&nbsp;
                {value.row.zPeakOneHz !== 0 ? parseFloat(value.row.zPeakOneHz).toFixed(2) : value.row.zPeakOneHz}
            </Typography>
        ),
    },
    {
        field: 'x_peak_two_Hz',
        headerName: 'Peak_two_Hz',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.xPeakTwoHz !== 0 ? parseFloat(value.row.xPeakTwoHz).toFixed(2) : value.row.xPeakTwoHz},&nbsp;&nbsp;
                {value.row.yPeakTwoHz !== 0 ? parseFloat(value.row.yPeakTwoHz).toFixed(2) : value.row.yPeakTwoHz},&nbsp;&nbsp;
                {value.row.zPeakTwoHz !== 0 ? parseFloat(value.row.zPeakTwoHz).toFixed(2) : value.row.zPeakTwoHz}
            </Typography>
        ),
    },
    {
        field: 'x_peak_three_Hz',
        headerName: 'Peak_three_Hz',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.xPeakThreeHz !== 0 ? parseFloat(value.row.xPeakThreeHz).toFixed(2) : value.row.xPeakThreeHz},&nbsp;&nbsp;
                {value.row.yPeakThreeHz !== 0 ? parseFloat(value.row.yPeakThreeHz).toFixed(2) : value.row.yPeakThreeHz},&nbsp;&nbsp;
                {value.row.zPeakThreeHz !== 0 ? parseFloat(value.row.zPeakThreeHz).toFixed(2) : value.row.zPeakThreeHz}
            </Typography>
        ),
    },
    {
        field: 'received',
        headerName: 'Timestamp',
        flex: 1,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.received ? moment(value.row.received).format('MM/DD/YY hh:mm a') : '-'}
            </Typography>
        ),
    },
]

export const configColumns = [
    {
        field: 'name',
        headerName: 'Input Name',
        width: 200,
        // flex: 1,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.name ? value.row.name : '-'}
            </Typography>
        ),
    },
    {
        field: 'pin',
        headerName: 'Sensor Pin',
        width: 200,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.pin ? value.row.pin : '-'}
            </Typography>
        ),
    },
    {
        field: 'logic',
        headerName: 'Conversion Logic',
        width: 300,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.logic ? value.row.logic : '-'}
            </Typography>
        ),
    },
    {
        field: 'dataType',
        headerName: 'Data Type',
        width: 200,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.dataType ? arrayDataType.find((dataType) => dataType.id == value.row.dataType)?.name : '-'}
            </Typography>
        ),
    },
    {
        field: 'updatedAt',
        headerName: 'Timestamp',
        flex: 1,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.updatedAt ? moment(value.row.updatedAt).format('MM/DD/YY hh:mm a') : '-'}
            </Typography>
        ),
    },
    // {
    //     field: 'arrowIcon',
    //     headerName: '',
    //     flex: 1,
    //     renderCell: () => (
    //         <Stack direction={'row'} justifyContent={'flex-end'} pr={5} alignItems={'center'} width={'100%'} gap={1}>
    //             <ArrowForwardIos
    //                 sx={{
    //                     color: 'secondary.main',
    //                     width: 18,
    //                     height: 18,
    //                 }}
    //             />
    //             <Typography color={'secondary.main'} fontSize={14}>
    //                 Details
    //             </Typography>
    //         </Stack>
    //     ),
    //     sortable: false,
    //     disableColumnMenu: true,
    // },
]