import { Stack, Typography, Box } from '@mui/material';
import { getPriorityColor } from '../../../core/functions/statusHelpers.js';
import moment from 'moment';
import { arrayCondition, arrayMeasurment } from '../../../modules/RuleNew/data/arrayData.jsx';
export const eventsColumns = [
    {
        field: 'eventID',
        headerName: 'Event ID',
        width: 130,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.eventID ? value.row.eventID : '-'}
            </Typography>
        ),
    },
    {
        field: 'ruleName',
        headerName: 'Rule',
        width: 150,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>
                {value ? value : "-"}
            </Typography>
        ),
    },
    {
        field: 'eventText',
        headerName: 'Condition',
        width: 140,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.eventText ? value.row.eventText : '-'}
            </Typography>
        ),
    },
    {
        field: 'value',
        headerName: 'Value',
        width: 140,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{value ? value : "-"}</Typography>
        ),
    },
    {
        field: 'eventType',
        headerName: 'Measurement',
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{arrayMeasurment.find(obj => obj.id == value)?.value || "-"}</Typography>
        ),
    },

    {
        field: 'thresholdValue',
        headerName: 'Sensor Value',
        width: 150,
        renderCell: (params) => (
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <Typography fontSize={12}>{params.row.thresholdValue || "-"}</Typography>
                {params.row.thresholdValue && <Box
                    sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: getPriorityColor(params.row.thresholdValue == "0" ? "3" : params.row.thresholdValue),
                    }}
                />}
            </Stack>
        ),
    },
    {
        field: 'createdAt',
        headerName: 'Timestamp',
        width: 190,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{value ? moment(value).format("MM-DD-YYYY hh:mm a") : "-"}</Typography>
        )
    },
];
export const eventsColumns1 = [
    {
        field: 'eventID',
        headerName: 'Event ID',
        width: 130,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.eventID ? value.row.eventID : '-'}
            </Typography>
        ),
    },
    {
        field: 'ruleName',
        headerName: 'Rule',
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{value ? value : "-"}</Typography>
        ),
    },
    {
        field: 'sensorMesurement',
        headerName: 'Measurement',
        width: 150,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{value ? arrayMeasurment.find((measurement) => measurement.id == value)?.value : "-"}</Typography>
        ),
    },
    {
        field: 'condition',
        headerName: 'Condition',
        width: 200,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{value ? arrayCondition.find((condition) => condition.id == value)?.value : "-"}</Typography>

        ),
    },
    {
        field: 'value',
        headerName: 'Data Value',
        width: 130,
        renderCell: (params) => (
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <Typography fontSize={12}>
                    {params.row.value ? params.row.value : '-'}
                </Typography>
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: getPriorityColor(params.row.value == "0" ? "3" : params.row.value),
                    }}
                />
            </Stack>
        ),
    },
    {
        field: 'duration',
        headerName: 'Timestamp',
        flex: 1,
        renderCell: ({ value }) => {
            return (
                <Typography fontSize={12}>{value ? value : "-"}</Typography>
            )
        },
    },
];
