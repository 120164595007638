import { Box, ButtonBase, Stack, Typography, Backdrop, CircularProgress, Checkbox, FormControlLabel, Breadcrumbs, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { SearchInput } from '../../components/SearchInput/index.js';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Tag } from '../../ui/Tag/index.js';
import { Groups } from '../../modules/Asset/Groups.jsx';
import { tabs } from './data/tabs.js';
import { Tabs } from '../../ui/Tabs/index.js';
import { Status } from '../../modules/Asset/Status/Status.jsx';
import { LocationsMap } from '../../modules/Asset/LocationsMap/LocationsMap.jsx';
import { Trips } from '../../modules/Asset/Trips/Trips.jsx';
import { Events } from '../../modules/Asset/Events/Events.jsx';
import { Details } from '../../modules/Asset/Details/Details.jsx';
import { Device } from '../../modules/Asset/Device/Device.jsx';
import { FormikProvider, useFormik } from 'formik';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios';
import { trackersTabs } from '../../components/Map/data/tabs.js';
import { Charts } from './Status/Chart.jsx';
import { Add } from '@mui/icons-material';
import ChevronRightIcon from '../../assets/icons/ChevronRight.jsx';
import { Link as RouterLink } from 'react-router-dom';
import { TimeLine } from './Timeline/Timeline.jsx';
import { Movement } from './Trips/Movement.jsx';

export const Asset = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const isFromMaps = location.state != null && location.state.isFromMap != null ? location.state.isFromMap : false
    const [isLoading, setIsLoading] = useState(false);
    const [arrayGroup, setArrayGroup] = useState([]);
    const [assetData, setAssetData] = useState({});
    const [trackerData, setTrackerData] = useState({});
    const [secondaryTrackerData, setSecondaryTrackerData] = useState({});
    const [activeTrackerTab, setActiveTrackerTab] = useState(trackersTabs[0].value);
    const [addGeoFence, setAddGeoFence] = useState(false);
    const [addSharing, setAddSharing] = useState(false);
    const [activeTab, setActiveTab] = useState(location.state != null && location.state.activeTab != null ? location.state.activeTab : tabs[0].value);
    const formik = useFormik({
        initialValues: {
            fenceName: '',
            alerts: true,
            sendTo: '',
            device: '',
            minTemperature: '',
            maxTemperature: '',
            minSpeed: '',
            maxSpeed: '',
        },
        onSubmit: (values) => {
            console.log(values);
        },
        validateOnChange: false,
    });

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    const handleChangeTrackersTab = (value) => {
        console.log("Tracker Data Assest", value);
        setActiveTrackerTab(value);
        getData()
    };

    useEffect(() => {
        getGroupData()
        if (selectedDataId != "") {
            getData()
        }
    }, []);

    function getGroupData() {
        // setIsLoading(true)
        api.get('/apis/Groups/activeAsset').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayGroup(resData)
                // setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Assets/byId/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                var trackerData = data.tracker
                setAssetData(data)
                setTrackerData(trackerData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateData = (data) => {
        var trackerData = data.tracker
        setAssetData(data)
        setTrackerData(trackerData)
    }

    const updateAssetData = (data) => {
        setAssetData(data)
    }

    const apiCalling = async (groups) => {
        setIsLoading(true)
        var data = {
            groups: groups,
            serialNumberID: assetData.serialNumberID,
            serialNumber: assetData.serialNumber
        }
        api.put('/apis/Assets/' + selectedDataId, data).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                getData()
            }
        }).catch(function (error) {
            console.log(error);
        });

    }
    function onCheck(assetData, isFavourite) {
        setIsLoading(true)
        var data = {
            serialNumberID: assetData.serialNumberID,
            serialNumber: assetData.serialNumber,
            secondarySerialNumber: assetData.secondarySerialNumber ? assetData.secondarySerialNumber : "",
            secondarySerialNumberID: assetData.secondarySerialNumberID ? assetData.secondarySerialNumberID : "",
            isFavourite: isFavourite
        }
        api.put('/apis/Assets/' + assetData._id, data).then((res) => {
            getData()
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <FormikProvider value={formik}>
            <Stack sx={{ height: 'calc(100vh - 50px)', width: '100%' }}>
                <Stack pt={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} px={3}>
                        <Box
                        // onClick={() => navigate(-1)}
                        >
                            <Stack
                                display={'inline-flex'}
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    svg: {
                                        fill: '#A0A3A6',
                                    },
                                }}
                                gap={0.5}
                            >

                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={3}
                                >
                                    <Grid item>
                                        <Stack direction="row" alignItems="center" gap={0.5} >
                                            <ArrowBackIos
                                                onClick={() => {
                                                    if (location.state != null && location.state.isFromNotification != null && location.state.isFromNotification) {
                                                        navigate("/asset-listing");
                                                    } else {
                                                        navigate(-1);
                                                    }
                                                }}
                                                sx={{
                                                    fontSize: "small",
                                                    cursor: 'pointer',
                                                }}
                                            />
                                            <Breadcrumbs
                                                aria-label="breadcrumb"
                                                separator={<Typography fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6">/</Typography>}
                                            >
                                                {isFromMaps ?
                                                    <Typography
                                                        fontSize={15}
                                                        fontWeight={400}
                                                        fontFamily={'Roboto'}
                                                        color="#A0A3A6"
                                                    >
                                                        Maps
                                                    </Typography>
                                                    :
                                                    <Typography
                                                        fontSize={15}
                                                        fontWeight={400}
                                                        fontFamily={'Roboto'}
                                                        color="#A0A3A6"
                                                    >
                                                        Assets
                                                    </Typography>
                                                }
                                                {assetData != null && assetData.category && <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    {assetData != null && assetData.category ? assetData.category : "-"}
                                                </Typography>}
                                                {assetData != null && assetData.type && <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    {assetData != null && assetData.type ? assetData.type : "-"}
                                                </Typography>}
                                                <Typography fontSize={15} fontWeight={500} color={'#151D26'}>
                                                    {assetData.assetName || "-"}
                                                </Typography>
                                            </Breadcrumbs>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                        {/* <SearchInput /> */}
                    </Stack>

                    <Stack direction={'row'} justifyContent={'space-between'} gap={4} alignItems={'center'} px={3} mt={'15px'}>
                        <Stack direction={'row'} gap={6} alignItems={'center'}>
                            <Typography fontSize={20} fontWeight={500}>
                                {assetData.assetName || "-"}
                            </Typography>
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: assetData.tracker ? assetData.tracker.state != null && assetData.tracker.state == 1 ? "green.main" : "red.main" : "red.main",
                                }}
                            />
                        </Stack>

                        <Stack direction={'row'}>
                            <ButtonBase
                                sx={{
                                    px: '40px',
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#F6F8FA',
                                    borderRadius: '5px',
                                    height: '30px',
                                    mr: '10px'
                                }}
                                onClick={() => {
                                    navigate(`/asset-listing/manage`, { state: { id: selectedDataId } })
                                }}
                            >
                                <Typography fontSize={12} fontWeight={500} color={"#151D26"} fontFamily={'Roboto'}>Edit</Typography>
                            </ButtonBase>
                            <Groups label="Groups">
                                {arrayGroup.length > 0 && <FormControlLabel control={<Checkbox
                                    checked={assetData.groups != null ? arrayGroup.length == assetData.groups.length : false}
                                    onChange={(e) => {
                                        var groups = assetData.groups ? assetData.groups : []
                                        if (arrayGroup.length == groups.length) {
                                            groups = []
                                        } else {
                                            groups = arrayGroup.map(data => data._id)
                                        }
                                        apiCalling(groups)
                                    }} />}
                                    label='All Groups' />}
                                {arrayGroup.map((item, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={<Checkbox
                                            checked={assetData.groups != null ? assetData.groups.includes(item._id) : false}
                                            onChange={(e) => {
                                                var groups = assetData.groups ? assetData.groups : []
                                                if (groups.includes(item._id)) {
                                                    groups = groups.filter(obj => obj != item._id)
                                                } else {
                                                    groups.push(item._id)
                                                }
                                                apiCalling(groups)
                                            }}
                                        />}
                                        label={item.name} />
                                ))}
                            </Groups>
                        </Stack>
                    </Stack>

                    <Stack mt={'15px'}>
                        <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} activeColor={'#151D26'} />
                    </Stack>

                    {activeTab === 'map' && <Stack direction={'row'} mt={'15px'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} px={3}>
                        <Stack gap={1.5} direction={'row'} width={'100%'}>
                            {assetData?.isFavourite != null && assetData?.isFavourite == "1" && <Box
                                sx={{
                                    p: "2px",
                                    borderRadius: '20px',
                                    bgcolor: "#27B2704F",
                                    height: '30px',
                                    alignContent: 'center',
                                }}
                            >
                                <Typography
                                    height="100%"
                                    width="100%"
                                    px="22px"
                                    borderRadius='20px'
                                    backgroundColor="#27B270"
                                    color={'#FFFFFF'}
                                    fontFamily={'Roboto'}
                                    fontWeight={400}
                                    fontSize={12}
                                    alignContent="center"
                                >
                                    Watching
                                </Typography>
                            </Box>}
                            {assetData.groupData != null && assetData.groupData.map(data => {
                                return (
                                    <Box
                                        key={data._id}
                                        sx={{
                                            px: 2,
                                            border: '1px solid #E2E2EA',
                                            borderRadius: '20px',
                                            bgcolor: "#F7F7F7",
                                            height: '30px',
                                        }}
                                    >
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            gap={2}
                                            height="100%"
                                        >
                                            <Typography
                                                color={'#44444F'}
                                                fontFamily={'Roboto'}
                                                fontWeight={400}
                                                fontSize={12}
                                            >
                                                {data.name}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    backgroundColor: data.color,
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                )
                            })}

                        </Stack>
                        <Stack gap={1.5} direction={'row'} alignItems={'center'}>
                            <Typography fontSize={12} fontFamily={"Roboto"} >
                                Watch
                            </Typography>
                            <Checkbox
                                color={"primary"}
                                sx={{
                                    padding: 0,
                                    margin: 0,

                                }}
                                checked={assetData?.isFavourite != null && assetData?.isFavourite == "1" ? true : false}
                                onChange={(e) => {
                                    onCheck(assetData, e.target.checked ? "1" : "0")
                                }}
                            />
                        </Stack>
                    </Stack>}
                </Stack>
                <Stack px={3} pb={3} pt={0} flex={1} sx={{ overflow: 'hidden', mt: '15px' }}>
                    {/* {activeTab === 'activity' && <Status assetData={assetData} trackerData={trackerData} />} */}
                    {activeTab === 'telemetry' && <Charts assetData={assetData} trackerData={trackerData} updateAssetData={updateAssetData} />}
                    {activeTab === 'map' && <LocationsMap assetData={assetData} trackerData={trackerData} handleChangeTrackersTab={handleChangeTrackersTab} activeTrackerTab={activeTrackerTab} getData={getData} />}
                    {/* {activeTab === 'movement' && <Trips assetData={assetData} trackerData={trackerData} />} */}
                    {activeTab === 'movement' && <Movement assetData={assetData} />}
                    {activeTab === 'events' && <Events assetData={assetData} trackerData={trackerData} />}
                    {activeTab === 'timeline' && <TimeLine selectedDataID={selectedDataId} />}
                    {/* {activeTab === 'details' && <Details assetData={assetData} trackerData={trackerData} updateData={updateData} />} */}
                    {activeTab === 'device' && <Device assetData={assetData} trackerData={trackerData} updateData={updateData} />}
                </Stack>
            </Stack >
            <Backdrop style={{ zIndex: 1500 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider >
    );
};
