import { Box, ButtonBase, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography, Backdrop, CircularProgress, MenuItem, Avatar, IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { tabs } from './data/tabs.js';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import { CheckCircle, Flag } from '@mui/icons-material';
import { DefaultToggle } from '../../modules/NodeDetail/NodeData/DefaultToggle.jsx'
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios.js';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { enqueueSnackbar } from 'notistack';
import { arrayRegisteredState } from './data/RegisteredState.jsx';
import { ConfirmAlert } from '../../customControls/confirmAlert.js';
import { SearchInput } from '../../components/SearchInput/SearchInput.jsx';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/DefaultDataGrid.jsx';
import { assignedSensorColumns, trackerColumns } from './data/sensor.jsx';
import { PlusIcon } from '../../assets/icons/PlusIcon.jsx';
import { arrayManufacturer, arrayProduct } from './data/arrayData.jsx';
import { DefaultIconButton } from '../../ui/DefaultIconButton/DefaultIconButton.jsx';
import imageEditIcon from '../../assets/images/imageEditIcon.svg';
import { CropProfilePicture } from './CropProfilePicture.jsx';

export const PersonnelNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const [isLoading, setIsLoading] = useState(false);
    const [arrayUserData, setArrayUserData] = useState([]);
    const [arrayAvailableSensor, setArrayAvailableSensor] = useState([]);
    const [arraySelectedNode, setArraySelectedNode] = useState([]);
    const [arrayGroups, setArrayGroups] = useState([]);
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [selectedNodeID, setSelectedNodeID] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePictureURL, setProfilePictureURL] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [billingActivate, setBillingActivate] = useState(false);
    const [shouldOpenCrop, setShouldOpenCrop] = useState(false);
    // const [arrayManufacturer, setArrayManufacturer] = useState([]);
    // const [arrayProduct, setArrayProduct] = useState([]);
    const fileRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const formik = useFormik({
        initialValues: {
            allGroups: false,
            selectedGroups: null,
            color: null,
            groups: [],
            selectedNode: null,
            selectedExistingUser: null,
            isBillingStatus: false,
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            selectedManufacturer: null,
            selectedProduct: null,
            nodeID: '',
            selectedFile: null,
            logo: ""
        },
        validationSchema: Yup.object().shape({
            selectedExistingUser: billingActivate ? Yup.object().required('Existing User is required') : "",
            firstName: !billingActivate ? Yup.string().required('First Name is required') : "",
            lastName: !billingActivate ? Yup.string().required('Last Name is required') : "",
            mobile: !billingActivate ? Yup.string().required('Mobile is required') : "",
            email: !billingActivate ? Yup.string().required('Email is required') : "",
            selectedManufacturer: Yup.object().required('Manufacturer is required'),
            selectedProduct: Yup.object().required('Product is required'),
            nodeID: Yup.string().required('Node Id is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                if (arraySelectedNode.length == 0) {
                    enqueueSnackbar("At least one node is required", {
                        anchorOrigin: { horizontal: "right", vertical: "top" },
                        variant: "error",
                    });
                } else {
                    var dataValues = {
                        allGroups: false,
                        selectedGroups: null,
                        color: null,
                        groups: values.groups,
                        selectedNode: values.selectedNode,
                        selectedExistingUser: values.selectedExistingUser,
                        isBillingStatus: values.isBillingStatus,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        mobile: values.mobile,
                        email: values.email,
                        selectedManufacturer: values.selectedManufacturer,
                        selectedProduct: values.selectedProduct,
                        nodeID: values.nodeID,
                        selectedFile: values.selectedFile,
                        logo: values.logo,
                    }
                    formik.setValues(dataValues)
                    if (selectedDataId == "" && profilePictureURL == null) {
                        enqueueSnackbar('Please upload image', {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                        return;
                    }
                    setIsLoading(true)
                    var data = await apicalling(values);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        enqueueSnackbar('Personnel ' + (selectedDataId == "" ? "added" : "updated"), {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                        navigate(-1)
                    } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                    setSubmitting(false);
                }
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        getAllDetail()
    }, []);

    function getUserData() {
        setIsLoading(true)
        api.post('/apis/CompanyUsers/byCompanyID/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayUserData(resData)
            }
            setIsLoading(false)
            if (selectedDataId != "") {
                getData(resData)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getAllDetail = () => {
        setIsLoading(true)
        api.get('/apis/Groups/activePersonnel').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayGroups(resData)
            }
            setIsLoading(false)
            getUserData()
        }).catch(function (error) {
            console.log(error);
        });
    }
    const getData = (arrayUser, arrayCategory, arrayType, arrayTracker) => {
        setIsLoading(true)
        api.get('/apis/Personnels/byId/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var arraySelectedNode = []
                var selectedNode = null
                var selectedManufacturer = null
                var selectedProduct = null
                var selectedExistingUser = arrayUser.find(user => user._id == resData.userID)
                console.log("Sensor", resData.sensor);
                setProfilePictureURL(resData.path)
                if (resData.sensor != null) {
                    selectedManufacturer = arrayManufacturer.find(manufacturer => manufacturer.id == resData.sensor.manufacturer)
                    selectedProduct = arrayProduct.find(product => product.id == resData.sensor.product)
                    var primaryTraker = { ...resData.sensor }
                    primaryTraker.role = "Primary"
                    selectedNode = primaryTraker
                    arraySelectedNode.push(resData.sensor)
                    setSelectedNodeID(resData.sensor._id)
                }
                setArraySelectedNode(arraySelectedNode)
                setBillingActivate(resData.billingStatus == "1" ? true : false)
                var values = {
                    allGroups: resData.groups,
                    groups: resData.groups,
                    selectedNode: selectedNode,
                    selectedExistingUser: selectedExistingUser,
                    isBillingStatus: resData.billingStatus == "1" ? true : false,
                    firstName: resData.firstName,
                    lastName: resData.lastName,
                    mobile: resData.mobile,
                    email: resData.email,
                    selectedManufacturer: selectedManufacturer,
                    selectedProduct: selectedProduct,
                    nodeID: resData.sensorNumber,
                    selectedFile: null,
                    logo: resData.path
                }
                formik.setValues(values);
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const goToNextTab = () => {
        const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);
        formik.validateForm().then((errors) => {
            formik.setErrors(errors);
            formik.setTouched({ selectedExistingUser: true, firstName: true, lastName: true, mobile: true, email: true, selectedNode: true });

            if (activeTab === tabs[0].value) {
                if (errors.selectedExistingUser || errors.firstName || errors.lastName || errors.mobile || errors.email) {
                    console.log("err")
                } else {
                    if (activeTabIndex < tabs.length - 1) {
                        setActiveTab(tabs[activeTabIndex + 1].value);
                    }
                }
            }
            else {
                if (activeTabIndex < tabs.length - 1) {
                    setActiveTab(tabs[activeTabIndex + 1].value);
                }
            }
        });
    };

    const goToPrevTab = () => {
        const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);

        if (activeTabIndex > 0) {
            setActiveTab(tabs[activeTabIndex - 1].value);
        }
    };


    async function apicalling(values) {
        const formData = new FormData();
        formData.append("file", profilePicture);
        formData.append("userID", values.selectedExistingUser != null ? values.selectedExistingUser._id : "")
        formData.append("firstName", values.firstName)
        formData.append("lastName", values.lastName)
        formData.append("mobile", values.mobile)
        formData.append("email", values.email)
        formData.append("groups", JSON.stringify(values.groups))
        formData.append("sensorNumber", values.selectedNode ? values.selectedNode.sensorNumber : "")
        formData.append("sensorNumberID", values.selectedNode ? values.selectedNode._id : "")
        formData.append("billingStatus", values.isBillingStatus == true ? "1" : "2")
        console.log("formData:", formData)
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
        // var data = {
        //     userID: values.selectedExistingUser != null ? values.selectedExistingUser._id : "",
        //     firstName: values.firstName,
        //     lastName: values.lastName,
        //     mobile: values.mobile,
        //     email: values.email,
        //     groups: values.groups,
        //     sensorNumber: values.selectedNode.sensorNumber,
        //     sensorNumberID: values.selectedNode._id,
        //     billingStatus: values.isBillingStatus == true ? "1" : "2",
        // }
        if (selectedDataId != "") {
            return await api({
                method: "PUT",
                url: '/apis/Personnels/' + selectedDataId,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            })
            //return await api.put('/apis/Personnels/' + selectedDataId, data)
        } else {
            return await api({
                method: "POST",
                url: '/apis/Personnels/',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            })

            //return await api.post('/apis/Personnels/', data)
        }
    }

    const handleColorChange = (selectedColor) => {
        formik.setFieldValue('groups', selectedColor);
    };

    function deletePersonnel() {
        setIsLoading(true)
        api.delete('/apis/Personnels/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Personnel deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-2)
            } else {
                setIsLoading(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deletePersonnel()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayAvailableSensor, event.target.value)
        setFilteredArrayData(filterArray)
    };

    const applyFilters = (arrayDataAll, query) => {
        if (query.length <= 3) {
            return []
        }
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['deviceSerialNumber'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []
        return finalArray
    }

    function handleAssignedNode(node) {
        if (arraySelectedNode.length >= 1) {
            enqueueSnackbar('Only one tracker can be added ', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            var objTracker = { ...node }
            var arraySelectedDevice = [...arraySelectedNode]
            arraySelectedDevice.push(objTracker)
            setArraySelectedNode(arraySelectedDevice);
            formik.setFieldValue('selectedNode', node);
        }
    }

    function handleUnassignedNode(sensor) {
        var objSensor = { ...sensor }
        setArrayAvailableSensor(objSensor);
        setArraySelectedNode([])
        setSelectedNodeID(null)
        formik.setFieldValue('selectedNode', null);
    }

    const addNode = () => {
        formik.validateForm().then((errors) => {
            formik.setErrors(errors);
            formik.setTouched({ selectedManufacturer: true, selectedProduct: true, nodeID: true });
            if (errors.selectedManufacturer || errors.selectedProduct || errors.nodeID) {
                // Return early to stop further execution
                return;
            } else {
                setIsLoading(true)
                var data = {
                    sensorNumber: formik.values.nodeID,
                    manufacturer: formik.values.selectedManufacturer ? formik.values.selectedManufacturer.id : "",
                    product: formik.values.selectedProduct ? formik.values.selectedProduct.id : ""
                }
                api.post('/apis/PersonnelSensors/add', data).then((res) => {
                    if (res.data.success) {
                        var resData = res.data.data
                        // setArrayCategory(resData.arrayCategory)
                        handleAssignedNode(resData)
                        setSelectedNodeID(resData._id)
                    } else {
                        enqueueSnackbar(res.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                    setIsLoading(false)
                }).catch(function (error) {
                    console.log(error);
                });
            }
        });

    }
    const deleteNode = (personnelData) => {
        setIsLoading(true)
        api.delete('/apis/PersonnelSensors/deleteById/' + selectedNodeID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                handleUnassignedNode(personnelData)
                // formik.setFieldValue('selectedManufacturer', '')
                // formik.setFieldValue('selectedProduct', '')
                // formik.setFieldValue('nodeID', '')
                getData()
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <>
            <FormikProvider value={formik}>
                <Stack
                    flex={1}
                    p={3}
                    gap={3}
                    overflow={'hidden'}
                    component={'form'}
                    onSubmit={formik.handleSubmit}
                >
                    <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography textTransform={'uppercase'}>PERSONNEL SETUP</Typography>
                    </Stack>
                    <Typography>Follow these quick steps to setup or edit a person in the system.</Typography>
                    <Stack direction={'row'} overflow={'hidden'} width={'100%'}>
                        {tabs.map((tab, idx) => (
                            <Stack
                                direction={'row'}
                                gap={1}
                                key={tab.value}
                                px={2}
                                py={1}
                                border={'1px solid #E2E2EA'}
                                borderRight={idx === tabs.length - 1 ? '1px solid #E2E2EA' : 'none'}
                                flex={1}
                                position={'relative'}
                                sx={{
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: '0',
                                        left: 0,
                                        width: '100%',
                                        height: 3,
                                        bgcolor: 'secondary.main',
                                        display: activeTab === tab.value ? 'block' : 'none',
                                    },
                                }}
                            >
                                <Typography>{idx + 1}.</Typography>
                                <Stack gap={0.5} overflow={'hidden'} flex={1}>
                                    <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography>{tab.title}</Typography>
                                        {tabs.findIndex((tab) => tab.value === activeTab) !== idx &&
                                            tabs.findIndex((tab) => tab.value === activeTab) > idx && (
                                                <CheckCircle
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        color: 'green.main',
                                                    }}
                                                />
                                            )}
                                    </Stack>
                                    <Typography
                                        fontSize={12}
                                        color={'#AAB0BC'}
                                        overflow={'hidden'}
                                        whiteSpace={'nowrap'}
                                        textOverflow={'ellipsis'}
                                    >
                                        {tab.text}
                                    </Typography>
                                </Stack>
                            </Stack>
                        ))}
                        <Stack
                            py={1}
                            px={5}
                            alignItems={'center'}
                            justifyContent={'center'}
                            border={'1px solid #E2E2EA'}
                            flexShrink={0}
                        >
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#FCFCFE',
                                    borderRadius: '5px',
                                    height: '30px'
                                }}
                                type='submit'
                                disabled={formik.isSubmitting}
                                onClick={(e) => { console.log("error", formik.errors); }}
                            >
                                <Typography color={'secondary.main'} fontSize={14} fontWeight={700} whiteSpace={'nowrap'}>
                                    Save & Close
                                </Typography>
                            </ButtonBase>
                        </Stack>
                    </Stack>

                    <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'flex-start'}>
                        {activeTab === tabs[0].value && (
                            <Stack gap={1}>
                                <Typography>Basic Setup Details</Typography>
                                <Typography fontSize={14} color={'#AAB0BC'}>
                                    Enter the basic details of the sensor node including it’s desired name, category and type.
                                </Typography>
                            </Stack>
                        )}
                        {activeTab === tabs[1].value && (
                            <Stack >
                                <Typography fontWeight={500} fontSize={15} fontFamily={'Roboto'}>Enter Node ID (Serial)</Typography>
                            </Stack>
                        )}
                        <Stack direction={'row'} gap={1}>
                            {selectedDataId != "" &&
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        border: '1px solid #E2E2EA',
                                        borderRadius: '5px',
                                        bgcolor: '#E2E2EA',
                                        mt: 'auto',
                                        alignSelf: 'flex-end',
                                        height: '30px'
                                    }}
                                    onClick={(e) => {
                                        setDeleteConfirmationShouldOpen(true)
                                    }}
                                >
                                    <Typography fontSize={14}>Remove</Typography>
                                </ButtonBase>
                            }
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#E2E2EA',
                                    borderRadius: '5px',
                                    height: '30px'
                                }}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <Typography fontSize={14}>Cancel</Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '5px',
                                    bgcolor: tabs.findIndex((tab) => tab.value === activeTab) === 0 && '#f0f0f3',
                                    height: '30px'
                                }}
                                disabled={tabs.findIndex((tab) => tab.value === activeTab) === 0}
                                onClick={goToPrevTab}
                            >
                                <Typography fontSize={14} color={tabs.findIndex((tab) => tab.value === activeTab) === 0 && '#9a9aa4'}>
                                    Back
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '5px',
                                    bgcolor: tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1 && '#f0f0f3',
                                    height: '30px'
                                }}
                                onClick={goToNextTab}
                                disabled={tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1}
                            >
                                <Typography
                                    fontSize={14}
                                    color={tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1 && '#9a9aa4'}
                                >
                                    Next
                                </Typography>
                            </ButtonBase>
                        </Stack>
                    </Stack>
                    {tabs[0].value === activeTab && (
                        <Box bgcolor={'#FAFBFD'} px={3} py={2} flex={1}>
                            <Stack gap={3} maxWidth={650}>
                                <Box>
                                    <Grid spacing={3} container>
                                        <Grid xs={6} item>
                                            <Stack direction={'row'} >
                                                {console.log("isBillingStatus", !formik.values.isBillingStatus)}
                                                <DefaultSelect
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: 40,
                                                        },
                                                    }}
                                                    label={'Existing User'}
                                                    value={formik.values.selectedExistingUser}
                                                    onItemSelected={(e) => formik.setFieldValue('selectedExistingUser', e.target.value)}
                                                    helperText={formik.touched.selectedExistingUser && formik.errors.selectedExistingUser}
                                                    error={Boolean(formik.touched.selectedExistingUser && formik.errors.selectedExistingUser)}
                                                    placeholder="Select an existing system user…"
                                                    displayKey="email"
                                                    disabled={!formik.values.isBillingStatus}
                                                >
                                                    {arrayUserData.map((item, index) => (
                                                        <MenuItem key={index} value={item}> {item.email}</MenuItem>
                                                    ))}
                                                </DefaultSelect>
                                            </Stack>
                                        </Grid>
                                        <Grid xs={6} item >
                                            <Stack direction={'row'} gap={2} alignItems={'center'} mt={2}>
                                                <DefaultToggle name={'isBillingStatus'} checked={formik.values.isBillingStatus} onChange={(e) => {
                                                    // setIsAutoRefresh(e.target.checked)
                                                    formik.resetForm();
                                                    formik.setFieldValue("isBillingStatus", e.target.checked == "1" ? true : false)
                                                    setBillingActivate(!billingActivate)
                                                    formik.setFieldValue('selectedExistingUser', null);
                                                    formik.setFieldValue("firstName", "");
                                                    formik.setFieldValue("lastName", "");
                                                    formik.setFieldValue("mobile", "");
                                                    formik.setFieldValue("email", "");

                                                }}
                                                />
                                            </Stack>


                                        </Grid>

                                        <Grid xs={6} item>
                                            <DefaultInput
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        pr: 6,
                                                    },
                                                }}
                                                label={'First Name'}
                                                value={formik.values.firstName}
                                                name={'firstName'}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                                helperText={formik.touched.firstName && formik.errors.firstName}
                                                disabled={formik.values.isBillingStatus}
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <DefaultInput
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        pr: 6,
                                                    },
                                                }}
                                                label={'Last Name'}
                                                value={formik.values.lastName}
                                                name={'lastName'}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                                helperText={formik.touched.lastName && formik.errors.lastName}
                                                disabled={formik.values.isBillingStatus}
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <DefaultInput
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        pr: 6,
                                                    },
                                                }}
                                                label={'Mobile'}
                                                value={formik.values.mobile}
                                                name={'mobile'}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                                                helperText={formik.touched.mobile && formik.errors.mobile}
                                                disabled={formik.values.isBillingStatus}
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <DefaultInput
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        pr: 6,
                                                    },
                                                }}
                                                label={'Email'}
                                                value={formik.values.email}
                                                name={'email'}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.touched.email && formik.errors.email)}
                                                helperText={formik.touched.email && formik.errors.email}
                                                disabled={formik.values.isBillingStatus}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box height={'1px'} bgcolor={'#E2E2EA'} />
                                <Typography>Profile picture</Typography>
                                <Stack width={180}>
                                    <Stack direction={'row'}>
                                        <Box flexGrow={1} />
                                        <Box
                                            sx={{
                                                padding: "3",
                                                border: 'none',
                                                cursor: 'pointer',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '20px',
                                                height: '20px',
                                                color: 'white',
                                            }}
                                        >
                                            <Box component={'img'} src={imageEditIcon} onClick={() => {
                                                if (fileRef.current) {
                                                    fileRef.current.value = null;
                                                    fileRef.current.click()
                                                }
                                            }}></Box>
                                        </Box >
                                    </Stack>
                                    <Avatar
                                        alt="+"
                                        src={profilePictureURL}
                                        sx={{
                                            width: 150,
                                            height: 150
                                        }}
                                        onClick={(e) => {
                                            if (fileRef.current) {
                                                fileRef.current.value = null;
                                                fileRef.current.click()
                                            }
                                        }}
                                    />
                                </Stack>
                                <input
                                    type="file"
                                    name="files"
                                    hidden
                                    ref={fileRef}
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => {
                                        const selectedFiles = e.target.files;
                                        if (!selectedFiles || selectedFiles.length === 0) {
                                            return;
                                        }
                                        const file = selectedFiles[0];
                                        if (file) {
                                            const acceptedTypes = ['image/png', 'image/jpeg'];
                                            if (!acceptedTypes.includes(file.type)) {
                                                enqueueSnackbar("Upload the file in the correct format", {
                                                    anchorOrigin: { horizontal: 'right', vertical: 'top' },
                                                    variant: 'error'
                                                });
                                                e.target.value = null;
                                                return;
                                            }
                                            formik.setFieldValue('selectedFile', file)
                                            const reader = new FileReader();
                                            reader.onload = () => {

                                                setSelectedImage(reader.result)
                                                setShouldOpenCrop(true)
                                                console.log("Image selected", reader.result)
                                                // formik.setFieldValue('logo', reader.result)
                                            };
                                            reader.readAsDataURL(selectedFiles[0]);
                                        }

                                    }}
                                />

                                <Box height={'1px'} bgcolor={'#E2E2EA'} />
                                <Stack gap={1}>
                                    <Typography>Groups</Typography>
                                    <Typography fontSize={12} color={'#AAB0BC'}>
                                        Assign to one or more groups to help manage your assets. To Edit Groups to go Settings &gt; Groups..
                                    </Typography>
                                </Stack>
                                <Stack gap={1}>
                                    <Typography fontSize={14}>Configured Groups</Typography>
                                    <FormGroup
                                        sx={{
                                            '.MuiFormControlLabel-label': {
                                                color: '#696974',
                                                fontSize: 14,
                                            },
                                            '.MuiCheckbox-root': {
                                                color: '#B5B5BE',

                                                '&.Mui-checked': {
                                                    color: '#44444F',
                                                },
                                            },
                                        }}
                                    >
                                        <Stack px={2} ml={-2}>
                                            {arrayGroups != null && arrayGroups.length > 0 &&
                                                <Stack
                                                    direction={'row'}
                                                    gap={3}
                                                    maxWidth={300}
                                                    justifyContent={'space-between'}
                                                    alignItems={'center'}
                                                >
                                                    <FormControlLabel
                                                        name={'allGroups'}
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.groups != null ? arrayGroups.length == formik.values.groups.length : false}
                                                                onChange={(e) => {
                                                                    var groups = formik.values.groups ? formik.values.groups : []
                                                                    if (arrayGroups.length == groups.length) {
                                                                        groups = []
                                                                    } else {
                                                                        groups = arrayGroups.map(data => data._id)
                                                                    }
                                                                    handleColorChange(groups)
                                                                }}
                                                            />
                                                        }
                                                        label='All Groups'
                                                    />
                                                </Stack>
                                            }
                                            {arrayGroups != null && arrayGroups.map((group, index) => (
                                                <Stack
                                                    key={index}
                                                    direction={'row'}
                                                    gap={3}
                                                    maxWidth={300}
                                                    justifyContent={'space-between'}
                                                    alignItems={'center'}
                                                >
                                                    <FormControlLabel
                                                        key={index}
                                                        value={group.name}
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.groups != null ? formik.values.groups.includes(group._id) : false}
                                                                onChange={(e) => {
                                                                    var groups = formik.values.groups ? formik.values.groups : []
                                                                    if (groups.includes(group._id)) {
                                                                        groups = groups.filter(obj => obj != group._id)
                                                                    } else {
                                                                        groups.push(group._id)
                                                                    }
                                                                    handleColorChange(groups)
                                                                }}
                                                            />
                                                        }
                                                        label={group.name}
                                                    />
                                                    <Box width={45} height={23} bgcolor={group.color} borderRadius={'5px'} />
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </FormGroup>
                                </Stack>
                            </Stack>
                        </Box>
                    )}

                    {tabs[1].value === activeTab && (
                        <>
                            <Stack spacing={2} flex={1}>
                                {selectedNodeID == null &&
                                    <Stack gap={3} maxWidth={650}>
                                        <Grid spacing={3} container>
                                            <Grid xs={6} item>
                                                <DefaultSelect
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: 40,
                                                        },
                                                    }}
                                                    label={'Manufacturer'}
                                                    value={formik.values.selectedManufacturer}
                                                    onItemSelected={(e) => {
                                                        formik.setFieldValue('selectedManufacturer', e.target.value)
                                                    }}
                                                    helperText={formik.touched.selectedManufacturer && formik.errors.selectedManufacturer}
                                                    error={Boolean(formik.touched.selectedManufacturer && formik.errors.selectedManufacturer)}
                                                    displayKey="value"
                                                >
                                                    {arrayManufacturer.map((item, index) => (
                                                        <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                    ))}
                                                </DefaultSelect>
                                            </Grid>
                                            <Grid xs={6} item>
                                                <Box position={'relative'}>
                                                    <DefaultSelect
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: 40,
                                                            },
                                                        }}
                                                        label={'Product'}
                                                        value={formik.values.selectedProduct}
                                                        onItemSelected={(e) => formik.setFieldValue('selectedProduct', e.target.value)}
                                                        helperText={formik.touched.selectedProduct && formik.errors.selectedProduct}
                                                        error={Boolean(formik.touched.selectedProduct && formik.errors.selectedProduct)}
                                                        displayKey="value"
                                                    >
                                                        {formik.values.selectedManufacturer != null && arrayProduct.filter(obj => obj.id == formik.values.selectedManufacturer.id).map((item, index) => (
                                                            <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                        ))}
                                                    </DefaultSelect>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6} item>
                                                <Stack position={'relative'}>
                                                    <DefaultInput
                                                        sx={{
                                                            '& .MuiInputBase-input': {
                                                                pr: 6,
                                                            },
                                                        }}
                                                        label={'ID (Serial)'}
                                                        value={formik.values.nodeID}
                                                        name={'nodeID'}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        error={Boolean(formik.touched.nodeID && formik.errors.nodeID)}
                                                        helperText={formik.touched.nodeID && formik.errors.nodeID}
                                                    ></DefaultInput>
                                                    <CheckCircle
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 10,
                                                            top: 24,
                                                            width: 30,
                                                            height: 30,
                                                            color: 'green.main',
                                                        }}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid xs={6} item>
                                                <ButtonBase
                                                    sx={{
                                                        height: 30,
                                                        px: 2,
                                                        py: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        mt: 2.8,
                                                        gap: 1,
                                                        bgcolor: 'green.main',
                                                        borderRadius: '6px',
                                                    }}
                                                    onClick={addNode}
                                                >
                                                    <PlusIcon />
                                                    <Typography fontSize={14} color={'common.white'}>
                                                        Add
                                                    </Typography>
                                                </ButtonBase>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                }
                                <Stack>
                                    <Typography fontWeight={700} fontSize={14}>Assigned Node</Typography>
                                </Stack>
                                <Stack
                                    px={3}
                                    py={2}
                                    width={'100%'}
                                    sx={{
                                        border: '1px solid #E2E2EA',
                                        borderRadius: '8px',
                                        borderRightWidth: '1px',
                                    }}
                                    overflow={'hidden'}
                                    position={'relative'}
                                    mb={3}
                                >
                                    <Stack direction={'row'} ml={-1.5}>
                                        <DefaultDataGrid columns={assignedSensorColumns(deleteNode)} rows={arraySelectedNode}
                                        // onRowClick={(e) => { onTrackerClick(e.row) }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </>
                    )}
                </Stack>
                {
                    deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                        updateConfirmAlert={(e, value) => {
                            setDeleteConfirmationShouldOpen(false)
                            updateConfirmAlert(value)
                        }} />)
                }
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </FormikProvider >
            {shouldOpenCrop && selectedImage && <CropProfilePicture src={selectedImage} setProfilePicture={setProfilePicture} setShouldOpenCrop={setShouldOpenCrop} setProfilePictureURL={setProfilePictureURL} />}</>
    );
};
