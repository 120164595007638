import React, { useEffect, useState } from 'react';
import { Backdrop, Box, ButtonBase, CircularProgress, MenuItem, Stack, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { Datepicker } from '../../../ui/Datepicker/index.js';
import { Filter } from '../../../ui/Filter/Filter.jsx';
import { arrayChartType, arraySensor } from './Data/arrayData.jsx';
import dayjs from 'dayjs';
import api from '../../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { arrayMeasurment } from '../../RuleNew/data/arrayData.jsx'
import { DefaultChart } from './DefaultChart.jsx';
import { Groups } from '../Groups.jsx';
import searchIcon from '../../../assets/images/SearchIcon.svg';
import excelFile from '../../../assets/images/excelFile.svg';
import printIcon from '../../../assets/images/PrintIcon.svg';

export const Charts = ({ nodeData, sensorData, nodeId, onUpdateNodeData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arraySelectedSensor, setArraySelectedSensor] = useState([])
    const [selectedChart, setSelectedChart] = useState("1")
    const [chartStartDate, setChartStartDate] = useState(dayjs());
    const [chartEndDate, setChartEndDate] = useState(dayjs());
    const [arrayData, setArrayData] = useState([]);
    const [arraySensorDataType, setArraySensorDataType] = useState([]);

    useEffect(() => {
        getSecondaryData()
    }, [])

    // useEffect(() => {
    //     if (nodeData != null) {
    //         setArraySelectedSensor(nodeData.arrayChart ? nodeData.arrayChart : [])
    //     }
    // }, [nodeData])

    useEffect(() => {
        getSensorData(chartStartDate, chartEndDate)
    }, [sensorData]);

    const getSensorData = async (startDate, endDate) => {
        setIsLoading(true);
        let url = '/apis/Nodes/getSensorData'
        if (sensorData.product == "4") {
            url = '/apis/NodeSensors/vibrationSensorDataByDate'
        }
        let response = await api.post(url, { startDate: startDate.format('MM-DD-YYYY'), endDate: endDate.format('MM-DD-YYYY'), nodeID: nodeId })
        setArrayData(response.data.data)
        if (nodeData != null) {
            setArraySelectedSensor(nodeData.arrayChart ? nodeData.arrayChart : [])
        }
        setIsLoading(false);
    }

    const handleStartDateChange = (date) => {
        const isStartDateAfterEndDate = date.isAfter(chartEndDate);
        const isStartDateEqualToEndDate = date.isSame(chartEndDate, 'day');
        if (isStartDateAfterEndDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setChartStartDate(date)
        }

    };

    const handleEndDateChange = (date) => {

        const isEndDateAfterStartDate = date.isAfter(chartStartDate);
        const isStartDateEqualToEndDate = date.isSame(chartStartDate, 'day');
        if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("End date must be after start date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setChartEndDate(date)
        }
    };

    function handleSearchClick() {
        if (arraySelectedSensor.length > 0 && selectedChart != -1) {
            getSensorData(chartStartDate, chartEndDate)

        } else {
            enqueueSnackbar("Please select chart and sensor ", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }

    const getSecondaryData = () => {
        setIsLoading(true)
        api.get('/apis/SecondaryDatas/byNode/' + nodeId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                let arraySensorDataType = arrayMeasurment.filter(item => item.array.includes(sensorData.product + ""))

                data.forEach((obj) => {
                    const exists = arraySensorDataType.some(sensor =>
                        sensor.id === obj._id);

                    if (!exists) {
                        let newData = { id: obj._id, value: obj.name, field: "pin", symbol: "" }
                        arraySensorDataType.push(newData);
                    }
                })
                setArraySensorDataType(arraySensorDataType)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function updateNode(arrayChart) {
        // setIsLoading(true)
        var data = {
            sensorNumberID: nodeData.sensorNumberID,
            sensorNumber: nodeData.sensorNumber,
            arrayChart: arrayChart
        }
        api.put('/apis/Nodes/' + nodeData._id, data).then((res) => {
            // setIsLoading(false)
            if (res.data.success) {
                var data = res.data.data
                onUpdateNodeData(data)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onCloseChartClick = (id) => {
        var groups = [...arraySelectedSensor]
        if (groups.includes(id)) {
            groups = groups.filter(obj => obj != id)
        } else {
            groups.push(id)
        }
        setArraySelectedSensor(groups)
        updateNode(groups)
    }

    const onUpChartClick = (id, index) => {
        var groups = [...arraySelectedSensor]
        if (index > 0) {
            [groups[index - 1], groups[index]] = [groups[index], groups[index - 1]];
        }
        setArraySelectedSensor(groups)
        updateNode(groups)
    }

    const ondownChartClick = (id, index) => {
        var groups = [...arraySelectedSensor]
        if (index != (arraySelectedSensor.length - 1)) {
            [groups[index + 1], groups[index]] = [groups[index], groups[index + 1]];
        }
        setArraySelectedSensor(groups)
        updateNode(groups)
    }


    return (
        <Stack spacing={2} flex={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} gap={1.5}>
                    <Box maxWidth={200} width={'100%'} >
                        <Groups label="Select Sensors">
                            {arraySensorDataType.length > 0 && <FormControlLabel control={<Checkbox
                                checked={arraySensorDataType.length == arraySelectedSensor.length}
                                onChange={(e) => {
                                    var groups = [...arraySelectedSensor]
                                    if (arraySensorDataType.length == groups.length) {
                                        groups = []
                                    } else {
                                        groups = arraySensorDataType.map(data => data.id)
                                    }
                                    setArraySelectedSensor(groups)
                                    updateNode(groups)
                                }} />}
                                label='All Sensors' />}
                            {arraySensorDataType.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={<Checkbox
                                        checked={arraySelectedSensor.includes(item.id)}
                                        onChange={(e) => {
                                            var groups = [...arraySelectedSensor]
                                            if (groups.includes(item.id)) {
                                                groups = groups.filter(obj => obj != item.id)
                                            } else {
                                                groups.push(item.id)
                                            }
                                            setArraySelectedSensor(groups)
                                            updateNode(groups)
                                        }}
                                    />}
                                    label={item.value} />
                            ))}
                        </Groups>
                    </Box>
                    <Box maxWidth={170} width={'100%'} >
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedChart(value)
                            }}
                            defaultValue={selectedChart}
                            placeholder="Select Chart Type"
                        >
                            {arrayChartType.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Datepicker
                        sx={{
                            width: '170px'
                        }}
                        initialDate={chartStartDate}
                        handleDateChange={handleStartDateChange}
                    ></Datepicker>

                    <Datepicker
                        sx={{
                            width: '170px'
                        }}
                        initialDate={chartEndDate}
                        handleDateChange={handleEndDateChange}
                    />

                    <Stack>
                        <ButtonBase
                            sx={{
                                height: 30,
                                width: 30,
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}

                            onClick={() => {
                                handleSearchClick()
                            }}
                        >
                            <Box component={'img'} src={searchIcon} sx={{ width: "20px", height: "20px" }} />
                        </ButtonBase>
                    </Stack>
                </Stack>

                <Stack direction={'row'} gap={1.5}>
                    <ButtonBase
                        sx={{
                            height: 30,
                            width: 30,
                            px: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            border: '1px solid #E2E2EA',
                            bgcolor: '#F6F8FA',
                            borderRadius: '6px',
                        }}

                        onClick={() => {
                            // handleSearchClick()
                        }}
                    >
                        <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />
                    </ButtonBase>
                    <ButtonBase
                        sx={{
                            height: 30,
                            width: 30,
                            px: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            border: '1px solid #E2E2EA',
                            bgcolor: '#F6F8FA',
                            borderRadius: '6px',
                        }}

                        onClick={() => {
                            // handleSearchClick()
                        }}
                    >
                        <Box component={'img'} src={printIcon} sx={{ width: "22px", height: "22px" }} />
                    </ButtonBase>
                </Stack>
            </Stack>
            <Box
                sx={{
                    width: '100%',
                    overflowY: 'auto',
                    height: 'calc(100vh - 265px)',
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '4px',
                        backgroundColor: '#bdbdbd',
                    },
                }}>
                {arraySelectedSensor.map((id, index) => {
                    return <DefaultChart arrayData={arrayData} arraySelectedSensor={arraySelectedSensor} arraySensorDataType={arraySensorDataType} sensorData={sensorData} selectedChart={selectedChart} selectedSensor={id} onUpChartClick={onUpChartClick} ondownChartClick={ondownChartClick} onCloseChartClick={onCloseChartClick} chartId={id} key={id} index={index} />
                })}
            </Box>

            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    )
}