import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
// import UserManage from './views/User/UserManage';

import { useLocation, useNavigate } from 'react-router-dom';
import { Asset } from './modules/Asset';
import { Login } from './modules/Login';
import { AssetMapView } from './modules/AssetMapView';
import { Settings } from './modules/Settings';
import { Tracker } from './modules/Devices';
import { AssetNew } from './modules/AssetNew';
import { AssetListing } from './modules/AssetListing';
import { NodeDetail } from './modules/NodeDetail';
import { NodeListing } from './modules/NodeListing';
import { ChangePassword } from './modules/ChangePassword/ChangePassword';
import { EditProfile } from './modules/EditProfile/EditProfile';
import { Events } from './modules/Events';
import { ForgotPassword } from './modules/ForgotPassword/ForgotPassword';
import { ResetPassword } from './modules/ResetPassword/ResetPassword';
import { Register } from './modules/Register/Register';
import { Success } from './modules/Success/Success';
import { NodeNew } from './modules/NodeNew';
import { TrackerDetail } from './modules/TrackerDetail/TrackerDetail';
import { RuleListing } from './modules/RuleListing/RuleListing';
import { RuleNew } from './modules/RuleNew/RuleNew';
import { BillingReportListing } from './modules/BillingReporting/BillingReportListing';
import { BillingReportDetail } from './modules/BillingDetail/BillingReportDetail';
import { BillingReportDetailPrint } from './modules/BillingDetail/BillingReportDetailPrint';
import { NodeDashboard } from './modules/NodeDashboard/NodeDashboard';
import { PersonnelListing } from './modules/PersonnelListing/PersonnelListing';
import { PersonnelDetail } from './modules/Personnel/PersonnelDetail';
import { PersonnelNew } from './modules/PersonnelNew';
import { Notification } from './modules/Notification/Notification';
import { PersonnelDashboard } from './modules/PersonnelDashboard/PersonnelDashboard';


function Loadable(Component) {
  const InjectedNavigate = function (props) {
    const navigate = useNavigate(props);
    const location = useLocation(props);
    return (<Suspense fallback={<LoadingScreen />}>
      <Component {...props} navigate={navigate} location={location} />
    </Suspense>)
  };
  return InjectedNavigate;
}

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'success',
        element: <Success />
      }
    ]
  },
  {
    path: 'asset-listing',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/asset-listing',
        element: <AssetListing />
      },
      {
        path: '/asset-listing/detail',
        element: <Asset />
      },
      {
        path: '/asset-listing/manage',
        element: <AssetNew />
      },
    ]
  },
  {
    path: 'asset',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/asset/asset-mapview',
        element: <AssetMapView />
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/dashboard',
        element: <AssetMapView />
      },
    ]
  },
  {
    path: 'node-listing',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/node-listing',
        element: <NodeListing />
      },
      {
        path: '/node-listing/detail',
        element: <NodeDetail />
      },
      {
        path: '/node-listing/manage',
        element: <NodeNew />
      },
    ]
  },
  {
    path: 'node-dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/node-dashboard',
        element: <NodeDashboard />
      },
    ]
  },
  {
    path: 'billing-reports-listing',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/billing-reports-listing',
        element: <BillingReportListing />
      },
      {
        path: '/billing-reports-listing/detail',
        element: <BillingReportDetail />
      },
      {
        path: '/billing-reports-listing/detail/print',
        element: <BillingReportDetailPrint />
      },
    ]
  },
  {
    path: 'devices',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/devices',
        element: <Tracker />
      },
      {
        path: '/devices/detail',
        element: <TrackerDetail />
      },
    ]
  },
  {
    path: 'events',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/events',
        element: <Events />
      },
    ]
  },
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/settings',
        element: <Settings />
      },
    ]
  },
  {
    path: 'profile',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/profile/editProfile',
        element: <EditProfile />
      },
      {
        path: '/profile/changePassword',
        element: <ChangePassword />
      },
    ]
  },
  {
    path: 'rule-listing',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/rule-listing',
        element: <RuleListing />
      },
      {
        path: '/rule-listing/manage',
        element: <RuleNew />
      },
    ]
  },
  {
    path: 'personnel-listing',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/personnel-listing',
        element: <PersonnelListing />
      },
      {
        path: '/personnel-listing/detail',
        element: <PersonnelDetail />
      },
      {
        path: '/personnel-listing/manage',
        element: <PersonnelNew />
      },
    ]
  },
  {
    path: 'personnel-dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/personnel-dashboard',
        element: <PersonnelDashboard />
      },
    ]
  },
  {
    path: 'notification',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/notification',
        element: <Notification />
      },
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <AssetMapView />
      },
    ]
  }
];

export default routes;
