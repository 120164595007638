import { Box, Stack, Typography, Backdrop, CircularProgress, ButtonBase } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Tabs } from '../../../ui/Tabs/index.js';
import { Map } from './Map.jsx';
import { Datepicker } from '../../../ui/Datepicker/index.js';
import { playTabs } from '../data/playTabs.jsx';
import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';
import api from '../../../lib/axios';
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/DefaultDataGrid.jsx';
import { movementColumn } from './data/movementColumn.jsx';
import searchIcon from '../../../assets/images/SearchIcon.svg';
import excelFile from '../../../assets/images/excelFile.svg';
import mapIcon from '../../../assets/images/mapIcon.svg';
import Papa from 'papaparse';
import moment from 'moment';
import { MovementMap } from './MovementMap.jsx';
import { PlayArrow } from '@mui/icons-material';

export const Movement = ({ personnelData }) => {
    const [activeTab, setActiveTab] = useState("");
    const [arrayDirectionData, setArrayDirectionData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedDataId, setSelectedDataId] = useState(null);
    const [isOpenTripMap, setIsOpenTripMap] = useState(false);
    const [isMapOpen, setIsMapOpen] = useState(false);
    const [isActivePlayButton, setIsActivePlayButton] = useState(false);


    useEffect(() => {
        if (personnelData != null) {
            getTrips(startDate, endDate)
        }
    }, [personnelData]);

    useEffect(() => {
        if (isActivePlayButton) {
            setSelectedLocation(null)
        }
    }, [isActivePlayButton]);

    const getTrips = (startDate, endDate) => {
        setIsLoading(true);
        api.post('/apis/Personnels/getDirection', { personnelID: personnelData._id, startDate: startDate.format('MM-DD-YYYY'), endDate: endDate.format('MM-DD-YYYY') }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayDirectionData(resData)
            }
            setSelectedLocation(null)
            setActiveTab("")
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleStartDateChange = (date) => {
        console.log("Start date: ", date)
        const isStartDateAfterEndDate = date.isAfter(endDate);
        const isStartDateEqualToEndDate = date.isSame(endDate, 'day');
        if (isStartDateAfterEndDate && !isStartDateEqualToEndDate) {
            console.log("startDate", startDate)
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setStartDate(date)
        }
    };

    const handleEndDateChange = (date) => {
        console.log(date)

        const isEndDateAfterStartDate = date.isAfter(startDate);
        const isStartDateEqualToEndDate = date.isSame(startDate, 'day');
        if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("End date must be after start date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setEndDate(date)
        }
    };

    const handleChangeTab = (tab) => {
        if (isOpenTripMap) {
            // setIsOpenTripMap(false)
            // setSelectedLocation(null)
            // setSelectedDataId(null)
        }
        if (arrayDirectionData.length > 0) {
            setActiveTab(tab);
        }
    };

    const updateSelectedLocation = (data) => {
        if (selectedLocation != null && selectedLocation._id == data._id) {
            setSelectedLocation(null)
            setSelectedDataId(null)
            setIsOpenTripMap(false)
        } else {
            setSelectedLocation(data)
            setSelectedDataId(data._id)
            setIsOpenTripMap(true)
            setIsMapOpen(true)
        }
        setIsActivePlayButton("")
    }

    function handleSearchClick() {
        getTrips(startDate, endDate)

    }
    return (
        <>
            <Stack mb={2} direction={'row'} gap={6} alignItems={'center'}>
                <Stack direction={'row'} gap={1}>
                    <Datepicker initialDate={startDate} handleDateChange={handleStartDateChange} />
                    <Datepicker initialDate={endDate} handleDateChange={handleEndDateChange} />
                    <Stack>
                        <ButtonBase
                            sx={{
                                height: 30,
                                width: 30,
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}

                            onClick={() => {
                                handleSearchClick()
                            }}
                        >
                            <Box component={'img'} src={searchIcon} sx={{ width: "20px", height: "20px" }} />
                        </ButtonBase>
                    </Stack>
                    <Stack>
                        <ButtonBase
                            sx={{
                                height: 30,
                                width: 30,
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}

                            onClick={() => {
                                if (arrayDirectionData.length > 0) {
                                    const updatedArrayData = arrayDirectionData.map((res) => {
                                        const location = res.latitude + " , " + res.longitude
                                        const temperature = res.temperature;
                                        const light = res.light;
                                        const arrayEvents = res.events
                                        const eventText = arrayEvents.map(event => event.name).join(',')
                                        const battery = res.battery ? parseFloat(res.battery) : null
                                        const formatedBattery = battery ? `${battery.toFixed(1)}v` : "-"
                                        const timeStamp = res.createdAt ? moment(res.createdAt).format("MMMM DD, YYYY hh:mm A") : '-'
                                        return {
                                            "Location (Area)": location,
                                            "light": light ? `${parseInt(light)}%` : "-",
                                            "Temperature": temperature ? `${temperature} °F` : "-",
                                            "Events": eventText ? eventText : "-",
                                            "Battery": formatedBattery,
                                            "Timestamp": timeStamp
                                        };
                                    });
                                    const csvData = Papa.unparse(updatedArrayData);

                                    // Create a Blob object containing the CSV data
                                    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

                                    // Create a URL for the Blob
                                    const url = URL.createObjectURL(blob);

                                    // Create a download link and trigger the click event to download the CSV file
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.download = `Movement.csv`;
                                    link.click();

                                    // Release the Blob URL
                                    URL.revokeObjectURL(url);
                                } else {
                                    enqueueSnackbar("There is no data to export", {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                }

                            }}
                        >
                            <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />
                        </ButtonBase>
                    </Stack>
                    <Stack>
                        <ButtonBase
                            sx={{
                                width: "43px",
                                height: "30px",
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}

                            onClick={() => {
                                setSelectedDataId(null)
                                if (!isMapOpen) {
                                    setIsMapOpen(true)
                                } else {
                                    setIsMapOpen(false)
                                    setSelectedLocation(null)
                                    setSelectedDataId(null)
                                }
                            }}
                        >
                            <Box component={'img'} src={mapIcon} sx={{ width: "20px", height: "20px" }} />
                        </ButtonBase>
                    </Stack>
                    {isMapOpen && <Stack>
                        {/* <Tabs data={playTabs} activeTab={activeTab} setActiveTab={handleChangeTab} activeColor={'#151D26'} inline /> */}
                        <ButtonBase
                            sx={{

                                height: "30px",
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}

                            onClick={() => {
                                setIsActivePlayButton(!isActivePlayButton)
                                setSelectedDataId(null)
                            }}
                        >
                            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} >
                                <Typography fontSize={12} color={'#000000'} fontFamily={'Roboto'} fontWeight={400}>
                                    Play
                                </Typography>
                                <PlayArrow sx={{
                                    color: isActivePlayButton ? "#4CDCB9" : "#979797",
                                    width: 17,
                                    height: 17,
                                }} />
                            </Stack>
                        </ButtonBase>
                    </Stack>}
                </Stack>
            </Stack >

            <Stack overflow={'hidden'} height={'calc(100vh - 230px)'} width={'calc(100% + 24px)'} position="relative">
                {!isMapOpen ? (<Stack
                    flex={1}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '8px 8px 8px 8px',
                    }}
                    mb={1}
                    height={"100%"}
                >
                    <Box
                        width={'100%'}
                        flexShrink={0}
                        borderRight={'1px solid #E2E2EA'}
                        display={"auto"}
                        height={'100%'}
                        sx={{
                            overflowX: 'hidden',
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                width: '4px',
                                bgcolor: 'grey.400',
                            },
                        }}
                    >
                        <Stack sx={{ height: '100%' }}>
                            <DefaultDataGrid columns={movementColumn(selectedDataId)} rows={arrayDirectionData} />
                        </Stack>
                    </Box>
                </Stack>

                ) : (
                    // <Box
                    //     sx={{
                    //         position: 'absolute',
                    //         top: 0,
                    //         right: 0,
                    //         width: '50%',
                    //         height: 'calc(100vh - 240px)',
                    //         border: "1px solid #8F98AB45",
                    //         zIndex: 10,
                    //         mt: "2px"

                    //     }}
                    // >
                    //     <Map arrayDirectionData={isOpenTripMap ? [selectedLocation] : arrayDirectionData} selectedData={selectedLocation} activeAnimation={activeTab} />
                    // </Box>
                    <MovementMap
                        selectedDataId={selectedDataId}
                        arrayDirectionData={arrayDirectionData}
                        updateSelectedLocation={updateSelectedLocation}
                        isOpenTripMap={isOpenTripMap}
                        selectedLocation={selectedLocation}
                        activeTab={activeTab}
                        isMapOpen={isMapOpen}
                        isActivePlayButton={isActivePlayButton}
                    />
                )}
            </Stack>

            <Backdrop style={{ zIndex: 4 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
