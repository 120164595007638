import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import DrawCircleMode, { withCircleSupport } from 'mapboxgl-draw-circle-drag';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Box, Stack } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useRecoilValue } from 'recoil';
import { sidebarOpened } from '../../../core/store/index.js';
import { Tabs } from '../../../ui/Tabs/index.js';
import { tabs, trackersTabs } from '../../../components/Map/data/tabs.js';
mapboxgl.accessToken = 'pk.eyJ1Ijoic2lnbmFsdHJhY2tzIiwiYSI6ImNsd3Rjd3A1bTAyYnQybnBuNXZnajJ2YjEifQ.6DdrfKM2ke64TpX-Igogdg';

// eslint-disable-next-line react/display-name
export const Map = memo(({ sensorData, personnelData, arrayData, isDrawGeoFence, onDrawGeofence, mode, selectedPoligon, sx, activeTrackerTab }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const isOpenedSidebar = useRecoilValue(sidebarOpened);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);
    const [lng, setLng] = useState((sensorData != null && sensorData.longitude != null && sensorData.longitude != "-") ? sensorData.longitude : -74.01521529520066);
    const [lat, setLat] = useState((sensorData != null && sensorData.latitude != null && sensorData.latitude != "-") ? sensorData.latitude : 40.73760113154407);
    const [zoom, setZoom] = useState(12);

    const handleChangeTab = (value) => {
        setActiveTab(value);
    };

    const renderMap = (arrayData) => {
        console.log("-----", [lng, lat])
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: `mapbox://styles/signaltracks/${activeTab}`,
                center: [lng, lat],
                zoom: zoom,
            });
            console.log("-----", map.current.getCenter())
            map.current.addControl(new mapboxgl.NavigationControl());
            map.current.setCenter([lng, lat])
            if (isDrawGeoFence) {
                const modes = {
                    ...MapboxDraw.modes,
                    draw_rectangle: DrawRectangle,
                    draw_circle: DrawCircleMode,
                    direct_select: withCircleSupport('direct_select'),
                    simple_select: withCircleSupport('simple_select'),
                };
                draw.current = new MapboxDraw({
                    displayControlsDefault: false,
                    //defaultMode: mode,// 'draw_polygon',
                    userProperties: true,
                    modes: modes,
                    controls: {
                        // polygon: true,
                        rectangle: true,
                        trash: true
                    }
                });
                map.current.addControl(draw.current);
                if (selectedPoligon != null) {
                    draw.current.add({
                        type: "Feature",
                        id: selectedPoligon._id,
                        properties: selectedPoligon,
                        geometry: selectedPoligon.region,
                    });
                    arrayData = arrayData.filter(obj => obj._id != selectedPoligon._id)
                    onDrawGeofence(draw.current.getAll())
                    //draw.current.changeMode(selectedPoligon.mode ? selectedPoligon.mode : "draw_polygon");
                } else {
                    draw.current.changeMode(mode);
                }
            }
            console.log("arrayData", arrayData.length)
            map.current.on('draw.create', addArea);
            map.current.on('draw.update', updateArea);
            const waypoints = arrayData.map((poligon) => ({
                type: "Feature",
                id: poligon._id,
                properties: poligon,
                geometry: poligon.region,
            }));
            var objPoligon = {
                "layerData": {
                    "type": "FeatureCollection",
                    "features": waypoints
                }
            }
            map.current.on("load", async () => {
                updatePolygons(objPoligon);
            });

            map.current.on('move', () => {
                setLng(map.current.getCenter().lng.toFixed(4));
                setLat(map.current.getCenter().lat.toFixed(4));
                setZoom(map.current.getZoom().toFixed(2));
            });

            map.current.on('click', (e) => {
                //console.log(e);
            });

            if (sensorData != null && sensorData.longitude != null && sensorData.longitude != "-" && sensorData.latitude != null && sensorData.latitude != "-") {
                const el = document.createElement('div');
                if (personnelData != null && personnelData.path) {
                    el.className = 'avatar-marker';
                    // Using an img element as an avatar
                    const avatarImg = document.createElement('img');
                    avatarImg.src = personnelData?.path // Replace with dynamic URL or default
                    avatarImg.alt = "User Avatar";
                    avatarImg.style.width = '45px';
                    avatarImg.style.height = '45px';
                    avatarImg.style.borderRadius = '50%';

                    el.appendChild(avatarImg);

                    // Attach the avatar marker to the map
                    new mapboxgl.Marker(el)
                        .setLngLat([sensorData.longitude, sensorData.latitude])
                        .addTo(map.current);
                } else {
                    el.className = 'map-marker';
                    new mapboxgl.Marker(el).setLngLat([sensorData.longitude, sensorData.latitude]).addTo(map.current);
                }
            }
        }
    };

    function addArea(e) {
        const data = draw.current.getAll();
        console.log("add.features", data.features)
        if (data.features.length > 0) {
            onDrawGeofence(data)
        } else {
            if (e.type !== 'draw.delete')
                alert('Click the map to draw a polygon.');
        }
    }

    function updateArea(e) {
        const data = draw.current.getAll();
        console.log("update.features", data.features)
        if (data.features.length > 0) {
            onDrawGeofence(data)
        } else {
            if (e.type !== 'draw.delete')
                alert('Click the map to draw a polygon.');
        }
    }

    let updatePolygons = polygonData => {
        const layerName = 'polygons';
        let polygonSource = map.current.getSource(layerName) || false;
        if (polygonSource) {
            polygonSource.setData(polygonData.layerData);
            map.current.removeLayer(layerName);
        } else {
            map.current.addSource(layerName, {
                type: 'geojson',
                data: polygonData.layerData
            });
        }

        map.current.addLayer({
            id: layerName,
            type: 'fill',
            source: layerName,
            layout: {
                visibility: 'visible'
            },
            "paint": {
                "fill-color": "#5EAFCA",
                "fill-opacity": 0.1
            }
        });
        map.current.addLayer({
            id: `${layerName}-border`,
            type: 'line',
            source: layerName,
            layout: {
                visibility: 'visible'
            },
            paint: {
                'line-color': '#5EAFCA', // Border color
                'line-width': 2,      // Border width
                'line-opacity': 1.0   // Border opacity
            }
        });
    };

    const handleStorageChange = useDebouncedCallback(() => {
        if (map.current) {
            map.current.remove();
            map.current = null
        }
        renderMap(arrayData ? arrayData : []);
    }, 500);

    useEffect(() => {
        handleStorageChange();
    }, [isOpenedSidebar, arrayData, activeTab, isDrawGeoFence]);

    useEffect(() => {
        if (draw.current) {
            draw.current.changeMode(mode);
        }
    }, [mode]);

    useEffect(() => {
        if (sensorData != null && sensorData._id != null) {
            var logitude = (sensorData != null && sensorData.longitude != null && sensorData.longitude != "-") ? sensorData.longitude : -74.01521529520066
            var latitude = (sensorData != null && sensorData.latitude != null && sensorData.latitude != "-") ? sensorData.latitude : 40.73760113154407
            setLng(logitude);
            setLat(latitude);
            if (map.current) {
                map.current.setCenter([logitude, latitude])
            }
        }
    }, [sensorData, activeTrackerTab]);

    return (
        <Box position={'relative'} width={'100%'} height={'100%'}>
            <Stack direction={'row'} justifyContent={'space-between'} position="relative">
                <Box position={'absolute'} left={12} top={12} zIndex={3}>
                    <Tabs data={tabs} setActiveTab={handleChangeTab} activeTab={activeTab} inline />
                </Box>
            </Stack>

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    ...sx,
                }}
                ref={mapContainer}
            />
        </Box >
    );
});
