import { Stack, Box } from "@mui/material"
import { movementMapColumn } from "./data/movementColumn"
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/DefaultDataGrid.jsx';
import { Map } from './Map.jsx';


export const MovementMap = ({ selectedDataId, arrayDirectionData, updateSelectedLocation, isOpenTripMap, selectedLocation, activeTab, isMapOpen, isActivePlayButton }) => {
    return (
        <Stack overflow={'hidden'} direction={'row'} height={'calc(100vh - 230px)'} position="relative">
            <Stack
                flex={1}
                direction={'row'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 8px 8px 8px',
                }}
                mb={1}
                height={"100%"}
            >
                <Box
                    width={'450px'}
                    flexShrink={0}
                    borderRight={'1px solid #E2E2EA'}
                    display={"auto"}
                    height={'100%'}
                    sx={{
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            width: '4px',
                            bgcolor: 'grey.400',
                        },
                    }}
                >
                    <Stack sx={{ height: '100%' }}>
                        <DefaultDataGrid columns={movementMapColumn(selectedDataId)} rows={arrayDirectionData} onRowClick={(e) => { updateSelectedLocation(e.row) }} sx={{
                            '.MuiDataGrid-columnHeader': {
                                '&:last-of-type': {
                                    overflow: 'hidden',
                                },
                                '&:last-of-type .MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&:first-of-type': {
                                    pl: 0,
                                },
                            },
                            '.MuiDataGrid-cell': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                border: 'none',
                                '&:focus': {
                                    outline: 'none',
                                },
                                '&:first-of-type': {
                                    pl: 0,
                                },
                                maxWidth: '200px',
                            },
                            '.MuiDataGrid-selectedRowCount': {
                                display: 'none',
                            },
                        }} />
                    </Stack>
                </Box>
                {isMapOpen && (
                    <Map arrayDirectionData={arrayDirectionData} selectedData={selectedLocation} activeAnimation={activeTab} isMapOpen={isMapOpen} isActivePlayButton={isActivePlayButton} />
                )}
            </Stack>
        </Stack>
    )
}