import { Box, ButtonBase, Stack, Typography, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SearchInput } from '../../components/SearchInput/index.js';
import { tabs } from './data/tabs.js';
import { Tabs } from '../../ui/Tabs/index.js';
import { PlusIcon } from '../../assets/icons/PlusIcon.jsx';
import { configurationTabs } from './data/configurationTabs.js';
import { companyTabs } from '../../modules/Settings/data/companyTabs.js';
import { SettingCompany } from './tabs/Company/SettingCompany.jsx';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { SettingUser } from './tabs/User/SettingUser.jsx';
import { AssetCategories } from './tabs/Asset Configuration/Categories/AssetCategories.jsx';
import { AssetTypes } from './tabs/Asset Configuration/Types/AssetTypes.jsx';
import { SettingGroups } from './tabs/Groups/SettingGroups.jsx';
import api from '../../lib/axios.js';
import { Filter } from '../../ui/Filter/Filter.jsx';
import { NodeCategories } from './tabs/Node Configuration/Categories/NodeCategories.jsx';
import { NodeTypes } from './tabs/Node Configuration/Types/NodeTypes.jsx';
import { nodeConfigurationTabs } from './data/nodeConfigurationTabs.js';

export const Settings = () => {
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [configurationTab, setConfigurationTab] = useState(configurationTabs[0].value);
    const [nodeConfigurationTab, setNodeConfigurationTab] = useState(nodeConfigurationTabs[0].value);
    const [companyTab, setCompanyTab] = useState(companyTabs[0].value);
    const [userDetailsModal, setUserDetailsModal] = useState(null);
    const [groupDetailsModal, setGroupDetailsModal] = useState(null);
    const [assetCategoryModal, setAssetCategoryModal] = useState(null);
    const [assetTypeModal, setAssetTypeModal] = useState(null);
    const [nodeCategoryModal, setNodeCategoryModal] = useState(null);
    const [nodeTypeModal, setNodeTypeModal] = useState(null);
    const [userSearch, setUserSearch] = useState("");
    const [groupsSearch, setGroupsSearch] = useState("");
    const [arrayCategoryData, setArrayCategoryData] = useState([]);
    const [arrayNodeCategoryData, setArrayNodeCategoryData] = useState([]);
    const [assetTypesSearch, setAssetTypesSearch] = useState("");
    const [selectedTypeCategory, setSelectedTypeCategory] = useState(-1);
    const [assetCategorySearch, setAssetCategorySearch] = useState("");
    const [nodeCategorySearch, setNodeCategorySearch] = useState("");
    const [nodeTypesSearch, setNodeTypesSearch] = useState("");
    const [selectedNodeTypeCategory, setSelectedNodeTypeCategory] = useState(-1);
    const [objSummery, setObjSummery] = useState({
        totalUser: 0,
        activeUser: 0,
        disabledUser: 0,
        adminUser: 0,
        standardUser: 0,
        totalGroup: 0,
        activeGroup: 0,
        inActiveGroup: 0,
    });

    useEffect(() => {
        setUserDetailsModal(null);
    }, [activeTab])

    useEffect(() => {
        getNodeCategoryData()
        getCategoryData()
        getSummeryData();
    }, [])

    // useEffect(() => {
    //     getCategoryData()
    // }, [configurationTab])

    // useEffect(() => {
    //     getNodeCategoryData()
    // }, [nodeConfigurationTab])

    function getSummeryData() {
        api.get('/apis/Groups/summery/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setObjSummery(resData)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    const handleChangeConfigurationTab = (tab) => {
        setConfigurationTab(tab);
    };

    const handleChangeNodeConfigurationTab = (tab) => {
        setNodeConfigurationTab(tab);
    };

    function updateData() {
        getSummeryData()
    }

    function getCategoryData() {
        //setIsLoading(true)
        api.get('/apis/Categories/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayCategoryData(resData)
                // setIsLoading(false)
            }
        }).catch(function (error) {
            //setIsLoading(false)
            console.log(error);
        });
    }

    function getNodeCategoryData() {
        //setIsLoading(true)
        api.get('/apis/NodeCategories/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayNodeCategoryData(resData)
                // setIsLoading(false)
            }
        }).catch(function (error) {
            //setIsLoading(false)
            console.log(error);
        });
    }

    return (
        <>
            <Stack flex={1}>
                <Stack pl={3} pt={3} flex={1}>
                    <Stack
                        px={3}
                        py={2}
                        width={'100% + 24px'}
                        sx={{
                            border: '1px solid #E2E2EA',
                            borderRadius: '8px 0 0 8px',
                            borderRight: 0,
                            pointerEvents: (userDetailsModal || groupDetailsModal || assetCategoryModal || assetTypeModal || nodeCategoryModal || nodeTypeModal) && 'none',
                        }}
                        overflow={'hidden'}
                        bgcolor={'#F5F7FB'}
                        position={'relative'}
                        mb={3}
                    >
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'center'} mb={3}>
                            <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} />
                            {activeTab === 'users' && (
                                <ButtonBase
                                    sx={{
                                        height: 30,
                                        px: 2,
                                        py: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        bgcolor: 'secondary.main',
                                        borderRadius: '6px',
                                    }}
                                    onClick={() => setUserDetailsModal("0")}
                                >
                                    <PlusIcon />
                                    <Typography fontSize={14} color={'common.white'}>
                                        Add User
                                    </Typography>
                                </ButtonBase>
                            )}
                            {activeTab === 'groups' && (
                                <ButtonBase
                                    sx={{
                                        height: 30,
                                        px: 2,
                                        py: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        bgcolor: 'secondary.main',
                                        borderRadius: '6px',
                                    }}
                                    onClick={() => setGroupDetailsModal("0")}
                                >
                                    <PlusIcon />
                                    <Typography fontSize={14} color={'common.white'}>
                                        Add Group
                                    </Typography>
                                </ButtonBase>
                            )}
                            {activeTab === 'asset-configuration' && configurationTab === 'categories' && (
                                <ButtonBase
                                    sx={{
                                        height: 30,
                                        px: 2,
                                        py: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        bgcolor: 'secondary.main',
                                        borderRadius: '6px',
                                    }}
                                    onClick={() => setAssetCategoryModal("0")}

                                >
                                    <PlusIcon />
                                    <Typography fontSize={14} color={'common.white'}>
                                        Category
                                    </Typography>
                                </ButtonBase>
                            )}
                            {activeTab === 'asset-configuration' && configurationTab === 'types' && (
                                <ButtonBase
                                    sx={{
                                        height: 30,
                                        px: 2,
                                        py: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        bgcolor: 'secondary.main',
                                        borderRadius: '6px',
                                    }}
                                    onClick={() => setAssetTypeModal("0")}
                                >
                                    <PlusIcon />
                                    <Typography fontSize={14} color={'common.white'}>
                                        Add Type
                                    </Typography>
                                </ButtonBase>
                            )}
                            {activeTab === 'node-configuration' && nodeConfigurationTab === 'categories' && (
                                <ButtonBase
                                    sx={{
                                        height: 30,
                                        px: 2,
                                        py: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        bgcolor: 'secondary.main',
                                        borderRadius: '6px',
                                    }}
                                    onClick={() => setNodeCategoryModal("0")}

                                >
                                    <PlusIcon />
                                    <Typography fontSize={14} color={'common.white'}>
                                        Category
                                    </Typography>
                                </ButtonBase>
                            )}
                            {activeTab === 'node-configuration' && nodeConfigurationTab === 'types' && (
                                <ButtonBase
                                    sx={{
                                        height: 30,
                                        px: 2,
                                        py: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        bgcolor: 'secondary.main',
                                        borderRadius: '6px',
                                    }}
                                    onClick={() => setNodeTypeModal("0")}
                                >
                                    <PlusIcon />
                                    <Typography fontSize={14} color={'common.white'}>
                                        Add Type
                                    </Typography>
                                </ButtonBase>
                            )}
                        </Stack>
                        {(activeTab === 'users') && (
                            <>
                                <Typography fontWeight={700} fontSize={14} mb={2}>
                                    User Manager
                                </Typography>
                                <Stack direction={'row'} gap={8} alignItems={'flex-end'} justifyContent={'space-between'}>
                                    <Stack direction={'row'} gap={8}>
                                        <Stack gap={2}>
                                            <Typography fontSize={14} color={'#A0A3A6'}>
                                                Total Enrolled
                                            </Typography>
                                            <Stack direction={'row'} gap={3} alignItems={'flex-end'}>
                                                <Typography fontSize={24} fontWeight={700} lineHeight={1}>
                                                    {objSummery.totalUser}
                                                </Typography>
                                                <Typography fontSize={14}>Users</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack gap={2}>
                                            <Typography fontSize={14} color={'#A0A3A6'}>
                                                Active
                                            </Typography>
                                            <Typography fontSize={24} fontWeight={700} lineHeight={1}>
                                                {objSummery.activeUser}
                                            </Typography>
                                        </Stack>
                                        <Stack gap={2}>
                                            <Typography fontSize={14} color={'#A0A3A6'}>
                                                Disabled
                                            </Typography>
                                            <Typography fontSize={24} fontWeight={700} lineHeight={1}>
                                                {objSummery.disabledUser}
                                            </Typography>
                                        </Stack>
                                        <Box
                                            sx={{
                                                width: '1px',
                                                flexShrink: 0,
                                                bgcolor: '#DADDE5',
                                                height: 70,
                                                alignSelf: 'center',
                                            }}
                                        />
                                        <Stack gap={2}>
                                            <Typography fontSize={14} color={'#A0A3A6'}>
                                                Admin Users
                                            </Typography>
                                            <Typography fontSize={24} fontWeight={700} lineHeight={1}>
                                                {objSummery.adminUser}
                                            </Typography>
                                        </Stack>
                                        <Stack gap={2}>
                                            <Typography fontSize={14} color={'#A0A3A6'}>
                                                Standard Users
                                            </Typography>
                                            <Typography fontSize={24} fontWeight={700} lineHeight={1}>
                                                {objSummery.standardUser}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    {activeTab === 'users' && <SearchInput
                                        sx={{
                                            bgcolor: 'common.white',
                                            '.MuiButtonBase-root, .MuiInput-input': {
                                                bgcolor: 'common.white',
                                            },
                                        }}
                                        value={userSearch}
                                        name={'userSearch'}
                                        onChange={(event) => {
                                            setUserSearch(event.target.value)
                                        }}
                                    />}
                                </Stack>
                            </>
                        )}
                        {activeTab === 'groups' && (
                            <>
                                <Typography fontWeight={700} fontSize={14} mb={2}>
                                    Group Settings
                                </Typography>
                                <Stack direction={'row'} gap={8} alignItems={'flex-end'} justifyContent={'space-between'}>
                                    <Stack direction={'row'} gap={8}>
                                        <Stack gap={2}>
                                            <Typography fontSize={14} color={'#A0A3A6'}>
                                                Total Enrolled
                                            </Typography>
                                            <Stack direction={'row'} gap={3} alignItems={'flex-end'}>
                                                <Typography fontSize={24} fontWeight={700} lineHeight={1}>
                                                    {objSummery.totalGroup}
                                                </Typography>
                                                <Typography fontSize={14}>Groups</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack gap={2}>
                                            <Typography fontSize={14} color={'#A0A3A6'}>
                                                Active
                                            </Typography>
                                            <Typography fontSize={24} fontWeight={700} lineHeight={1}>
                                                {objSummery.activeGroup}
                                            </Typography>
                                        </Stack>
                                        <Stack gap={2}>
                                            <Typography fontSize={14} color={'#A0A3A6'}>
                                                Inactive
                                            </Typography>
                                            <Typography fontSize={24} fontWeight={700} lineHeight={1}>
                                                {objSummery.inActiveGroup}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    {activeTab === 'groups' && <SearchInput
                                        sx={{
                                            bgcolor: 'common.white',
                                            '.MuiButtonBase-root, .MuiInput-input': {
                                                bgcolor: 'common.white',
                                            },
                                        }}
                                        value={groupsSearch}
                                        name={'groupsSearch'}
                                        onChange={(event) => {
                                            setGroupsSearch(event.target.value)
                                        }}
                                    />}
                                </Stack>
                            </>
                        )}
                        {activeTab === 'asset-configuration' && (
                            <>
                                <Box mb={4}>
                                    <Tabs
                                        data={configurationTabs}
                                        activeTab={configurationTab}
                                        setActiveTab={handleChangeConfigurationTab}
                                    />
                                </Box>
                                {configurationTab === 'categories' && (
                                    <Stack direction={'row'} gap={3}>
                                        {/* <SearchInput
                                            sx={{
                                                bgcolor: 'common.white',
                                                '.MuiButtonBase-root, .MuiInput-input': {
                                                    bgcolor: 'common.white',
                                                },
                                            }}
                                            name={'assetsSearch'}
                                        /> */}
                                        <SearchInput
                                            sx={{
                                                bgcolor: 'common.white',
                                                '.MuiButtonBase-root, .MuiInput-input': {
                                                    bgcolor: 'common.white',
                                                },
                                            }}
                                            value={assetCategorySearch}
                                            name={'assetCategorySearch'}
                                            onChange={(event) => {
                                                setAssetCategorySearch(event.target.value)
                                            }}
                                        />
                                    </Stack>
                                )}
                                {configurationTab === 'types' && (
                                    <Stack direction={'row'} gap={3}>
                                        <SearchInput
                                            sx={{
                                                bgcolor: 'common.white',
                                                '.MuiButtonBase-root, .MuiInput-input': {
                                                    bgcolor: 'common.white',
                                                },
                                            }}
                                            value={assetTypesSearch}
                                            name={'assetTypesSearch'}
                                            onChange={(event) => {
                                                setAssetTypesSearch(event.target.value)
                                            }}
                                        />
                                        <Box maxWidth={200} width={'100%'}>
                                            <Filter
                                                onChange={(event) => {
                                                    var value = event.target.value
                                                    setSelectedTypeCategory(value)
                                                }}
                                                defaultValue={selectedTypeCategory}
                                                placeholder="All Categories"
                                            >
                                                {arrayCategoryData.map((item, index) => (
                                                    <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                                                ))}
                                            </Filter>
                                        </Box>
                                    </Stack>
                                )}
                            </>
                        )}
                        {activeTab === 'node-configuration' && (
                            <>
                                <Box mb={4}>
                                    <Tabs
                                        data={nodeConfigurationTabs}
                                        activeTab={nodeConfigurationTab}
                                        setActiveTab={handleChangeNodeConfigurationTab}
                                    />
                                </Box>
                                {nodeConfigurationTab === 'categories' && (
                                    <Stack direction={'row'} gap={3}>
                                        {/* <SearchInput
                                            sx={{
                                                bgcolor: 'common.white',
                                                '.MuiButtonBase-root, .MuiInput-input': {
                                                    bgcolor: 'common.white',
                                                },
                                            }}
                                            name={'assetsSearch'}
                                        /> */}
                                        <SearchInput
                                            sx={{
                                                bgcolor: 'common.white',
                                                '.MuiButtonBase-root, .MuiInput-input': {
                                                    bgcolor: 'common.white',
                                                },
                                            }}
                                            value={nodeCategorySearch}
                                            name={'nodeCategorySearch'}
                                            onChange={(event) => {
                                                setNodeCategorySearch(event.target.value)
                                            }}
                                        />
                                    </Stack>
                                )}
                                {nodeConfigurationTab === 'types' && (
                                    <Stack direction={'row'} gap={3}>
                                        <SearchInput
                                            sx={{
                                                bgcolor: 'common.white',
                                                '.MuiButtonBase-root, .MuiInput-input': {
                                                    bgcolor: 'common.white',
                                                },
                                            }}
                                            value={nodeTypesSearch}
                                            name={'nodeTypesSearch'}
                                            onChange={(event) => {
                                                setNodeTypesSearch(event.target.value)
                                            }}
                                        />
                                        <Box maxWidth={200} width={'100%'}>
                                            <Filter
                                                onChange={(event) => {
                                                    var value = event.target.value
                                                    setSelectedNodeTypeCategory(value)
                                                }}
                                                defaultValue={selectedNodeTypeCategory}
                                                placeholder="All Categories"
                                            >
                                                {arrayNodeCategoryData.map((item, index) => (
                                                    <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                                                ))}
                                            </Filter>
                                        </Box>

                                    </Stack>
                                )}
                            </>
                        )}
                    </Stack>
                    {activeTab === 'company' && <SettingCompany />}
                    {activeTab === 'users' && <SettingUser userDetailsModalOpen={userDetailsModal} setUserDetailsModalOpen={setUserDetailsModal} query={userSearch} updateData={updateData} />}
                    {activeTab === 'groups' && <SettingGroups groupDetailsModal={groupDetailsModal} setGroupDetailsModal={setGroupDetailsModal} query={groupsSearch} updateData={updateData} />}
                    {activeTab === 'asset-configuration' && configurationTab === 'categories' && <AssetCategories assetCategoryModal={assetCategoryModal} query={assetCategorySearch} setAssetCategoryModal={setAssetCategoryModal} />}
                    {activeTab === 'asset-configuration' && configurationTab === 'types' && <AssetTypes assetTypeModal={assetTypeModal} query={assetTypesSearch} selectedAssetTypeCategory={selectedTypeCategory} setAssetTypeModal={setAssetTypeModal} />}
                    {activeTab === 'node-configuration' && nodeConfigurationTab === 'categories' && <NodeCategories nodeCategoryModal={nodeCategoryModal} query={nodeCategorySearch} setNodeCategoryModal={setNodeCategoryModal} />}
                    {activeTab === 'node-configuration' && nodeConfigurationTab === 'types' && <NodeTypes nodeTypeModal={nodeTypeModal} query={nodeTypesSearch} selectedNodeTypeCategory={selectedNodeTypeCategory} setNodeTypeModal={setNodeTypeModal} />}
                </Stack>
            </Stack>
        </>
    );
};
